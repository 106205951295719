<div>
  <form
    class="form-login"
    method="post"
    [formGroup]="resetForm"
    (ngSubmit)="submit()"
  >
    <div class="form-group" [ngClass]="{'has-error':pswd.invalid && (pswd.dirty||pswd.touched)}">
      <div class="input-group input-group-lg">
        <span class="input-group-addon"
          ><span class="fas fa-lock"></span
        ></span>
        <input
          type="password"
          name="password"
          class="form-control"
          formControlName="pswd"
          placeholder="Password"
          required
          autocomplete="on"
          matInput
          autofocus
        />
      </div>
      <span *ngIf="pswd.invalid && (pswd.dirty||pswd.touched)" class="help-block">Please enter valid password!</span>
    </div>
    <div class="form-group" [ngClass]="{'has-error':(pswd.value !== con_pswd.value || con_pswd.invalid) && (con_pswd.dirty||con_pswd.touched)}">
      <div class="input-group input-group-lg">
        <span class="input-group-addon"
          ><span class="fas fa-lock"></span
        ></span>
        <input
          type="password"
          class="form-control"
          name="con_pswd"
          formControlName="con_pswd"
          placeholder="Confirm Password"
          required
        />
      </div>
      <span *ngIf="con_pswd.invalid && (con_pswd.dirty||con_pswd.touched)" class="help-block">Please enter valid password!</span>
      <span *ngIf="pswd.value !== con_pswd.value && (con_pswd.dirty||con_pswd.touched)" class="help-block">Please enter matching password!</span>
    </div>
    <button type="submit" class="btn btn-lg btn-primary btn-block">
      SAVE PASSWORD
    </button>
  </form>
  <div class="row">
    <div class="col-sm-6">
      <a [routerLink]="['../login']" class="btn btn-sm">
        LOGIN
      </a>
    </div>
    <div class="col-sm-6">
      <a [routerLink]="['../register']" class="btn btn-sm btn-green">
        REGISTER NOW
      </a>
    </div>
  </div>
</div>
