import { Component, OnInit } from '@angular/core';
import * as fromCoreStore from '@app/core/store';
import { Company } from '@app/shared/models';
import { States } from '@app/shared/services';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {

  companyListener: Subscription;

  company: Company;
  states: {
    id: string;
    abbr: string;
    name: string;
    active: string;
  }[];
  statesService = new States();

  constructor(private store: Store<fromCoreStore.State>) {
    this.states = this.statesService.getStates();
    this.companyListener = this.store.select(fromCoreStore.selectCurrentCompany)
      .subscribe(company => this.company = company);
  }

  ngOnInit() {
  }

  onEditCompany() {
    this.store.dispatch(
      new fromCoreStore.OpenModal({
        modal: 'COMPANY_FORM',
        props: { company: this.company }
      })
    );
  }

  ngOnDestroy(): void {
    this.companyListener.unsubscribe();
  }
}
