import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/core/store/reducers';
import * as fromCompany from '@app/core/store/reducers/companies.reducers';
import * as fromAuth from '@app/auth/store/selectors/auth.selectors';

// Companies State
export const selectCompanyState = createSelector(
  fromFeature.selectCoreState,
  (state: fromFeature.State) => state.companies
);

export const getCompaniesLoaded = createSelector(
  selectCompanyState,
  fromCompany.getLoaded
);
export const getCompaniesLoading = createSelector(
  selectCompanyState,
  fromCompany.getLoading
);

export const getSearchCompany = createSelector(
  selectCompanyState,
  fromCompany.getSearchCompany
);

export const selectCompanyIds = createSelector(
  selectCompanyState,
  fromCompany.selectCompanyIds
);
export const selectCompanyEntities = createSelector(
  selectCompanyState,
  fromCompany.selectCompanyEntities
);
export const selectAllCompanies = createSelector(
  selectCompanyState,
  fromCompany.selectAllCompany
);
export const selectCompanyTotal = createSelector(
  selectCompanyState,
  fromCompany.selectCompanyTotal
);
export const selectCurrentCompanyId = createSelector(
  selectCompanyState,
  fromCompany.getSelectedId
);

export const selectMyCompany = createSelector(
  selectAllCompanies,
  fromAuth.selectAuthCompany,
  (companies, authCompany) => {
    console.log(companies, authCompany)
    return companies.find(c => c.id === authCompany?.id)
  }
);

export const selectCurrentCompany = createSelector(
  selectCompanyEntities,
  selectCurrentCompanyId,
  (companyEntities, companyId) => companyEntities[companyId]
);
