import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { QuarterDatePicker } from '@app/shared/models';

@Component({
  selector: 'app-quarter-datepicker',
  templateUrl: './quarter-datepicker.component.html',
  styleUrls: ['./quarter-datepicker.component.scss']
})

export class QuarterDatepickerComponent implements OnInit {

  @Output()
  onChange = new EventEmitter<QuarterDatePicker>();

  currentDate = new Date();

  quarter: number = Math.ceil((this.currentDate.getMonth() + 1) / 3);
  year: number = this.currentDate.getFullYear();

  quarters = [
    {
      name: 'Q1',
      value: 1
    },
    {
      name: 'Q2',
      value: 2
    },
    {
      name: 'Q3',
      value: 3
    },
    {
      name: 'Q4',
      value: 4
    }
  ];
  years: number[] = [];

  constructor() {
    let currentDateYear = this.currentDate.getFullYear() + 1;
    while (currentDateYear >= 2010) {
      this.years.unshift(currentDateYear);
      currentDateYear--;
    }
  }

  ngOnInit() {
    const outputObj: QuarterDatePicker = {
      ...this.startAndEndDateConvertor(this.quarter),
      year: String(this.year)
    };
    this.onChange.emit(outputObj);
  }

  startAndEndDateConvertor(quarter) {
    quarter = String(quarter);
    switch (quarter) {
      case '1':
      return {
        quarter: '1',
        start_date: '01/01',
        end_date: '03/31'
      };
      case '2':
      return {
        quarter: '2',
        start_date: '04/01',
        end_date: '06/30'
      };
      case '3':
      return {
        quarter: '3',
        start_date: '07/01',
        end_date: '09/30'
      };
      case '4':
      return {
        quarter: '4',
        start_date: '10/01',
        end_date: '12/31'
      };

      default:
      return {
        quarter,
        start_date: 'error',
        end_date: 'error'
      };
    }
  }

  prevQuarter() {
    if (this.year - 1 >= 2010) {
      if (this.quarter <= 1) {
        this.quarter = 4;
        this.year--;
      } else {
        this.quarter--;
      }
      this.change();
    }
  }
  nextQuarter() {
    if (this.year <= this.currentDate.getFullYear()) {
      if (this.quarter >= 4) {
        this.quarter = 1;
        this.year++;
      } else {
        this.quarter++;
      }
      this.change();
    }
  }

  change() {
    const outputObj: QuarterDatePicker = {
      ...this.startAndEndDateConvertor(this.quarter),
      year: String(this.year)
    };
    this.onChange.emit(outputObj);
  }

}
