import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbPopoverModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

import { HasPermissionDirective } from '@app/shared/directives';

import {
  FiltersComponent,
  AddressComponent,
  InputComponent,
  TextareaExpandedComponent,
  DateComponent,
  NotesComponent,
  CompanyDetailsComponent,
  SelectCompanyComponent,
  CompanySearchComponent,
  SelectTruckComponent,
  QuarterDatepickerComponent,
  SelectUserComponent,
  AutoCompleteComponent,
  BtnDefaultComponent
} from '@app/shared/components';

const COMPONENTS = [
  FiltersComponent,
  AddressComponent,
  InputComponent,
  TextareaExpandedComponent,
  DateComponent,
  NotesComponent,
  CompanyDetailsComponent,
  CompanySearchComponent,
  SelectCompanyComponent,
  SelectTruckComponent,
  QuarterDatepickerComponent,
  SelectUserComponent,
  AutoCompleteComponent,
  BtnDefaultComponent,
  HasPermissionDirective
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgbDropdownModule,
    NgbPopoverModule,
    NgbTypeaheadModule
  ],
  declarations: COMPONENTS,
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    ...COMPONENTS
  ],
  entryComponents: []
})
export class SharedModule { }
