import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of, map, switchMap, mergeMap, catchError } from 'rxjs';

import * as TripsActions from '@app/core/store/actions';
import * as TripActions from '@app/trips/store/actions';
import * as CoreActions from '@app/core/store/actions';
import { TripService } from '@app/core/services/trip.service';
import { ToastHelper } from '@app/core/services/toast.service';
import { Router } from '@angular/router';
import { Trip } from '@app/shared';

@Injectable()
export class TripEffects {

  getTrips$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TripsActions.GetTrips>(TripsActions.TripActionTypes.GET_TRIPS),
      switchMap((action: TripsActions.GetTrips) => {
        return this._TripService.getAll(action.payload).pipe(
          map(resp => {
            return new TripsActions.GetTripsSuccess({
              trips: resp || []
            });
          }),
          catchError(error => of(new TripsActions.GetTripsError(error)))
        );
      })
    )
  });


  updateTrip$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<TripsActions.UpdateTrip>(TripsActions.TripActionTypes.UPDATE),
      mergeMap(action => {
        return this._TripService.update(action.payload.changes).pipe(
          map(resp => {
            this.notify.ok('Trip update was Successful!');
            return new TripsActions.UpdateTripSuccess(
              {
                id: resp.id,
                changes: resp
              }
            );
          }),
          catchError(error => {
            this.notify.error(error);
            return of(
              new TripsActions.UpdateTripError(action.payload.changes)
            );
          })
        );
      })
    )
  });


  deleteTrip$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<TripsActions.DeleteTrip>(TripsActions.TripActionTypes.DELETE),
      mergeMap(action => {
        return this._TripService.delete(action.payload.trip).pipe(
          map((data: Response) => {
            this.notify.ok('Trip Deleted Successful!');
            return new TripsActions.DeleteTripSuccess(data);
          }),
          catchError(error => {
            this.notify.error(error);
            return of(new TripsActions.DeleteTripError(action.payload.trip));
          })
        );
      })
    )
  });


  getNewTripId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TripsActions.GetNewTripId>(TripsActions.TripActionTypes.GET_NEW_TRIP_ID),
      switchMap((action: TripsActions.GetNewTripId) => {
        return this._TripService.create(action.payload).pipe(
          map(resp => {
            return new TripsActions.GetTrip({ id: resp.id, viewTrip: false });
          }),
          catchError(error => of(new TripsActions.GetNewTripIdError(error)))
        );
      })
    )
  });


  getTrip$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<TripsActions.GetTrip>(TripsActions.TripActionTypes.GET_TRIP),
      mergeMap(action => {
        return this._TripService.addTrip(action.payload.id).pipe(
          mergeMap(resp => {
            if (resp) {
              this.notify.ok('Trip Added Successful!');
              if (action.payload.viewTrip) {
                this.router.navigate(['trips', resp.id, 'view']);
              } else {
                this.router.navigate(['trips', resp.id]);
              }
              const tripObj: Trip = {
                id: resp.id,
                number: resp.number,
                start_od: resp.start_od,
                end_od: resp.end_od,
                trip_or: resp.trip_or,
                trip_de: resp.trip_de,
                trip_start: resp.trip_start,
                trip_end: resp.trip_end,
                truck_id: resp.truck_id,
                driver_id: resp.driver_id,
                account_id: resp.account_id,
                company_id: resp.company_id,
                status: resp.status,
                created: resp.created,
                modified: resp.modified
              };
              return [
                new TripsActions.GetTripSuccess({
                  trip: tripObj
                }),
                new CoreActions.GetUsersByCompany({
                  companyId: resp.company_id
                }),
                new CoreActions.GetUserSuccess({
                  user: resp.driver
                }),
                new CoreActions.GetCompanySuccess({
                  company: resp.company
                }),
                new CoreActions.GetTrucksByCompany({
                  companyId: resp.company_id
                }),
                new TripActions.GetFuels({
                  tripId: tripObj.id
                }),
                new TripActions.GetMiles({
                  tripId: tripObj.id
                }),
                new TripActions.GetFuelsNameList(),
                new TripActions.GetStops({
                  tripId: tripObj.id
                }),
              ];
            }
          }),
          catchError(error => {
            this.notify.error(error);
            return of(new TripsActions.GetTripError(error));
          })
        );
      })
    )
  });

  constructor(
    private actions$: Actions,
    private _TripService: TripService,
    private notify: ToastHelper,
    private router: Router
  ) { }
}
