import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    path = `api/payment`;
    constructor(private apiService: ApiService) {}

    getPayment(account_id?: string) {
      return this.apiService.get(this.path, {
        action_type: 'all',
        account_id
      });
    }
}
