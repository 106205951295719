import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Mile } from '@app/shared/models/mile.model';

export enum MileActionTypes {
  GET_MILES = '[TripMile] GET_MILES',
  GET_MILES_SUCCESS = '[TripMile] GET_MILES_SUCCESS',
  GET_MILES_ERROR = '[TripMile] GET_MILES_ERROR',

  ADD_ONE = '[TripMile] ADD_ONE',
  ADD_ONE_SUCCESS = '[TripMile] ADD_ONE_SUCCESS',
  ADD_ONE_ERROR = '[TripMile] ADD_ONE_ERROR',

  GET_ONE = '[TripMile] GET_ONE',
  GET_ONE_SUCCESS = '[TripMile] GET_ONE_SUCCESS',
  GET_ONE_ERROR = '[TripMile] GET_ONE_ERROR',

  UPDATE = '[TripMile] UPDATE',
  UPDATE_SUCCESS = '[TripMile] UPDATE_SUCCESS',
  UPDATE_ERROR = '[TripMile] UPDATE_ERROR',

  DELETE = '[TripMile] DELETE',
  DELETE_SUCCESS = '[TripMile] DELETE_SUCCESS',
  DELETE_ERROR = '[TripMile] DELETE_ERROR',

  DELETE_ALL = '[TripMile] DELETE_ALL',
  DELETE_ALL_SUCCESS = '[TripMile] DELETE_ALL_SUCCESS',
  DELETE_ALL_ERROR = '[TripMile] DELETE_ALL_ERROR',

  GENERATE_MILES = '[TripMile] GENERATE_MILES',
  GENERATE_MILES_SUCCESS = '[TripMile] GENERATE_MILES_SUCCESS',
  GENERATE_MILES_ERROR = '[TripMile] GENERATE_MILES_ERROR',
}

// Actions for Getting  Miles
export class GetMiles implements Action {
  readonly type = MileActionTypes.GET_MILES;
  constructor(public payload: { tripId: string }) {}
}

export class GetMilesSuccess implements Action {
  readonly type = MileActionTypes.GET_MILES_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: Mile[]) {}
  constructor(public payload: { miles: Mile[], tripId: string }) {}
}
export class GetMilesError implements Action {
  readonly type = MileActionTypes.GET_MILES_ERROR;
  constructor(public payload: any) {}
}

// Action for Creating Mile
export class CreateMile implements Action {
  readonly type = MileActionTypes.ADD_ONE;

  constructor(public payload: { mile: Mile }) {}
}
export class CreateMileSuccess implements Action {
  readonly type = MileActionTypes.ADD_ONE_SUCCESS;

  constructor(public payload: { mile: Mile }) {}
}
export class CreateMileError implements Action {
  readonly type = MileActionTypes.ADD_ONE_ERROR;
  constructor(public payload: any) {}
}

export class GetMile implements Action {
  readonly type = MileActionTypes.GET_ONE;
}
export class GetMileSuccess implements Action {
  readonly type = MileActionTypes.GET_ONE_SUCCESS;

  constructor(public payload: { mile: Mile }) {}
}
export class GetMileError implements Action {
  readonly type = MileActionTypes.GET_ONE_ERROR;
  constructor(public payload: any) {}
}

export class UpdateMile implements Action {
  readonly type = MileActionTypes.UPDATE;

  constructor(public payload: Update<Mile>) {}
}
export class UpdateMileSuccess implements Action {
  readonly type = MileActionTypes.UPDATE_SUCCESS;

  constructor(public payload: { mile: Update<Mile> }) {}
}
export class UpdateMileError implements Action {
  readonly type = MileActionTypes.UPDATE_ERROR;

  constructor(public payload: any) {}
}

export class DeleteMile implements Action {
  readonly type = MileActionTypes.DELETE;

  constructor(public payload: { mile: Mile }) {}
}
export class DeleteMileSuccess implements Action {
  readonly type = MileActionTypes.DELETE_SUCCESS;

  constructor(public payload: any) {}
}
export class DeleteMileError implements Action {
  readonly type = MileActionTypes.DELETE_ERROR;

  constructor(public payload: any) {}
}

export class DeleteAllMiles implements Action {
  readonly type = MileActionTypes.DELETE_ALL;

  constructor(public payload: string) {}
}
export class DeleteAllMilesSuccess implements Action {
  readonly type = MileActionTypes.DELETE_ALL_SUCCESS;

  constructor(public payload: any) {}
}
export class DeleteAllMilesError implements Action {
  readonly type = MileActionTypes.DELETE_ALL_ERROR;

  constructor(public payload: any) {}
}

export class GenerateMiles implements Action {
  readonly type = MileActionTypes.GENERATE_MILES;
}
export class GenerateMilesSuccess implements Action {
  readonly type = MileActionTypes.GENERATE_MILES_SUCCESS;

  constructor(public payload: Mile[]) {}
}
export class GenerateMilesError implements Action {
  readonly type = MileActionTypes.GENERATE_MILES_ERROR;

  constructor(public payload: any) {}
}

export type MileActions =
  | GetMile
  | GetMileSuccess
  | GetMileError

  | UpdateMile
  | UpdateMileSuccess
  | UpdateMileError

  | GetMiles
  | GetMilesSuccess
  | GetMilesError

  | CreateMile
  | CreateMileSuccess
  | CreateMileError

  | DeleteMile
  | DeleteMileSuccess
  | DeleteMileError

  | DeleteAllMiles
  | DeleteAllMilesSuccess
  | DeleteAllMilesError

  | GenerateMiles
  | GenerateMilesSuccess
  | GenerateMilesError;
