import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthUser } from '@app/shared';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';

import * as fromCoreStore from '@app/core/store';
import * as fromSettingStore from '@app/settings/store';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  @Input()
  props: { user: AuthUser };
  @Output()
  update = new EventEmitter<AuthUser>();
  @Output()
  create = new EventEmitter<AuthUser>();

  user: AuthUser;
  userForm: FormGroup;
  updateStatus: boolean;

  emailDisplay = false;

  constructor(private store: Store<fromCoreStore.State>) {}

  ngOnInit() {
    this.userForm = new FormGroup({
      driver: new FormControl(this.props.user.driver, [Validators.required]),
      firstname: new FormControl(this.props.user.firstname, [Validators.required]),
      lastname: new FormControl(this.props.user.lastname, [Validators.required]),
      email: new FormControl(this.props.user.email, [Validators.required]),
    });
  }

  changeEmailDisplay(status: boolean) {
    this.emailDisplay = status;
  }

  setValue(field, value) {
    if (this.userForm && this.userForm.controls[field]) {
      this.userForm.controls[field].setValue(value);
    }
  }

  showTrucking() {}

  onSubmit() {
    const companyFormLet: AuthUser = this.userForm.value;
    const changeUser = { ...this.props.user, ...companyFormLet };
    console.log(changeUser);
    if (this.props.user.id) {
      this.store.dispatch(
        new fromSettingStore.UpdateUsers(
          {
            id: changeUser.id,
            changes: changeUser
          }
        )
      );
    } else {
      this.store.dispatch(
        new fromSettingStore.AddUser(changeUser)
      );
    }
    this.store.dispatch(new fromCoreStore.CloseModal());
  }

  close() {
    this.store.dispatch(new fromCoreStore.CloseModal());
  }
}
