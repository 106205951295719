import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import {
  Trip,
  QuarterDatePicker,
  AuthUser,
  Truck
} from '@app/shared/models';

export enum TripActionTypes {
  GET_TRIPS = '[Trip] GET_TRIPS',
  GET_TRIPS_SUCCESS = '[Trip] GET_TRIPS_SUCCESS',
  GET_TRIPS_ERROR = '[Trip] GET_TRIPS_ERROR',

  GET_TRIP = '[Trip] GET_TRIP',
  GET_TRIP_SUCCESS = '[Trip] GET_TRIP_SUCCESS',
  GET_TRIP_ERROR = '[Trip] GET_TRIP_ERROR',

  UPDATE = '[Trip] UPDATE',
  UPDATE_SUCCESS = '[Trip] UPDATE_SUCCESS',
  UPDATE_ERROR = '[Trip] UPDATE_ERROR',

  DELETE = '[Trip] DELETE',
  DELETE_SUCCESS = '[Trip] DELETE_SUCCESS',
  DELETE_ERROR = '[Trip] DELETE_ERROR',

  GET_NEW_TRIP_ID = '[TripItem] GET_NEW_TRIP_ID',
  GET_NEW_TRIP_ID_SUCCESS = '[TripItem] GET_NEW_TRIP_ID_SUCCESS',
  GET_NEW_TRIP_ID_ERROR = '[TripItem] GET_NEW_TRIP_ID_ERROR',

  CHANGE_TRUCK_OF_TRIP_ITEM = '[TripItem] CHANGE_TRUCK_OF_TRIP_ITEM',

  CHANGE_DRIVER_OF_TRIP_ITEM = '[TripItem] CHANGE_DRIVER_OF_TRIP_ITEM',

  ADD_FUEL_TO_TRIP_ITEM = '[TripItem] ADD_FUEL_TO_TRIP_ITEM',
  ADD_FUEL_TO_TRIP_ITEM_SUCCESS = '[TripItem] ADD_FUEL_TO_TRIP_ITEM_SUCCESS',
  ADD_FUEL_TO_TRIP_ITEM_ERROR = '[TripItem] ADD_FUEL_TO_TRIP_ITEM_ERROR',
}

// Actions for Getting  Trips
export class GetTrips implements Action {
  readonly type = TripActionTypes.GET_TRIPS;
  constructor(public payload?: QuarterDatePicker) {}
}

export class GetTripsSuccess implements Action {
  readonly type = TripActionTypes.GET_TRIPS_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: Trip[]) {}
  constructor(public payload: { trips: Trip[] }) {}
}

export class GetTripsError implements Action {
  readonly type = TripActionTypes.GET_TRIPS_ERROR;
  constructor(public payload: any) {}
}

export class GetTrip implements Action {
  readonly type = TripActionTypes.GET_TRIP;
  constructor(public payload: { id: string, viewTrip: boolean }) {}
}
export class GetTripSuccess implements Action {
  readonly type = TripActionTypes.GET_TRIP_SUCCESS;

  constructor(public payload: { trip: Trip }) {}
}

export class GetTripError implements Action {
  readonly type = TripActionTypes.GET_TRIP_ERROR;
  constructor(public payload: any) {}
}
export class UpdateTrip implements Action {
  readonly type = TripActionTypes.UPDATE;

  constructor(public payload: Update<Trip>) {}
}
export class UpdateTripSuccess implements Action {
  readonly type = TripActionTypes.UPDATE_SUCCESS;

  constructor(public payload: Update<Trip>) {}
}

export class UpdateTripError implements Action {
  readonly type = TripActionTypes.UPDATE_ERROR;

  constructor(public payload: any) {}
}

export class DeleteTrip implements Action {
  readonly type = TripActionTypes.DELETE;

  constructor(public payload: { trip: Trip }) {}
}
export class DeleteTripSuccess implements Action {
  readonly type = TripActionTypes.DELETE_SUCCESS;

  constructor(public payload: any) {}
}
export class DeleteTripError implements Action {
  readonly type = TripActionTypes.DELETE_ERROR;

  constructor(public payload: Trip) {}
}

export class GetNewTripId implements Action {
  readonly type = TripActionTypes.GET_NEW_TRIP_ID;

  constructor(public payload:
    {
      account_id: string,
      company_id: string,
      created: string,
      trip_end: string,
      trip_start: string,
      truck_id: string
    }
  ) {}
}
export class GetNewTripIdSuccess implements Action {
  readonly type = TripActionTypes.GET_NEW_TRIP_ID_SUCCESS;

  constructor(public payload: Trip ) {}
}
export class GetNewTripIdError implements Action {
  readonly type = TripActionTypes.GET_NEW_TRIP_ID_ERROR;
  constructor(public payload: any) {}
}

export class ChangeTruckOfTripItem implements Action {
  readonly type = TripActionTypes.CHANGE_TRUCK_OF_TRIP_ITEM;

  constructor(public payload: { truck: Truck }) {}
}

export class ChangeDriverOfTripItem implements Action {
  readonly type = TripActionTypes.CHANGE_DRIVER_OF_TRIP_ITEM;

  constructor(public payload: { driver: AuthUser }) {}
}

export type TripActions =
  | GetTrip
  | GetTripSuccess
  | GetTripError

  | UpdateTrip
  | UpdateTripSuccess
  | UpdateTripError

  | GetTrips
  | GetTripsSuccess
  | GetTripsError

  | DeleteTrip
  | DeleteTripSuccess
  | DeleteTripError

  | GetNewTripId
  | GetNewTripIdSuccess
  | GetNewTripIdError

  | ChangeTruckOfTripItem

  | ChangeDriverOfTripItem;
