import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/core/store/reducers';
import * as fromTrips from '@app/core/store/reducers/trip.reducers';

// Trips State
export const selectTripState = createSelector(
  fromFeature.selectCoreState,
  (state: fromFeature.State) => state.trips
);

export const getTripsLoaded = createSelector(
  selectTripState,
  fromTrips.getLoaded
);
export const getTripsLoading = createSelector(
  selectTripState,
  fromTrips.getLoading
);

export const selectTripIds = createSelector(
  selectTripState,
  fromTrips.selectTripIds
);
export const selectTripEntities = createSelector(
  selectTripState,
  fromTrips.selectTripEntities
);
export const selectAllTrips = createSelector(
  selectTripState,
  fromTrips.selectAllTrip
);
export const selectTripTotal = createSelector(
  selectTripState,
  fromTrips.selectTripTotal
);
export const selectCurrentTripId = createSelector(
  selectTripState,
  fromTrips.getSelectedId
);

export const selectCurrentTrip = createSelector(
  selectTripEntities,
  selectCurrentTripId,
  (tripEntities, tripId) => tripEntities[tripId]
);