<div>
  <form
    class="form-login"
    method="post"
    [formGroup]="form"
    (ngSubmit)="submit()"
  >
    <div class="form-group" [ngClass]="{'has-error':email.invalid && (email.dirty||email.touched)}">
      <div class="input-group input-group-lg">
        <span class="input-group-addon"
          ><span class="fas fa-envelope"></span
        ></span>
        <input
          class="form-control"
          matInput
          name="email"
          formControlName="email"
          placeholder="Email address"
          type="email"
          required
          autofocus
          autocomplete="on"
        />
      </div>
      <span *ngIf="email.invalid && (email.dirty||email.touched)" class="help-block">Please enter valid email!</span>
    </div>
    <div class="form-group" [ngClass]="{'has-error':pswd.invalid && (pswd.dirty||pswd.touched)}">
      <div class="input-group input-group-lg">
        <span class="input-group-addon"
        ><span class="fas fa-lock"></span
          ></span>
          <input
          type="password"
          name="password"
          class="form-control"
          formControlName="pswd"
          placeholder="Password"
          required
          autocomplete="on"
          />
        </div>
        <span *ngIf="pswd.invalid && (pswd.dirty||pswd.touched)" class="help-block">Please enter your password!</span>
    </div>

    <button type="submit" class="btn btn-lg btn-primary btn-block">
      LOGIN
    </button>
  </form>
  <div class="row">
    <div class="col-sm-6">
      <a [routerLink]="['../forgot']" class="btn btn-sm">
        FORGOT PASSWORD
      </a>
    </div>
    <div class="col-sm-6">
      <a [routerLink]="['../register']" class="btn btn-sm btn-green">
        REGISTER NOW
      </a>
    </div>
  </div>
</div>
