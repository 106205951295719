<div>
  <form
    class="form-login"
    method="post"
    [formGroup]="forgotForm"
    (ngSubmit)="submit()"
  >
    <h2 class="form-signin-heading text-center">
      Forgot Password?
    </h2>
    <div class="form-group" [ngClass]="{'has-error':email.invalid && (email.dirty||email.touched)}">
      <div class="input-group input-group-lg">
        <span class="input-group-addon"
          ><span class="fas fa-envelope"></span
        ></span>
        <input
          class="form-control"
          matInput
          name="email"
          formControlName="email"
          placeholder="Email address"
          type="email"
          required
          autofocus
          autocomplete="on"
        />
      </div>
      <span *ngIf="email.invalid && (email.dirty||email.touched)" class="help-block">Please enter valid email!</span>
    </div>
    <button type="submit" class="btn btn-lg btn-primary btn-block">
      RESET PASSWORD
    </button>
  </form>
  <div class="row">
    <div class="col-sm-6">
      <a [routerLink]="['../login']" class="btn btn-sm">
        LOGIN
      </a>
    </div>
    <div class="col-sm-6">
      <a [routerLink]="['../register']" class="btn btn-sm btn-green">
        REGISTER NOW
      </a>
    </div>
  </div>
  <p style="margin-top:20px;">
    <strong>Spam Filter Note</strong> If you don't get an email from us within a
    few minutes please check your spam filter. The email will be coming from
    service@ifueltax.com
  </p>
</div>
