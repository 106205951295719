import { Action } from '@ngrx/store';
import {
  CompanyActions,
  CompanyActionTypes
} from '@app/core/store/actions/company.actions';
import { Company } from '@app/shared';
import { EntityState, createEntityAdapter, EntityAdapter } from '@ngrx/entity';

export interface State extends EntityState<Company> {
  selectId: string | null;
  loaded: boolean;
  loading: boolean;
  searchCompany: Company;
}

export const adapter: EntityAdapter<Company> = createEntityAdapter<Company>({
  selectId: (company: Company) => company.id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectId: null,
  loaded: false,
  loading: false,
  searchCompany: null
});

export function reducer(state = initialState, action: CompanyActions): State {
  switch (action.type) {
    case CompanyActionTypes.GET_COMPANIES_SUCCESS: {
      return adapter.setAll(action.payload.companies, {
        ...state,
        selectId: state.selectId,
        loaded: true,
        loading: false
      });
    }

    case CompanyActionTypes.ADD_COMPANY: {
      // return adapter.addOne(action.payload.company, state);
      // Nothing to change to the store at this point. An effect CREATE is also triggered and will subsequently fire a CREATE_DONE action.
      return {
        ...state,
        loading: true
      };
    }

    case CompanyActionTypes.ADD_COMPANY_SUCCESS: {
      return adapter.addOne(action.payload.company, {
        ...state,
        loading: false
      });
    }

    case CompanyActionTypes.GET_COMPANY: {
      return {
        ...state,
        loading: true
      };
    }

    case CompanyActionTypes.GET_COMPANY_SUCCESS: {
      return adapter.addOne(action.payload.company, {
        ...state,
        selectId: action.payload.company.id,
        loading: false
      });
    }

    case CompanyActionTypes.UPDATE_SUCCESS: {
      return adapter.upsertOne(action.payload.company, state);
    }

    case CompanyActionTypes.DELETE: {
      return adapter.removeOne(action.payload.company.id, state);
    }

    case CompanyActionTypes.SET_CURRENT_ID: {
      return {
        ...state,
        selectId: action.payload
      };
    }

    case CompanyActionTypes.CLEAR_COMPANIES: {
      return initialState;
    }

    case CompanyActionTypes.SEARCH_COMPANY_SUCCESS: {
      return {
        ...state,
        searchCompany: action.payload
      };
    }

    case CompanyActionTypes.SEARCH_COMPANY_RESET: {
      return {
        ...state,
        searchCompany: null
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getSelectedId = (state: State) => state.selectId;
export const getSearchCompany = (state: State) => state.searchCompany;
export const {
  // select the array of Company ids
  selectIds: selectCompanyIds,
  // select the dictionary of Company entities
  selectEntities: selectCompanyEntities,
  // select the array of companies
  selectAll: selectAllCompany,
  // select the array of notes
  selectAll: selectAllNote,
  // select the total Company count
  selectTotal: selectCompanyTotal
} = adapter.getSelectors();
