import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import * as fromStore from '@app/auth/store';
import { Reset } from '@app/auth/store/actions/auth.actions';
import { Authenticate } from '@app/auth/models/authentication.model';

@Component({
  selector: 'app-reset-page',
  template: `
    <app-auth-wrapper>
      <div auth-form>
        <app-reset-form (submitted)="onReset($event)"></app-reset-form>
      </div>
    </app-auth-wrapper>
  `
})
export class ResetPageComponent implements OnInit {

  params: {};
  error$ = this.store.select(fromStore.selectLoginPageError);
  pending$ = this.store.select(fromStore.selectLoginPagePending);

  constructor(private store: Store<fromStore.State>, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.params = params;
      }
      );
  }

  onReset(credentials: Authenticate) {
    if (this.params['key']) {
      credentials.key = this.params['key'];
    }
    this.store.dispatch(new Reset(credentials));
  }
}
