import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

import { Address } from '@app/shared/models/address.model';

@Component({
  selector: 'app-address',
  template: `
  <div class="text-muted">{{address.addressLine1}} <br/>{{address.city}}, {{address.state}}</div>
  `
})
export class AddressComponent {
  @Input()
  address: Address;
  @Output()
  update = new EventEmitter();

  constructor() {}
}
