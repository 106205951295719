import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  TripActions,
  TripActionTypes
} from '@app/core/store/actions/trip.actions';
import { Trip } from '@app/shared/models';

export interface State extends EntityState<Trip> {
  selectId: string | null;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<Trip> = createEntityAdapter<Trip>({
  selectId: (trip: Trip) => trip.id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectId: null,
  loaded: false,
  loading: false
});

export function reducer(state = initialState, action: TripActions): State {
  switch (action.type) {
    case TripActionTypes.GET_TRIPS_SUCCESS: {
      return adapter.setAll(action.payload.trips, {
        ...state,
        selectId: null,
        loaded: true,
        loading: false
      });
    }

    case TripActionTypes.GET_TRIP_SUCCESS: {
      return adapter.addOne(action.payload.trip, {
        ...state,
        selectId: action.payload.trip.id,
        loaded: true,
        loading: false
      });
    }

    // case TripActionTypes.UPSERT: {
    //   return adapter.upsertOne(action.payload.trip, state);
    // }

    // case TripActionTypes.UPSERT_MANY: {
    //   return adapter.upsertMany(action.payload.trips, state);
    // }

    case TripActionTypes.UPDATE: {
      return adapter.updateOne(action.payload, state);
    }

    case TripActionTypes.UPDATE_SUCCESS: {
      return adapter.updateOne(action.payload, state);
    }

    // case TripActionTypes.UPDATE_MANY: {
    //   return adapter.updateMany(action.payload.trips, state);
    // }

    case TripActionTypes.DELETE: {
      return adapter.removeOne(action.payload.trip.id, state);
    }

    // case TripActionTypes.DELETE_MANY: {
    //   return adapter.removeMany(action.payload.ids, state);
    // }

    // case TripActionTypes.DELETE_MANY: {
    //   return adapter.removeAll({ ...state, selectedTripId: null });
    // }

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getSelectedId = (state: State) => state.selectId;
export const {
  // select the array of Trip ids
  selectIds: selectTripIds,
  // select the dictionary of Trip entities
  selectEntities: selectTripEntities,
  // select the array of trips
  selectAll: selectAllTrip,
  // select the total Trip count
  selectTotal: selectTripTotal
} = adapter.getSelectors();
