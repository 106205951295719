import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Authenticate } from '@app/auth/models/authentication.model';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styles: []
})
export class LoginFormComponent implements OnInit {
  @Input()
  error: string | null;

  @Input()
  set disabled(isDisabled: boolean) {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Output()
  submitted = new EventEmitter<Authenticate>();

  form = new FormGroup({
    email: new FormControl('abc11@gmail.com', [
      Validators.required,
      Validators.email
    ]),
    pswd: new FormControl('test', [Validators.required])
  });

  ngOnInit() { }

  get email() {
    return this.form.get('email') as FormControl;
  }

  get pswd() {
    return this.form.get('pswd') as FormControl;
  }

  submit() {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach(field => {
        this.form.get(field).markAsTouched({ onlySelf: true });
      });
      return;
    }
    const value: Authenticate = this.form.value;
    if (this.form.valid) {
      this.submitted.emit(value);
    }
  }
}
