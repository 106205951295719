import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  BillingActions,
  BillingActionTypes
} from '@app/settings/store/actions/billing.actions';
import { AuthUser, Plan, AdditionalFeatures } from '@app/shared/models';

export interface State {
  plan: Plan | null;
  prices: any | null;
  payments: any | [];
  additional_features: Array<AdditionalFeatures> | null;
  session: string;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<AuthUser> = createEntityAdapter<AuthUser>({
  selectId: (setting: AuthUser) => setting.id
});

export const initialState: State = {
  plan: null,
  prices: null,
  payments: [],
  additional_features: null,
  session: null,
  loaded: false,
  loading: false
};

export function reducer(state = initialState, action: BillingActions): State {
  switch (action.type) {
    case BillingActionTypes.GET_PLAN_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loaded: true,
        loading: false
      };
    }
    case BillingActionTypes.GET_PAYMENT_SUCCESS: {
      return {
        ...state,
        payments: action.payload.payments,
        loaded: true,
        loading: false
      };
    }
    case BillingActionTypes.GET_ADDITIONAL_FEATURES_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loaded: true,
        loading: false
      };
    }

    case BillingActionTypes.CALL_SERVER_SUCCESS: {
      return {
        ...state,
        session: action.payload.session,
        loaded: true,
        loading: false
      };
    }
    case BillingActionTypes.COMPLETE_PAYMENT_SUCCESS: {
      return {
        ...state,
        payments: [...state.payments, action.payload.payment],
        loaded: true,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const {
  // select the array of Billing ids
  selectIds: selectBillingIds,
  // select the dictionary of Billing entities
  selectEntities: selectBillingEntities,
  // select the array of settings
  selectAll: selectAllBilling,
  // select the total Billing count
  selectTotal: selectBillingTotal
} = adapter.getSelectors();

export const getSession = (state: State) => state.session;
export const getPlan = (state: State) => state.plan;
export const getPrices = (state: State) => state.prices;
export const getPayments = (state: State) => state.payments;
export const getAdditionalFeatures = (state: State) => state.additional_features;
