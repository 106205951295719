<div class="modal-header">
    <h4 class="modal-title">Edit Truck</h4>
    <button class="btn btn-link" type="button" (click)="close()" aria-label="Close">
        <span aria-hidden="true">
            <i class="fas fa-times"></i>
        </span>
    </button>
</div>
<div class="modal-body">
    <div>
        <div class="card card-block">
            <canvas baseChart
                *ngIf="this.props.account.graph"
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [colors]="lineChartColors"
                [legend]="lineChartLegend"
                [chartType]="lineChartType"
            ></canvas>
            <table class="table">
                <thead>
                    <th>ID</th>
                    <th>Name</th>
                </thead>
                <tbody>
                    <tr *ngFor="let c of props.account.companies">
                        <td>{{c.id}}</td>
                        <td>{{c.name}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card card-block">
            <table class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>E-mail</th>
                        <th>Account Role</th>
                        <th>Driver</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of props.account.users">
                        <td>{{ user.firstname }} {{ user.lastname }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.level }}</td>
                        <td>{{(user.driver == '1')?'Yes':'No'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div ng-controller="BillingCtrl">
            <div class="container">
                <div class="col-md-4">
                    <h4>{{ plan.name }} {{ 'Plan' }} <span class="label"
                            [ngClass]="(account.status=='1') ? 'label-success' : 'label-default'">{{(account.status=='1')? 'active':'inactive'}}</span>
                    </h4>
                </div>
                <div class="col-md-4">
                    <h4>{{ 'bill_next_date' }}</h4>
                    <p>{{ account.exp }}</p>
                </div>
            </div>
            <div id="payment-history" class="card card-block">
                <h5>{{ 'payment_history' }}</h5>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>{{ 'payment_date' }}</th>
                            <th>{{ 'months' }}</th>
                            <th>{{ 'amount' }}</th>
                            <th>{{ 'service_dates' }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let payment of props.account.payment">
                            <td>{{ payment.payment_date }}</td>
                            <td>{{ payment.length }}</td>
                            <td>${{ payment.gross }}</td>
                            <td>{{ payment.dates }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>