import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router
} from '@angular/router';
import { Store } from '@ngrx/store';
import { of, combineLatest, map, take, mergeMap } from 'rxjs';
import * as Auth from '@app/auth/store/actions';
import * as fromAuth from '@app/auth/store';
import { AuthService } from '@app/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    private store: Store<fromAuth.State>,
    private router: Router,
    private _AuthService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.checkStoreAuth().pipe(
      mergeMap(user => {
        if (user) {
          return of(user);
        }
        return this.checkTokenAuth();
      }),
      map((user: any) => {
        if (!user) {
          this.store.dispatch(new Auth.LoginRequest());
          return false;
        }
        if (route.data && route.data.action && user.permissions) {
          return user.permissions.includes(route.data.action);
        }
        return true;
      })
    );
  }

  checkStoreAuth() {
    return this.store.select(fromAuth.selectAuthUser).pipe(take(1));
  }

  checkTokenAuth() {
    return combineLatest([
      this.store.select(fromAuth.selectCheckedToken),
      this.store.select(fromAuth.selectAuthUser)
    ]).pipe(
      map(([checked, user]) => {
        if (!checked && this._AuthService.getToken()) {
          this.store.dispatch(new fromAuth.LoginToken());
          return true;
        } else {
          return false;
        }
      })
    );
  }
}
