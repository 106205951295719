import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  path = `api/company`;
  constructor(private apiService: ApiService) { }

  get() {
    return this.apiService.get(this.path);
  }

  getAll(account_id?: string) {
    if (account_id) {
      return this.apiService.get(this.path, {
        action_type: 'all',
        account_id
      });
    } else {
      return this.apiService.get(this.path, {
        action_type: 'all'
      });
    }
  }
  AgentCompanyLink(query: {
    action_type: string;
    company_id: string;
  }) {
    return this.apiService.post('api/agent', query);
  }

  searchCompany(mc: string) {
    return this.apiService.get(this.path, {
      action_type: 'search',
      mc
    });
  }

  changeCompany(data: { action_name?: string, company_id: string, linkType: string }) {
    if (data.action_name === 'create') {
      return this.apiService.post('api/account-company', { company_id: data.company_id, type: data.linkType });
    }
    return this.apiService.put('api/account-company', { company_id: data.company_id, type: data.linkType });
  }

  create(company) {
    return this.apiService.post(this.path, company);
  }

  update(data) {
    data = this.cleanObj(data);
    return this.apiService.put(`${this.path}/${data.id}`, data);
  }

  delete(data) {
    return this.apiService.delete(`${this.path}/${data.id}`);
  }

  upload(data) {
    return this.apiService.postForm(`${this.path}`, data);
  }

  download(data) {
    data = this.cleanObj(data);
    return this.apiService.getFile(`${this.path}/download`, data);
  }

  cleanObj(data) {
    Object.keys(data)
      .filter(k => k !== '_id' && k.startsWith('_'))
      .map(k => {
        console.log('delete', k);
        delete data[k];
      });
    return data;
  }

  getBlobURL(payload) {
    const blob = new Blob([payload.body], {
      type: payload.headers.get('content-type')
    });
    // Remove Company Ext
    const name = payload.name.replace(/\.[^/.]+$/, '');
    const ext = payload.uploadName.split('.').pop();
    const url = window.URL.createObjectURL(blob);
    return { _url: url, name: name + (ext ? `.${ext}` : '') };
  }
}
