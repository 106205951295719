import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Company } from '@app/shared/models';
import * as fromCoreStore from '@app/core/store';

@Component({
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss']
})
export class CompanySearchComponent implements OnInit {
  searchText: string;
  searchResult$: Observable<Company>;


  constructor(private store: Store<fromCoreStore.State>) { }

  @Input() action: string;

  ngOnInit() {
    this.searchResult$ = this.store.select(fromCoreStore.getSearchCompany);
  }
  search() {
    this.store.dispatch(
      new fromCoreStore.SearchCompany(this.searchText)
    );
  }
  clearInput() {
    this.searchText = '';
  }

  change(company) {
    this.store.dispatch(new fromCoreStore.UserCompanyChange({ linkType: '1', company_id: company.id }));
    this.clearInput()
  }

  linkAgentCompany(company: Company) {
    this.store.dispatch(
      new fromCoreStore.AgentCompanyLink({
        action_name: this.action,
        action_type: 'agent_link',
        company_id: company.id
      })
    );
    this.clearInput()
  }

  ngOnDestroy(): void {
  }

}
