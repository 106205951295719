import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectionStrategy
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';

import { Store } from '@ngrx/store';

import * as fromCoreStore from '@app/core/store';

import { Truck, } from '@app/shared/models/truck.model';
import { Fuels, States } from '@app/shared';

@Component({
  selector: 'app-truck-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './truck-form.component.html',
  styleUrls: ['./truck-form.component.scss']
})
export class TruckFormComponent implements OnInit {
  @Input()
  props: { truck: Truck };
  @Output()
  update = new EventEmitter<Truck>();
  @Output()
  create = new EventEmitter<Truck>();

  states;
  fuels;
  truck: Truck;
  truckForm: FormGroup;
  updateStatus: boolean;

  constructor(
    private store: Store<fromCoreStore.State>,
    private statesService: States,
    private fuelsService: Fuels
  ) {
    this.states = this.statesService.getStates();
    this.fuels = this.fuelsService.getFuels();
  }

  ngOnInit() {
    console.log(this.props.truck);
    this.truckForm = new FormGroup({
      axles: new FormControl(this.props.truck.axles),
      end_date: new FormControl(this.props.truck.end_date, []),
      fuel_id: new FormControl(this.props.truck.fuel_id),
      lic: new FormControl(this.props.truck.lic),
      make: new FormControl(this.props.truck.make),
      model: new FormControl(this.props.truck.model),
      start_date: new FormControl(this.props.truck.start_date, [Validators.required]),
      state_id: new FormControl(this.props.truck.state_id),
      status: new FormControl(this.props.truck.status, [Validators.required]),
      trailer_id: new FormControl(this.props.truck.trailer_id),
      unit: new FormControl(this.props.truck.unit, [Validators.required]),
      vin: new FormControl(this.props.truck.vin),
      w_loaded: new FormControl(this.props.truck.w_loaded),
      w_unloaded: new FormControl(this.props.truck.w_unloaded),
      year: new FormControl(this.props.truck.year)
    });
  }

  setValue(field, value) {
    if (this.truckForm && this.truckForm.controls[field]) {
      this.truckForm.controls[field].setValue(value);
    }
  }

  fc(name) {
    return this.truckForm.get(name) as FormControl;
  }

  onSubmit() {
    if (this.truckForm.invalid) {
      Object.keys(this.truckForm.controls).forEach(field => {
        this.truckForm.get(field).markAsTouched({ onlySelf: true });
      });
      return;
    }
    const truckFormLet: Truck = { ...this.props.truck, ...this.truckForm.value };
    if (this.props.truck.id) {
      this.store.dispatch(
        new fromCoreStore.UpdateTruck({
          id: truckFormLet.id,
          changes: truckFormLet
        })
      );
    } else {
      this.store.dispatch(
        new fromCoreStore.CreateTruck({
          truck: truckFormLet,
          closeModal: true
        })
      );
    }
    // this.store.dispatch(new fromCoreStore.CloseModal());
  }

  close() {
    this.store.dispatch(new fromCoreStore.CloseModal());
  }
}
