import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { environment } from 'environments/environment';

import { NgbDropdownModule, NgbPopoverModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthModule } from '@app/auth/auth.module';

import { reducers, effects, metaReducers } from '@app/core/store';
import { ChartsModule } from 'ng2-charts';

import { getErrorHandler } from '@app/core/services/sentry.service'; // Do not remove this line
import { ToastHelper } from '@app/core/services/toast.service'; // Do not remove this line
import {
  ApiService,
  UserService,
  PaymentService,
  Fuels,
  Languages,
  GoogleMapService,
  States
} from '@app/shared';

import { SharedModule } from '@app/shared/shared.module';

import { TokenInterceptor } from '@app/auth/services/token.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from '@app/core/containers/app.component';
import { HeaderComponent } from '@app/core/containers/header.component';
import { NotFoundPageComponent } from './containers/not-found-page';

import { ComponentsModule } from '@app/core/components';

import { ModalRootComponent } from '@app/core/containers/modal-root.component';
import { TruckFormComponent } from '@app/trucks/components/truck-form/truck-form.component';
import { CompanyFormComponent } from '@app/companies/components/company-form/company-form.component';
import { UserFormComponent } from '@app/settings/components/user-form/user-form.component';
import { CheckoutFormComponent } from '@app/settings/components/checkout-form/checkout-form.component';
import { AccountDetailsComponent } from '@app/manage/components/account-details/account-details.component';

@NgModule({
  declarations: [
    NotFoundPageComponent,
    AppComponent,
    ModalRootComponent,
    HeaderComponent,
    TruckFormComponent,
    CompanyFormComponent,
    UserFormComponent,
    CheckoutFormComponent,
    AccountDetailsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbDropdownModule, NgbPopoverModule, NgbTypeaheadModule,
    ChartsModule,
    StoreModule.forRoot({}, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictActionWithinNgZone: false,
      }, metaReducers
    }),
    StoreModule.forFeature('core', reducers),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    }),
    AppRoutingModule,
    AuthModule,
    ComponentsModule,
    SharedModule
  ],
  providers: [
    ApiService,
    UserService,
    PaymentService,
    Fuels,
    Languages,
    GoogleMapService,
    States,
    ToastHelper,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useFactory: getErrorHandler
    }
  ],
  entryComponents: [
    TruckFormComponent,
    CompanyFormComponent,
    UserFormComponent,
    CheckoutFormComponent,
    AccountDetailsComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
