import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromSettings from '@app/settings/store/reducers/settings.reducers';
import * as fromUsers from '@app/settings/store/reducers/users.reducers';
import * as fromBilling from '@app/settings/store/reducers/billing.reducers';
import * as fromAgent from '@app/settings/store/reducers/agent.reducers';
import * as fromReferrals from '@app/settings/store/reducers/settings.reducers';

export interface State {
  settings: fromSettings.State;
  users: fromUsers.State;
  billing: fromBilling.State;
  agent: fromAgent.State;
  referrals: fromReferrals.State;
}

export const reducers: ActionReducerMap<State> = {
  settings: fromSettings.reducer,
  users: fromUsers.reducer,
  billing: fromBilling.reducer,
  agent: fromAgent.reducer,
  referrals: fromReferrals.reducer
};

export const selectSettingsState = createFeatureSelector<State>('settings');
