import { Action } from '@ngrx/store';

import { Plan, AdditionalFeatures } from '@app/shared/models';

export enum BillingActionTypes {
  GET_ALL = '[Billing] GET_ALL',

  GET_PLAN = '[Billing] GET_PLAN',
  GET_PLAN_SUCCESS = '[Billing] GET_PLAN_SUCCESS',
  GET_PLAN_ERROR = '[Billing] GET_PLAN_ERROR',

  GET_PAYMENT = '[Billing] GET_PAYMENT',
  GET_PAYMENT_SUCCESS = '[Billing] GET_PAYMENT_SUCCESS',
  GET_PAYMENT_ERROR = '[Billing] GET_PAYMENT_ERROR',

  GET_ADDITIONAL_FEATURES = '[Billing] GET_ADDITIONAL_FEATURES',
  GET_ADDITIONAL_FEATURES_SUCCESS = '[Billing] GET_ADDITIONAL_FEATURES_SUCCESS',
  GET_ADDITIONAL_FEATURES_ERROR = '[Billing] GET_ADDITIONAL_FEATURES_ERROR',

  CHANGE_ADDITIONAL_FEATURES = '[Billing] CHANGE_ADDITIONAL_FEATURES',
  CHANGE_ADDITIONAL_FEATURES_SUCCESS = '[Billing] CHANGE_ADDITIONAL_FEATURES_SUCCESS',
  CHANGE_ADDITIONAL_FEATURES_ERROR = '[Billing] CHANGE_ADDITIONAL_FEATURES_ERROR',

  CALL_SERVER = '[Billing] CALL_SERVER',
  CALL_SERVER_SUCCESS = '[Billing] CALL_SERVER_SUCCESS',
  CALL_SERVER_ERROR = '[Billing] CALL_SERVER_ERROR',

  COMPLETE_PAYMENT = '[Billing] COMPLETE_PAYMENT',
  COMPLETE_PAYMENT_SUCCESS = '[Billing] COMPLETE_PAYMENT_SUCCESS',
  COMPLETE_PAYMENT_ERROR = '[Billing] COMPLETE_PAYMENT_ERROR',
}

export class GetAll implements Action {
  readonly type = BillingActionTypes.GET_ALL;
}

export class GetPlan implements Action {
  readonly type = BillingActionTypes.GET_PLAN;
}
export class GetPlanSuccess implements Action {
  readonly type = BillingActionTypes.GET_PLAN_SUCCESS;
  constructor(public payload:
    {
      plan: Plan,
      prices: any
    }
  ) { }
}
export class GetPlanError implements Action {
  readonly type = BillingActionTypes.GET_PLAN_ERROR;
  constructor(public payload: any) { }
}

export class GetPayment implements Action {
  readonly type = BillingActionTypes.GET_PAYMENT;
}
export class GetPaymentSuccess implements Action {
  readonly type = BillingActionTypes.GET_PAYMENT_SUCCESS;
  constructor(public payload: { payments: any }) { }
}
export class GetPaymentError implements Action {
  readonly type = BillingActionTypes.GET_PAYMENT_ERROR;
  constructor(public payload: any) { }
}

export class GetAdditionalFeatures implements Action {
  readonly type = BillingActionTypes.GET_ADDITIONAL_FEATURES;
}
export class GetAdditionalFeaturesSuccess implements Action {
  readonly type = BillingActionTypes.GET_ADDITIONAL_FEATURES_SUCCESS;
  constructor(public payload: { additional_features: Array<AdditionalFeatures> }) { }
}
export class GetAdditionalFeaturesError implements Action {
  readonly type = BillingActionTypes.GET_ADDITIONAL_FEATURES_ERROR;
  constructor(public payload: any) { }
}

export class ChangeAdditionalFeatures implements Action {
  readonly type = BillingActionTypes.CHANGE_ADDITIONAL_FEATURES;
  constructor(public payload: AdditionalFeatures) { }
}
export class ChangeAdditionalFeaturesSuccess implements Action {
  readonly type = BillingActionTypes.CHANGE_ADDITIONAL_FEATURES_SUCCESS;
  constructor(public payload: AdditionalFeatures) { }
}
export class ChangeAdditionalFeaturesError implements Action {
  readonly type = BillingActionTypes.CHANGE_ADDITIONAL_FEATURES_ERROR;
  constructor(public payload: any) { }
}

export class CompletePayment implements Action {
  readonly type = BillingActionTypes.COMPLETE_PAYMENT;
  constructor(public payload: any) { };
}
export class CompletePaymentSuccess implements Action {
  readonly type = BillingActionTypes.COMPLETE_PAYMENT_SUCCESS;
  constructor(public payload: any) { };
}
export class CompletePaymentError implements Action {
  readonly type = BillingActionTypes.COMPLETE_PAYMENT_ERROR;
}

export class CallServer implements Action {
  readonly type = BillingActionTypes.CALL_SERVER;
  constructor(public payload: {
    action_type: string,
    token: any,
    payment: any
  }) { }
}
export class CallServerSuccess implements Action {
  readonly type = BillingActionTypes.CALL_SERVER_SUCCESS;
  constructor(public payload: any) { }
}
export class CallServerError implements Action {
  readonly type = BillingActionTypes.CALL_SERVER_ERROR;
  constructor(public payload: any) { }
}


export type BillingActions =
  | GetAll
  | GetPlan
  | GetPlanSuccess
  | GetPlanError
  | GetPayment
  | GetPaymentSuccess
  | GetPaymentError
  | GetAdditionalFeatures
  | GetAdditionalFeaturesSuccess
  | GetAdditionalFeaturesError
  | ChangeAdditionalFeatures
  | ChangeAdditionalFeaturesSuccess
  | ChangeAdditionalFeaturesError
  | CompletePayment
  | CompletePaymentSuccess
  | CompletePaymentError
  | CallServer
  | CallServerSuccess
  | CallServerError;
