import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of, map, switchMap, catchError } from 'rxjs';
import { UserService } from '@app/core/services/user.service';

import * as UserActions from '@app/core/store/actions/user.actions';
import { AuthUser } from '@app/shared';

// import { UserService } from '@app/users/services/user.service';
// import { AccountService } from '@app/crm/services/account.service';

@Injectable()
export class UserEffects {


  getUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserActions.GetUsers>(UserActions.UserActionTypes.GET_USERS),
      switchMap((action: UserActions.GetUsers) => {
        return this._UserService.getAll(action.payload.account_id).pipe(
          map(resp => {
            return new UserActions.GetUsersSuccess({ users: resp as AuthUser[] });
          }),
          catchError(error => of(new UserActions.GetUsersError(error)))
        );
      })
    )
  });
  // 
  // getUsers$ = createEffect(() => {
  // return this.actions$.ofType(UserActions.UserActionTypes.GET_USERS).pipe(
  //   switchMap(() => {
  //     return this._AccountService.getAdmins().pipe(
  //       map(resp => {
  //         return new UserActions.GetUsersSuccess({
  //           users: resp && resp.adminList ? resp.adminList : []
  //         });
  //       }),
  //       catchError(error => of(new UserActions.GetUsersError(error)))
  //     );
  //   })
  // );

  // 
  // createUser$: Observable<Action> = createEffect(() => {
  // return this.actions$
  //   .ofType<UserActions.CreateUser>(UserActions.UserActionTypes.ADD_USER)
  //   .pipe(
  //     tap(() => {
  //       console.log('createUser');
  //     })
  //     mergeMap(action => {
  //       return this.UserService.add(action.payload.user).pipe(
  //         map((data: Response) => {
  //           this.notify.ok('User Added Successful!');
  //           return new UserActions.CreateUserSuccess({
  //             user: { ...data['data'] }
  //           });
  //         }),
  //         catchError(error => {
  //           this.notify.error(error);
  //           return of(new UserActions.CreateUserError(error));
  //         })
  //       );
  //     })
  //   );

  // 
  // updateUser$: Observable<Action> = createEffect(() => {
  // return this.actions$
  //   .ofType<UserActions.UpdateUser>(UserActions.UserActionTypes.UPDATE)
  //   .pipe(
  //     tap(() => {
  //       console.log('updateUser');
  //     })
  //     mergeMap(action => {
  //       console.log(action.payload);
  //       return this.UserService.update(action.payload.user.changes).pipe(
  //         map(data => {
  //           this.notify.ok('User update was Successful!');
  //           return new UserActions.UpdateUserSuccess({
  //             user: data
  //           });
  //         }),
  //         catchError(error => {
  //           this.notify.error(error);
  //           return of(
  //             new UserActions.UpdateUserError(action.payload.user.changes)
  //           );
  //         })
  //       );
  //     })
  //   );

  // 
  // deleteUser$: Observable<Action> = createEffect(() => {
  // return this.actions$
  //   .ofType<UserActions.DeleteUser>(UserActions.UserActionTypes.DELETE)
  //   .pipe(
  //     tap(() => {
  //       console.log('deleteUser');
  //     })
  //     mergeMap(action => {
  //       return this.UserService.delete(action.payload.id).pipe(
  //         map((data: Response) => {
  //           this.notify.ok('User Deleted Successful!');
  //           return new UserActions.DeleteUserSuccess(data);
  //         }),
  //         catchError(error => {
  //           this.notify.error(error);
  //           return of(new UserActions.DeleteUserError(action.payload));
  //         })
  //       );
  //     })
  //   );


  getUsersByCompany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserActions.GetUsersByCompany>(UserActions.UserActionTypes.GET_USERS_BY_COMPANY),
      switchMap((action: UserActions.GetUsersByCompany) => {
        return this._UserService.getUsersByCompanyId(action.payload.companyId).pipe(
          map(resp => {
            return new UserActions.GetUsersByCompanySuccess({ drivers: resp as AuthUser[] });
          }),
          catchError(error => of(new UserActions.GetUsersByCompanyError(error)))
        );
      })
    )
  });

  constructor(
    private actions$: Actions,
    private _UserService: UserService,
  ) { }
}
