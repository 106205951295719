import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthUser } from '@app/shared/models';
import * as fromCoreStore from '@app/core/store';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss']
})
export class SelectUserComponent implements OnInit {

  users: AuthUser[];
  @Input()
  currentUserId;
  @Output()
  onChange = new EventEmitter<number>();

  usersListener: Subscription;

  change(userId: number) {
    this.onChange.emit(userId);
  }

  constructor(private store: Store<fromCoreStore.State>) { }

  ngOnInit() {
    this.usersListener = this.store.select(fromCoreStore.selectAllUsers).subscribe(users => this.users = users);
  }

  ngOnDestroy(): void {
    this.usersListener.unsubscribe();
  }
}
