import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ToastItemComponent } from '@app/core/components/toast-item.component';
import { ToastListComponent } from '@app/core/components/toast-list.component';
export const COMPONENTS = [ToastItemComponent, ToastListComponent];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class ComponentsModule {}
