export interface Note {
  $$hashKey: string,
  account_id: string,
  content: string,
  created: string,
  creator_id: string,
  deleted_at: null,
  id: string,
  modified: string,
  name: string,
  resource: string,
  resource_id: string

  // _id?: string;
  // content: string;
  // creator?: {
  //   _id: string;
  //   name: string;
  // };
  // createdAt?: string;
  // followUp?: string;
  // assignee?: any[];
  // followUpType?: string;
  // isCompleted?: string;
  // edit?: Boolean;
  // visibility?: any[];
}

export function genMockNote(): Note {
  return {
    $$hashKey: "03X",
    account_id: "869384",
    content: "2016 3rd Quoter Oregon miles were paid @ entry.",
    created: "2017-01-25 19:14:02",
    creator_id: "5",
    deleted_at: null,
    id: "1",
    modified: "2017-01-25 19:14:02",
    name: "Serg",
    resource: "company",
    resource_id: "160"
  }
}
