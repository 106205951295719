import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Company } from '@app/shared/models';

export enum CompanyActionTypes {
  GET_COMPANIES = '[Company] GET_COMPANIES',
  GET_COMPANIES_SUCCESS = '[Company] GET_COMPANIES_SUCCESS',
  GET_COMPANIES_ERROR = '[Company] GET_COMPANIES_ERROR',

  ADD_COMPANY = '[Company] ADD_COMPANY',
  ADD_COMPANY_SUCCESS = '[Company] ADD_COMPANY_SUCCESS',
  ADD_COMPANY_ERROR = '[Company] ADD_COMPANY_ERROR',

  GET_COMPANY = '[Company] GET_COMPANY',
  GET_COMPANY_SUCCESS = '[Company] GET_COMPANY_SUCCESS',
  GET_COMPANY_ERROR = '[Company] GET_COMPANY_ERROR',

  UPDATE = '[Company] UPDATE',
  UPDATE_SUCCESS = '[Company] UPDATE_SUCCESS',
  UPDATE_ERROR = '[Company] UPDATE_ERROR',

  DELETE = '[Company] DELETE',
  DELETE_SUCCESS = '[Company] DELETE_SUCCESS',
  DELETE_ERROR = '[Company] DELETE_ERROR',

  SET_CURRENT_ID = '[Company] SET_CURRENT_ID',

  AGENT_COMPANY_LINK = '[Company] AGENT_COMPANY_LINK',
  AGENT_COMPANY_LINK_SUCCESS = '[Company] AGENT_COMPANY_LINK_SUCCESS',
  AGENT_COMPANY_LINK_ERROR = '[Company] AGENT_COMPANY_LINK_ERROR',

  USER_COMPANY_CHANGE = '[Company] USER_COMPANY_CHANGE',
  USER_COMPANY_CHANGE_SUCCESS = '[Company] USER_COMPANY_CHANGE_SUCCESS',
  USER_COMPANY_CHANGE_ERROR = '[Company] USER_COMPANY_CHANGE_ERROR',

  SEARCH_COMPANY = '[Company] SEARCH_COMPANY',
  SEARCH_COMPANY_RESET = '[Company] SEARCH_COMPANY_RESET',
  SEARCH_COMPANY_SUCCESS = '[Company] SEARCH_COMPANY_SUCCESS',
  SEARCH_COMPANY_ERROR = '[Company] SEARCH_COMPANY_ERROR',

  CLEAR_COMPANIES = '[Company] CLEAR_COMPANIES'
}

// Actions for Getting  Companies

export class AgentCompanyLink implements Action {
  readonly type = CompanyActionTypes.AGENT_COMPANY_LINK;
  constructor(
    public payload: {
      action_name?: string,
      action_type: string,
      company_id: string
    }
  ) { }
}
export class AgentCompanyLinkSuccess implements Action {
  readonly type = CompanyActionTypes.AGENT_COMPANY_LINK_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: Company[]) {}
  constructor(public payload: Company) { }
}

export class AgentCompanyLinkError implements Action {
  readonly type = CompanyActionTypes.AGENT_COMPANY_LINK_ERROR;
  constructor(public payload: any) { }
}

export class UserCompanyChange implements Action {
  readonly type = CompanyActionTypes.USER_COMPANY_CHANGE;
  constructor(
    public payload: {
      linkType: string,
      company_id: string
    }
  ) { }
}
export class UserCompanyChangeSuccess implements Action {
  readonly type = CompanyActionTypes.USER_COMPANY_CHANGE_SUCCESS;
  constructor(public payload: any) { }
}

export class UserCompanyChangeError implements Action {
  readonly type = CompanyActionTypes.USER_COMPANY_CHANGE_ERROR;
  constructor(public payload: any) { }
}
export class GetCompanies implements Action {
  readonly type = CompanyActionTypes.GET_COMPANIES;

  constructor(public payload?: { account_id: string }) { }
}

export class GetCompaniesSuccess implements Action {
  readonly type = CompanyActionTypes.GET_COMPANIES_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: Company[]) {}
  constructor(public payload: { companies: Company[] }) { }
}

export class GetCompaniesError implements Action {
  readonly type = CompanyActionTypes.GET_COMPANIES_ERROR;
  constructor(public payload: any) { }
}
// Action for Creating Company
export class CreateCompany implements Action {
  readonly type = CompanyActionTypes.ADD_COMPANY;

  constructor(public payload: { company: Company }) { }
}
export class CreateCompanySuccess implements Action {
  readonly type = CompanyActionTypes.ADD_COMPANY_SUCCESS;

  constructor(public payload: { company: Company }) { }
}
export class CreateCompanyError implements Action {
  readonly type = CompanyActionTypes.ADD_COMPANY_ERROR;
  constructor(public payload: any) { }
}
export class GetCompany implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY;
  constructor(public payload: { _id: string }) { }
}
export class GetCompanySuccess implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_SUCCESS;

  constructor(public payload: { company: Company }) { }
}

export class GetCompanyError implements Action {
  readonly type = CompanyActionTypes.GET_COMPANY_ERROR;
  constructor(public payload: any) { }
}
export class UpdateCompany implements Action {
  readonly type = CompanyActionTypes.UPDATE;
  constructor(public payload: { company: Company }) { }
}

export class UpdateCompanySuccess implements Action {
  readonly type = CompanyActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { company: Company }) { }
}

export class UpdateCompanyError implements Action {
  readonly type = CompanyActionTypes.UPDATE_ERROR;

  constructor(public payload: any) { }
}

export class DeleteCompany implements Action {
  readonly type = CompanyActionTypes.DELETE;

  constructor(public payload: { company: Company }) { }
}
export class DeleteCompanySuccess implements Action {
  readonly type = CompanyActionTypes.DELETE_SUCCESS;

  constructor(public payload: any) { }
}
export class DeleteCompanyError implements Action {
  readonly type = CompanyActionTypes.DELETE_ERROR;

  constructor(public payload: Company) { }
}

export class SearchCompany implements Action {
  readonly type = CompanyActionTypes.SEARCH_COMPANY;
  constructor(public payload: string) { }
}
export class SearchCompanyReset implements Action {
  readonly type = CompanyActionTypes.SEARCH_COMPANY_RESET;
}
export class SearchCompanySuccess implements Action {
  readonly type = CompanyActionTypes.SEARCH_COMPANY_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: Company[]) {}
  constructor(public payload: Company) { }
}

export class SearchCompanyError implements Action {
  readonly type = CompanyActionTypes.SEARCH_COMPANY_ERROR;
  constructor(public payload: any) { }
}

export class SetCurrentCompanyId implements Action {
  readonly type = CompanyActionTypes.SET_CURRENT_ID;

  constructor(public payload: string) { }
}

export class ClearCompanies implements Action {
  readonly type = CompanyActionTypes.CLEAR_COMPANIES;
}

export type CompanyActions =
  | GetCompany
  | GetCompanySuccess
  | GetCompanyError
  | UpdateCompany
  | UpdateCompanySuccess
  | UpdateCompanyError
  | GetCompanies
  | GetCompaniesSuccess
  | GetCompaniesError
  | CreateCompany
  | CreateCompanySuccess
  | CreateCompanyError
  | DeleteCompany
  | DeleteCompanySuccess
  | DeleteCompanyError
  | SetCurrentCompanyId
  | AgentCompanyLink
  | AgentCompanyLinkSuccess
  | AgentCompanyLinkError
  | UserCompanyChange
  | UserCompanyChangeSuccess
  | UserCompanyChangeError
  | ClearCompanies
  | SearchCompany
  | SearchCompanyReset
  | SearchCompanySuccess
  | SearchCompanyError;
