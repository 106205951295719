import { ToastEffects } from '@app/core/store/effects/toast.effects';
import { UserEffects } from '@app/core/store/effects/user.effects';
import { CompanyEffects } from '@app/core/store/effects/company.effects';
import { TruckEffects } from '@app/core/store/effects/truck.effects';
import { TripEffects } from '@app/core/store/effects/trip.effects';


export const effects: any[] = [ToastEffects, UserEffects, CompanyEffects, TruckEffects, TripEffects];

export * from '@app/core/store/effects/toast.effects';
export * from '@app/core/store/effects/user.effects';
export * from '@app/core/store/effects/company.effects';
export * from '@app/core/store/effects/truck.effects';
export * from '@app/core/store/effects/trip.effects';
