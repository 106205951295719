import {
  TruckActions,
  TruckActionTypes
} from '@app/core/store/actions/truck.actions';
import {
  AuthActions,
  AuthActionTypes
} from '@app/auth/store/actions/auth.actions';
import {
  AuthUser,
  AuthPlan,
  AuthPermissions,
  AuthSettings,
  AuthAccount
} from '@app/shared/models/auth.model';
import { Company, Truck } from '@app/shared';

export interface State {
  user: AuthUser | null;
  plan: AuthPlan | null;
  permissions: AuthPermissions | null;
  settings: AuthSettings | null;
  company: Company | null;
  truck: Truck | null;
  account: AuthAccount | null;
  checkedToken: boolean;
  topMenu: any[];
}

export const initialState: State = {
  user: null,
  plan: null,
  permissions: null,
  settings: null,
  company: null,
  truck: null,
  account: null,
  checkedToken: false,
  topMenu: []
};



const payloadRegroup = (resp) => {
  return {
    user: resp.user,
    plan: resp.plan,
    permissions: resp.permissions,
    settings: resp.settings,
    company: resp.company,
    truck: resp.truck,
    account: {
      created: resp.created,
      exp: resp.exp,
      ref: resp.ref,
      status: resp.status,
      trucks: resp.trucks
    }
  };
}

export function reducer(state = initialState, action: AuthActions | TruckActions): State {
  switch (action.type) {
    case AuthActionTypes.LoginSuccess:
      return { ...state, checkedToken: true, ...payloadRegroup(action.payload) };

    case AuthActionTypes.LogoutConfirmed:
      return initialState;

    case TruckActionTypes.CHANGE_USER_TRUCK_SUCCESS:
      return { ...state, truck: action.payload };

    case AuthActionTypes.GET_TOP_MENU_SUCCESS:
      return { ...state, topMenu: action.payload };

    default:
      return state;
  }
}

export const selectAuthUser = (state: State) => state.user;
export const selectAuthPlan = (state: State) => state.plan;
export const selectAuthPermissions = (state: State) => state.permissions;
export const selectAuthSettings = (state: State) => state.settings;
export const selectAuthCompany = (state: State) => state.company;
export const selectAuthTruck = (state: State) => state.truck;
export const selectAuthAccount = (state: State) => state.account;
export const selectCheckedToken = (state: State) => state.checkedToken;
export const selectTopMenu = (state: State) => state.topMenu;
