import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of, map, switchMap, mergeMap, catchError } from 'rxjs';

import * as UserActions from '@app/settings/store/actions/users.actions';
import { ToastHelper } from '@app/core/services/toast.service';
import { Router } from '@angular/router';
import { UserService } from '@app/shared';

@Injectable()
export class UsersEffects {

  get$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserActions.GetUsers>(UserActions.UserActionTypes.GET),
      switchMap((action: UserActions.GetUsers) => {
        return this._UserService.getUserList().pipe(
          map(resp => {
            return new UserActions.GetUsersSuccess({
              users: resp || []
            });
          }),
          catchError(error => of(new UserActions.GetUsersError(error)))
        );
      })
    )
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserActions.UpdateUsers>(UserActions.UserActionTypes.UPDATE_USER),
      switchMap((action: UserActions.UpdateUsers) => {
        return this._UserService.changeUser(action.payload).pipe(
          map(resp => {
            return new UserActions.UpdateUsersSuccess({
              users: resp
            });
          }),
          catchError(error => of(new UserActions.UpdateUsersError(error)))
        );
      })
    )
  });

  add$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserActions.AddUser>(UserActions.UserActionTypes.ADD_USER),
      switchMap((action: UserActions.AddUser) => {
        return this._UserService.addUser(action.payload).pipe(
          map(resp => {
            return new UserActions.AddUserSuccess(resp);
          }),
          catchError(error => of(new UserActions.AddUserSuccess(error)))
        );
      })
    )
  });

  remove$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserActions.DeleteUser>(UserActions.UserActionTypes.DELETE_USER),
      switchMap((action: UserActions.DeleteUser) => {
        return this._UserService.deleteUser(action.payload.user).pipe(
          map(resp => {
            return new UserActions.DeleteUserSuccess(resp);
          }),
          catchError(error => of(new UserActions.DeleteUserError(error)))
        );
      })
    )
  });

  forgot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<UserActions.ForgotUser>(UserActions.UserActionTypes.FORGOT_USER),
      switchMap((action: UserActions.ForgotUser) => {
        return this._UserService.forgot(action.payload).pipe(
          map(resp => {
            return new UserActions.ForgotUserSuccess(resp);
          }),
          catchError(error => of(new UserActions.ForgotUserError(error)))
        );
      })
    )
  });

  constructor(
    private actions$: Actions,
    private _UserService: UserService,
    private notify: ToastHelper,
    private router: Router
  ) { }
}
