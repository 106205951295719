import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromFeature from '@app/settings/store/reducers';
import * as fromBilling from '@app/settings/store/reducers/billing.reducers';

// Billing State
export const selectBillingState = createSelector(
  fromFeature.selectSettingsState,
  (state: fromFeature.State) => state.billing
);

export const getBillingLoaded = createSelector(
  selectBillingState,
  fromBilling.getLoaded
);
export const getBillingLoading = createSelector(
  selectBillingState,
  fromBilling.getLoading
);

export const selectPlan = createSelector(
  selectBillingState,
  fromBilling.getPlan
);
export const selectPrices = createSelector(
  selectBillingState,
  fromBilling.getPrices
);
export const selectPayments = createSelector(
  selectBillingState,
  fromBilling.getPayments
);
export const selectPaymentById = (id) => createSelector(
  selectPayments,
  (payments) => {
    if (payments) {
      return payments.find(p => p.id === id);
    }
  }
);
export const selectSession = createSelector(
  selectBillingState,
  fromBilling.getSession
);
export const selectAdditionalFeatures = createSelector(
  selectBillingState,
  fromBilling.getAdditionalFeatures
);
