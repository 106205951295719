import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  SettingActions,
  SettingActionTypes
} from '@app/settings/store/actions/setting.actions';
import { Settings } from '@app/shared/models';

export interface State extends EntityState<Settings> {
  selectId: string | null;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<Settings> = createEntityAdapter<Settings>({
  selectId: (setting: Settings) => setting.id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectId: null,
  loaded: false,
  loading: false
});

export function reducer(state = initialState, action: SettingActions): State {
  switch (action.type) {
    case SettingActionTypes.GET_SUCCESS: {
      return adapter.setAll(action.payload.settings, {
        ...state,
        selectId: state.selectId,
        loaded: true,
        loading: false
      });
    }

    case SettingActionTypes.UPDATE_SETTINGS: {
      return adapter.updateOne(action.payload, state);
    }

    case SettingActionTypes.UPDATE_SETTINGS_SUCCESS: {
      return adapter.updateOne(action.payload.settings, state);
    }

    case SettingActionTypes.UPDATE_SETTINGS_ERROR: {
      return adapter.removeOne(action.payload.setting.id, state);
    }

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getSelectedId = (state: State) => state.selectId;
export const {
  // select the array of Settings ids
  selectIds: selectSettingsIds,
  // select the dictionary of Settings entities
  selectEntities: selectSettingsEntities,
  // select the array of settings
  selectAll: selectAllSettings,
  // select the total Settings count
  selectTotal: selectSettingsTotal
} = adapter.getSelectors();
