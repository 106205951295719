import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { createEffect, Actions, ofType, act } from '@ngrx/effects';
import { Observable, of, map, switchMap, mergeMap, catchError } from 'rxjs';

import * as TruckActions from '@app/core/store/actions/truck.actions';
import * as LayoutActions from '@app/core/store/actions/layout.actions';
import * as CompanyActions from '@app/core/store/actions/company.actions';
import { TruckService } from '@app/core/services/truck.service';
import { ToastHelper } from '@app/core/services/toast.service';

@Injectable()
export class TruckEffects {

  getTrucks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TruckActions.GetTrucks>(TruckActions.TruckActionTypes.GET_TRUCKS),
      switchMap(() => {
        return this._TruckService.getAll().pipe(
          map(resp => {
            return new TruckActions.GetTrucksSuccess({
              trucks: resp || null
            });
          }),
          catchError(error => of(new TruckActions.GetTrucksError(error)))
        );
      })
    )
  });


  getTrucksByCompany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<TruckActions.GetTrucksByCompany | CompanyActions.SetCurrentCompanyId>(TruckActions.TruckActionTypes.GET_TRUCKS_BY_COMPANY),
      switchMap((action: TruckActions.GetTrucksByCompany) => {
        return this._TruckService.getTrucksByCompany(action.payload.companyId).pipe(
          map(resp => {
            return new TruckActions.GetTrucksSuccess({
              trucks: resp || null
            });
          }),
          catchError(error => of(new TruckActions.GetTrucksError(error)))
        );
      })
    )
  });


  createTruck$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<TruckActions.CreateTruck>(TruckActions.TruckActionTypes.ADD_TRUCK),
      mergeMap(action => {
        return this._TruckService.create(action.payload.truck).pipe(
          switchMap(resp => {
            this.notify.ok('Truck Added Successful!');
            return [
              new LayoutActions.CloseModal(),
              new TruckActions.CreateTruckSuccess({ truck: resp })
            ];
          }),
          catchError(error => {
            this.notify.error(error);
            return of(new TruckActions.CreateTruckError(error));
          })
        );
      })
    )
  });


  updateTruck$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<TruckActions.UpdateTruck>(TruckActions.TruckActionTypes.UPDATE),
      mergeMap(action => {
        return this._TruckService.update(action.payload.changes).pipe(
          switchMap(resp => {
            this.notify.ok('Truck update was Successful!');
            return [
              new LayoutActions.CloseModal(),
              new TruckActions.UpdateTruckSuccess({
                truck: { id: resp.id, changes: resp }
              })]
          }),
          catchError(error => {
            this.notify.error(error);
            return of(new TruckActions.UpdateTruckError(error));
          })
        );
      })
    )
  });


  deleteTruck$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<TruckActions.DeleteTruck>(TruckActions.TruckActionTypes.DELETE),
      mergeMap(action => {
        return this._TruckService.delete(action.payload.truck).pipe(
          map((data: Response) => {
            this.notify.ok('Truck Deleted Successful!');
            return new TruckActions.DeleteTruckSuccess(action.payload);
          }),
          catchError(error => {
            this.notify.error(error);
            return of(new TruckActions.DeleteTruckError(error));
          })
        );
      })
    )
  });


  changeUserTruck$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<TruckActions.ChangeUserTruck>(TruckActions.TruckActionTypes.CHANGE_USER_TRUCK),
      mergeMap(action => {
        return this._TruckService.changeUserTruck(action.payload).pipe(
          map((data) => {
            this.notify.ok('Truck Changed Successful!');
            const newTruck = action.payload.trucks.find(truck => truck.id === data.truck_id);
            return new TruckActions.ChangeUserTruckSuccess(newTruck);
          }),
          catchError(error => {
            this.notify.error(error);
            return of(new TruckActions.ChangeUserTruckError(error));
          })
        );
      })
    )
  });

  constructor(
    private actions$: Actions,
    private _TruckService: TruckService,
    private notify: ToastHelper
  ) { }
}
