import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Truck, Company } from '@app/shared/models';

export enum TruckActionTypes {
  GET_TRUCKS = '[Truck] GET_TRUCKS',
  GET_TRUCKS_BY_COMPANY = '[Truck] GET_TRUCKS_BY_COMPANY',
  GET_TRUCKS_SUCCESS = '[Truck] GET_TRUCKS_SUCCESS',
  GET_TRUCKS_ERROR = '[Truck] GET_TRUCKS_ERROR',

  ADD_TRUCK = '[Truck] ADD_TRUCK',
  ADD_TRUCK_SUCCESS = '[Truck] ADD_TRUCK_SUCCESS',
  ADD_TRUCK_ERROR = '[Truck] ADD_TRUCK_ERROR',

  GET_TRUCK = '[Truck] GET_TRUCK',
  GET_TRUCK_SUCCESS = '[Truck] GET_TRUCK_SUCCESS',
  GET_TRUCK_ERROR = '[Truck] GET_TRUCK_ERROR',

  UPDATE = '[Truck] UPDATE',
  UPDATE_SUCCESS = '[Truck] UPDATE_SUCCESS',
  UPDATE_ERROR = '[Truck] UPDATE_ERROR',

  DELETE = '[Truck] DELETE',
  DELETE_SUCCESS = '[Truck] DELETE_SUCCESS',
  DELETE_ERROR = '[Truck] DELETE_ERROR',

  SET_CURRENT_ID = '[Truck] SET_CURRENT_ID',
  CLEAR_COMPANIES = '[Truck] CLEAR_COMPANIES',

  CHANGE_USER_TRUCK = '[Truck] CHANGE_USER_TRUCK',
  CHANGE_USER_TRUCK_SUCCESS = '[Truck] CHANGE_USER_TRUCK_SUCCESS',
  CHANGE_USER_TRUCK_ERROR = '[Truck] CHANGE_USER_TRUCK_ERROR',
}

// Actions for Getting  Trucks

export class GetTrucks implements Action {
  readonly type = TruckActionTypes.GET_TRUCKS;
}
export class GetTrucksByCompany implements Action {
  readonly type = TruckActionTypes.GET_TRUCKS_BY_COMPANY;
  constructor(public payload: { companyId: string }) { }
}
export class GetTrucksSuccess implements Action {
  readonly type = TruckActionTypes.GET_TRUCKS_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: Truck[]) {}
  constructor(public payload: { trucks: Truck[] }) { }
}

export class GetTrucksError implements Action {
  readonly type = TruckActionTypes.GET_TRUCKS_ERROR;
  constructor(public payload: any) { }
}
// Action for Creating Truck
export class CreateTruck implements Action {
  readonly type = TruckActionTypes.ADD_TRUCK;

  constructor(public payload: { truck: Truck, closeModal?: boolean }) { }
}
export class CreateTruckSuccess implements Action {
  readonly type = TruckActionTypes.ADD_TRUCK_SUCCESS;

  constructor(public payload: { truck: Truck }) { }
}
export class CreateTruckError implements Action {
  readonly type = TruckActionTypes.ADD_TRUCK_ERROR;
  constructor(public payload: any) { }
}
export class GetTruck implements Action {
  readonly type = TruckActionTypes.GET_TRUCK;
  constructor(public payload: { _id: string }) { }
}
export class GetTruckSuccess implements Action {
  readonly type = TruckActionTypes.GET_TRUCK_SUCCESS;

  constructor(public payload: { truck: Truck }) { }
}

export class GetTruckError implements Action {
  readonly type = TruckActionTypes.GET_TRUCK_ERROR;
  constructor(public payload: any) { }
}
export class UpdateTruck implements Action {
  readonly type = TruckActionTypes.UPDATE;

  constructor(public payload: Update<Truck>) { }
}

export class UpdateTruckSuccess implements Action {
  readonly type = TruckActionTypes.UPDATE_SUCCESS;

  constructor(public payload: { truck: Update<Truck> }) { }
}

export class UpdateTruckError implements Action {
  readonly type = TruckActionTypes.UPDATE_ERROR;

  constructor(public payload: any) { }
}

export class DeleteTruck implements Action {
  readonly type = TruckActionTypes.DELETE;

  constructor(public payload: { truck: Truck }) { }
}
export class DeleteTruckSuccess implements Action {
  readonly type = TruckActionTypes.DELETE_SUCCESS;

  constructor(public payload: any) { }
}
export class DeleteTruckError implements Action {
  readonly type = TruckActionTypes.DELETE_ERROR;

  constructor(public payload: Truck) { }
}

export class SetCurrentTruckId implements Action {
  readonly type = TruckActionTypes.SET_CURRENT_ID;

  constructor(public payload: string) { }
}

export class ClearTrucks implements Action {
  readonly type = TruckActionTypes.CLEAR_COMPANIES;
}

export class ChangeUserTruck implements Action {
  readonly type = TruckActionTypes.CHANGE_USER_TRUCK;

  constructor(public payload: {
    driver_id: string,
    id: string,
    truck_id: string,
    trucks: Truck[]
  }) { }
}
export class ChangeUserTruckSuccess implements Action {
  readonly type = TruckActionTypes.CHANGE_USER_TRUCK_SUCCESS;

  constructor(public payload: Truck) { }
}
export class ChangeUserTruckError implements Action {
  readonly type = TruckActionTypes.CHANGE_USER_TRUCK_ERROR;

  constructor(public payload: any) { }
}

export type TruckActions =
  | GetTruck
  | GetTruckSuccess
  | GetTruckError

  | UpdateTruck
  | UpdateTruckSuccess
  | UpdateTruckError

  | GetTrucks
  | GetTrucksSuccess
  | GetTrucksError

  | CreateTruck
  | CreateTruckSuccess
  | CreateTruckError

  | DeleteTruck
  | DeleteTruckSuccess
  | DeleteTruckError

  | SetCurrentTruckId

  | ClearTrucks

  | ChangeUserTruck
  | ChangeUserTruckSuccess
  | ChangeUserTruckError;
