import {
  Component,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  ElementRef,
  OnInit,
  NgZone,
  ViewChild
} from '@angular/core';

declare function require(name: string);
const flatpickr = require('flatpickr').default;

@Component({
  selector: 'app-date',
  styleUrls: ['./date.component.scss'],
  templateUrl: `./date.component.html`
})
export class DateComponent implements OnInit, OnChanges {
  @ViewChild('dateTime', { static: true })
  dateTime: ElementRef;
  @Input()
  isContainerStyle;
  @Input()
  styleWidth;
  @Input()
  parent;
  @Input()
  label;
  @Input()
  ids;
  @Input()
  name;
  @Input()
  startKey;
  @Input()
  endKey;
  @Input()
  time;
  @Input()
  options;
  @Input()
  error;
  @Input()
  regex;
  @Input()
  disabled: boolean;

  @Output()
  onChange = new EventEmitter();

  stringDate;

  dateObject = new Date();

  tracker;
  el: ElementRef;
  show = false;
  save = new EventEmitter();
  onBlur = new EventEmitter();
  m: Number = 3;

  type: String = 'month';

  invalid = false;

  constructor(el: ElementRef, private zone: NgZone) {
    this.el = el;
  }

  ngOnInit() {
    // this.originalText = ; // Saves a copy of the original field info.
  }

  convertDate(key, local = true) {
    if (!this.parent || !this.parent.hasOwnProperty(key)) {
      return undefined;
    }
    // local = this.options && this.options.timezone ? true : false;
    // if (local) {
    //   return moment(this.parent[key])
    //     .local()
    //     .format('MM/DD/YY');
    // }
    // return moment.utc(this.parent[key]).format();
    return this.parent[key];
  }

  ngOnChanges(): void {
    const self = this;
    const options = {
      locale: 'en',
      defaultDate: [this.convertDate(this.startKey)],
      dateFormat: 'm/d/y',
      onChange: (selectedDates, dateStr, instance) => {
        self.updateDates(selectedDates);
      }
    };

    if (this.time) {
      options.dateFormat += ' H:i';
      options['time_24hr'] = true;
      options['enableTime'] = true;
    }

    if (this.options && this.options.mode === 'range') {
      options.defaultDate = [
        this.convertDate(this.startKey),
        this.convertDate(this.endKey)
      ];
      options['mode'] = this.options.mode;
    }
    flatpickr(this.dateTime.nativeElement, options);
  }

  getWeek(dateObject) {
    const firstDay = new Date(
      dateObject.getFullYear(),
      dateObject.getMonth(),
      dateObject.getDate() - dateObject.getDay()
    );
    const lastDay = new Date(
      dateObject.getFullYear(),
      dateObject.getMonth(),
      dateObject.getDate() - dateObject.getDay() + 6
    );
    return [firstDay, lastDay];
  }

  getMonth(dateObject) {
    const firstDay = new Date(
      dateObject.getFullYear(),
      dateObject.getMonth(),
      1
    );
    const lastDay = new Date(
      dateObject.getFullYear(),
      dateObject.getMonth() + 1,
      0
    );
    return [firstDay, lastDay];
  }

  getYear(dateObject) {
    const firstDay = new Date(dateObject.getFullYear(), 0, 1);
    const lastDay = new Date(dateObject.getFullYear() + 1, 0, 0);
    return [firstDay, lastDay];
  }

  updateDates(dates) {
    // this.parent[this.startKey] = dates[0] ? moment.utc(dates[0]).format() : '';
    this.parent[this.startKey] = dates[0] ? dates[0] : '';

    if (dates.length > 1) {
      this.parent[this.endKey] = dates[1] ? dates[1] : '';
    } else if (this.endKey) {
      this.parent[this.endKey] = '';
    }

    this.onChange.emit(this.parent);
  }

  clearDate() {
    this.dateTime.nativeElement.value = '';
    this.updateDates([]);
  }
}
