import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/auth/store/reducers';
import * as fromAuth from '@app/auth/store/reducers/auth.reducers';

export const selectAuthState = createSelector(
  fromFeature.featureSelectAuthState,
  (state: fromFeature.State) => state.auth
);

export const selectAuthUser = createSelector(
  selectAuthState,
  fromAuth.selectAuthUser
);
export const selectAuthPlan = createSelector(
  selectAuthState,
  fromAuth.selectAuthPlan
);
export const selectAuthPermissions = createSelector(
  selectAuthState,
  fromAuth.selectAuthPermissions
);
export const selectAuthSettings = createSelector(
  selectAuthState,
  fromAuth.selectAuthSettings
);
export const selectAuthCompany = createSelector(
  selectAuthState,
  fromAuth.selectAuthCompany
);
export const selectAuthTruck = createSelector(
  selectAuthState,
  fromAuth.selectAuthTruck
);
export const selectAuthAccount = createSelector(
  selectAuthState,
  fromAuth.selectAuthAccount
);
export const selectIsLoggedIn = createSelector(
  selectAuthUser,
  user => !!user
);
export const selectTopMenu = createSelector(
  selectAuthState,
  fromAuth.selectTopMenu
);

export const selectCheckedToken = createSelector(
  selectAuthState,
  fromAuth.selectCheckedToken
);
