import * as fromUsers from '@app/core/store/actions/user.actions';

import { AuthUser } from '@app/shared/models/auth.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  UserActions,
  UserActionTypes
} from '@app/core/store/actions/user.actions';

export interface State extends EntityState<AuthUser> {
  selectId: string | null;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<AuthUser> = createEntityAdapter<AuthUser>({
  selectId: (user: AuthUser) => user.id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectId: null,
  loaded: false,
  loading: false
});

export function reducer(state = initialState, action: UserActions): State {
  switch (action.type) {
    case UserActionTypes.GET_USERS_SUCCESS: {
      return adapter.setAll(action.payload.users, {
        ...state,
        selectId: state.selectId,
        loaded: true,
        loading: false
      });
    }

    case UserActionTypes.ADD_USER: {
      // return adapter.addOne(action.payload.user, state);
      // Nothing to change to the store at this point. An effect CREATE is also triggered and will subsequently fire a CREATE_DONE action.
      return {
        ...state,
        loading: true
      };
    }

    case UserActionTypes.ADD_USER_SUCCESS: {
      return adapter.addOne(action.payload.user, {
        ...state,
        loaded: true
      });
    }

    case UserActionTypes.GET_USER: {
      return {
        ...state,
        loading: true
      };
    }

    case UserActionTypes.GET_USER_SUCCESS: {
      return adapter.addOne(action.payload.user, {
        ...state,
        selectId: action.payload.user.id,
        loaded: true
      });
    }

    // case UserActionTypes.UPSERT: {
    //   return adapter.upsertOne(action.payload.user, state);
    // }

    // case UserActionTypes.UPSERT_MANY: {
    //   return adapter.upsertMany(action.payload.users, state);
    // }

    case UserActionTypes.UPDATE: {
      return adapter.updateOne(action.payload.user, state);
    }

    // case UserActionTypes.UPDATE_MANY: {
    //   return adapter.updateMany(action.payload.users, state);
    // }

    case UserActionTypes.DELETE: {
      return adapter.removeOne(action.payload.id, state);
    }

    // case UserActionTypes.DELETE_MANY: {
    //   return adapter.removeMany(action.payload.ids, state);
    // }

    // case UserActionTypes.DELETE_MANY: {
    //   return adapter.removeAll({ ...state, selectedUserId: null });
    // }

    default: {
      return state;
    }
  }
}
export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getSelectedId = (state: State) => state.selectId;
export const {
  // select the array of AuthUser ids
  selectIds: selectUserIds,
  // select the dictionary of AuthUser entities
  selectEntities: selectUserEntities,
  // select the array of users
  selectAll: selectAllUser,
  // select the total AuthUser count
  selectTotal: selectUserTotal
} = adapter.getSelectors();
