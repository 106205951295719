import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Fuel } from '@app/shared/models/fuel.model';

export enum FuelActionTypes {
  GET_FUELS = '[TripFuel] GET_FUELS',
  GET_FUELS_SUCCESS = '[TripFuel] GET_FUELS_SUCCESS',
  GET_FUELS_ERROR = '[TripFuel] GET_FUELS_ERROR',

  ADD_ONE = '[TripFuel] ADD_ONE',
  ADD_ONE_SUCCESS = '[TripFuel] ADD_ONE_SUCCESS',
  ADD_ONE_ERROR = '[TripFuel] ADD_ONE_ERROR',

  GET_ONE = '[TripFuel] GET_ONE',
  GET_ONE_SUCCESS = '[TripFuel] GET_ONE_SUCCESS',
  GET_ONE_ERROR = '[TripFuel] GET_ONE_ERROR',

  UPDATE = '[TripFuel] UPDATE',
  UPDATE_SUCCESS = '[TripFuel] UPDATE_SUCCESS',
  UPDATE_ERROR = '[TripFuel] UPDATE_ERROR',

  DELETE = '[TripFuel] DELETE',
  DELETE_SUCCESS = '[TripFuel] DELETE_SUCCESS',
  DELETE_ERROR = '[TripFuel] DELETE_ERROR',

  GET_FUELS_NAME_LIST = '[TripFuel] GET_FUELS_NAME_LIST',
  GET_FUELS_NAME_LIST_SUCCESS = '[TripFuel] GET_FUELS_NAME_LIST_SUCCESS',
  GET_FUELS_NAME_LIST_ERROR = '[TripFuel] GET_FUELS_NAME_LIST_ERROR'
}

// Actions for Getting  Fuels
export class GetFuels implements Action {
  readonly type = FuelActionTypes.GET_FUELS;
  constructor(public payload: { tripId: string }) {}
}
export class GetFuelsSuccess implements Action {
  readonly type = FuelActionTypes.GET_FUELS_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: Fuel[]) {}
  constructor(public payload: { fuels: Fuel[]; tripId: string }) {}
}
export class GetFuelsError implements Action {
  readonly type = FuelActionTypes.GET_FUELS_ERROR;
  constructor(public payload: any) {}
}
// Action for Creating Fuel

export class CreateFuel implements Action {
  readonly type = FuelActionTypes.ADD_ONE;

  constructor(public payload: { fuel: Fuel }) {}
}
export class CreateFuelSuccess implements Action {
  readonly type = FuelActionTypes.ADD_ONE_SUCCESS;

  constructor(public payload: { fuel: Fuel }) {}
}
export class CreateFuelError implements Action {
  readonly type = FuelActionTypes.ADD_ONE_ERROR;
  constructor(public payload: any) {}
}

export class GetFuel implements Action {
  readonly type = FuelActionTypes.GET_ONE;
}
export class GetFuelSuccess implements Action {
  readonly type = FuelActionTypes.GET_ONE_SUCCESS;

  constructor(public payload: { fuel: Fuel }) {}
}
export class GetFuelError implements Action {
  readonly type = FuelActionTypes.GET_ONE_ERROR;
  constructor(public payload: any) {}
}

export class UpdateFuel implements Action {
  readonly type = FuelActionTypes.UPDATE;

  constructor(public payload: Update<Fuel>) {}
}

export class UpdateFuelSuccess implements Action {
  readonly type = FuelActionTypes.UPDATE_SUCCESS;

  constructor(public payload: { fuel: Update<Fuel> }) {}
}
export class UpdateFuelError implements Action {
  readonly type = FuelActionTypes.UPDATE_ERROR;

  constructor(public payload: any) {}
}

export class DeleteFuel implements Action {
  readonly type = FuelActionTypes.DELETE;

  constructor(public payload: { fuel: Fuel }) {}
}
export class DeleteFuelSuccess implements Action {
  readonly type = FuelActionTypes.DELETE_SUCCESS;

  constructor(public payload: any) {}
}
export class DeleteFuelError implements Action {
  readonly type = FuelActionTypes.DELETE_ERROR;

  constructor(public payload: Fuel) {}
}

export class GetFuelsNameList implements Action {
  readonly type = FuelActionTypes.GET_FUELS_NAME_LIST;
}
export class GetFuelsNameListSuccess implements Action {
  readonly type = FuelActionTypes.GET_FUELS_NAME_LIST_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: Fuel[]) {}
  constructor(public payload: [ { name: string } ]) {}
}
export class GetFuelsNameListError implements Action {
  readonly type = FuelActionTypes.GET_FUELS_NAME_LIST_ERROR;
  constructor(public payload: any) {}
}

export type FuelActions =
  | GetFuel
  | GetFuelSuccess
  | GetFuelError

  | UpdateFuel
  | UpdateFuelSuccess
  | UpdateFuelError

  | GetFuels
  | GetFuelsSuccess
  | GetFuelsError

  | CreateFuel
  | CreateFuelSuccess
  | CreateFuelError

  | DeleteFuel
  | DeleteFuelSuccess
  | DeleteFuelError

  | GetFuelsNameList
  | GetFuelsNameListSuccess
  | GetFuelsNameListError;
