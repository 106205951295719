<div>
  <form
    class="form-login"
    method="post"
    [formGroup]="loginForm"
    (ngSubmit)="submit()"
  >
    <div class="form-group" [ngClass]="{'has-error':fullname.invalid && (fullname.dirty||fullname.touched)}">
      <div class="input-group input-group-lg">
        <span class="input-group-addon"
          ><span class="fas fa-user"></span
        ></span>
        <input
          type="text"
          class="form-control"
          name="fullname"
          formControlName="fullname"
          placeholder="Full Name"
          required
          autofocus
        />
      </div>
      <span *ngIf="fullname.invalid && (fullname.dirty||fullname.touched)" class="help-block">Please enter valid fullname!</span>
    </div>
    <div class="form-group" [ngClass]="{'has-error':email.invalid && (email.dirty||email.touched)}">
      <div class="input-group input-group-lg">
        <span class="input-group-addon"
          ><span class="fas fa-envelope"></span
        ></span>
        <input
          class="form-control"
          matInput
          name="email"
          formControlName="email"
          placeholder="Email address"
          type="email"
          required
          autofocus
          autocomplete="on"
        />
      </div>
      <span *ngIf="email.invalid && (email.dirty||email.touched)" class="help-block">Please enter valid email!</span>
    </div>
    <div class="form-group" [ngClass]="{'has-error':pswd.invalid && (pswd.dirty||pswd.touched)}">
      <div class="input-group input-group-lg">
        <span class="input-group-addon"
          ><span class="fas fa-lock"></span
        ></span>
        <input
          type="password"
          name="password"
          class="form-control"
          formControlName="pswd"
          placeholder="Password"
          required
          autocomplete="on"
        />
      </div>
      <span *ngIf="pswd.invalid && (pswd.dirty||pswd.touched)" class="help-block">Please enter valid password!</span>
    </div>
    <button type="submit" class="btn btn-lg btn-green btn-block">
      Create My Account!
    </button>
  </form>
  <p style="margin-top:20px;">
    By clicking Create My Account you agree to the
    <a
      href="https://ifueltax.com/default/index/terms-of-service"
      target="_blank"
      >Terms of Service</a
    >
    and
    <a href="https://ifueltax.com/default/index/privacy" target="_blank"
      >Privacy Policy</a
    >.
  </p>
  <div class="row">
    <div class="col-sm-6">
      <a [routerLink]="['../login']" class="btn btn-sm">
        LOGIN
      </a>
    </div>
  </div>
</div>
