import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromAuthStore from '@app/auth/store';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective implements OnInit, OnDestroy {
  @Input() appHasPermission: string;

  private user$: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<fromAuthStore.State>
  ) { }

  ngOnInit(): void {
    this.user$ = this.store
      .select(fromAuthStore.selectAuthPermissions)
      .subscribe((permissions) => {
        if (!this.hasPermission(permissions)) {
          this.viewContainer.clear();
        } else {
          // Clear fnx run to remove element if it's already embedded before
          this.viewContainer.clear();
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      });
  }

  hasPermission = (permissionList) => {
    if (!permissionList) {
      return false;
    }
    let permission = this.appHasPermission.trim();
    let i = permission.split(".");
    if (permissionList[i[0]]) {
      return permissionList[i[0]][i[1]];
    }
    return false;
  }

  ngOnDestroy(): void {
    this.user$?.unsubscribe();
  }
}
