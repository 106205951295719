import { Stop } from '@app/shared/models/stop.model';

export interface Company {
  id?: string,
  owner_id?: string,
  mc: string,
  dot: string,
  ifta: string,
  ifta_state_id: string,
  nm: string,
  ny: string,
  ny_report: string,
  or: string,
  ky: string,
  ma_report: string,
  name: string,
  dba: string,
  street1: string,
  street2: string,
  city: string,
  state: string,
  zip: string,
  website: string,
  phone: string,
  fax: string,
  email: string,
  type: string,
  created?: string,
  modified?: string
}

export function genMockCompany(): Company {
  return {
    city: '',
    dba: '',
    dot: '',
    email: '',
    fax: '',
    ifta: '',
    ifta_state_id: '',
    ky: '',
    ma_report: '',
    mc: '',
    name: '',
    nm: '',
    ny: '',
    ny_report: '',
    or: '',
    phone: '',
    state: '',
    street1: '',
    street2: '',
    type: '',
    website: '',
    zip: ''
  };
}
