import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/core/store/reducers';
import * as fromTruck from '@app/core/store/reducers/trucks.reducers';

// Trucks State
export const selectTruckState = createSelector(
  fromFeature.selectCoreState,
  (state: fromFeature.State) => state.trucks
);

export const getTrucksLoaded = createSelector(
  selectTruckState,
  fromTruck.getLoaded
);
export const getTrucksLoading = createSelector(
  selectTruckState,
  fromTruck.getLoading
);

export const selectTruckIds = createSelector(
  selectTruckState,
  fromTruck.selectTruckIds
);
export const selectTruckEntities = createSelector(
  selectTruckState,
  fromTruck.selectTruckEntities
);
export const selectAllTrucks = createSelector(
  selectTruckState,
  fromTruck.selectAllTruck
);
export const selectTruckTotal = createSelector(
  selectTruckState,
  fromTruck.selectTruckTotal
);
export const selectCurrentTruckId = createSelector(
  selectTruckState,
  fromTruck.getSelectedId
);

export const selectCurrentTruck = createSelector(
  selectTruckEntities,
  selectCurrentTruckId,
  (truckEntities, truckId) => truckEntities[truckId]
);
