<form class="form-inline add-company ng-pristine ng-valid">
  <div class="form-group">
    <div class="input-group floating-label">
      <input class="form-control ng-pristine ng-valid" [(ngModel)]="searchText" name="search" type="text" placeholder="ex. 123456">
      <label for="MC">MC Number</label>
      <span class="input-group-btn">
        <a class="btn btn-default ng-binding" (click)="search()">Search</a>
      </span>
    </div>
  </div>
</form>
<div *ngIf="(searchResult$|async ) as results">
  <table class="table table-bordered">
    <thead>
    </thead>
    <tbody>
      <tr>
        <td>{{results.name}}</td>
        <td *ngIf="action==='link'" class="col-md-1"><a class="btn btn-default" (click)="linkAgentCompany(results)">{{ 'Link' }}</a></td>
        <td *ngIf="action==='change' || action==='create'" class="col-md-1"><a class="btn btn-default" (click)="change(results)">Change</a></td>
      </tr>
    </tbody>
  </table>
</div>
