import { SettingsEffects } from '@app/settings/store/effects/settings.effects';
import { UsersEffects } from '@app/settings/store/effects/users.effects';
import { AgentEffects } from '@app/settings/store/effects/agent.effects';
import { BillingEffects } from '@app/settings/store/effects/billing.effects';

export const effects: any[] = [
    SettingsEffects,
    UsersEffects,
    AgentEffects,
    BillingEffects
];

export * from '@app/settings/store/effects/settings.effects';
export * from '@app/settings/store/effects/users.effects';
export * from '@app/settings/store/effects/agent.effects';
export * from '@app/settings/store/effects/billing.effects';
