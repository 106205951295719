import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  AgentActions,
  AgentActionTypes
} from '@app/settings/store/actions/agent.actions';
import { AuthUser } from '@app/shared/models';

export interface State {
  agent: AuthUser | null;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<AuthUser> = createEntityAdapter<AuthUser>({
  selectId: (setting: AuthUser) => setting.id
});

export const initialState: State = {
  agent: null,
  loaded: false,
  loading: false
};

export function reducer(state = initialState, action: AgentActions): State {
  switch (action.type) {
    case AgentActionTypes.GET_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loaded: true,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const {
  // select the array of Agent ids
  selectIds: selectAgentIds,
  // select the dictionary of Agent entities
  selectEntities: selectAgentEntities,
  // select the array of settings
  selectAll: selectAllAgent,
  // select the total Agent count
  selectTotal: selectAgentTotal
} = adapter.getSelectors();

export const getAgent = (state: State) => state.agent;
