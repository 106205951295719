import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Authenticate } from '@app/auth/models/authentication.model';

@Component({
  selector: 'app-forgot-form',
  templateUrl: './forgot-form.component.html',
  styles: []
})
export class ForgotFormComponent implements OnInit {
  @Input()
  error: string | null;

  @Input()
  set disabled(isDisabled: boolean) {
    if (isDisabled) {
      this.forgotForm.disable();
    } else {
      this.forgotForm.enable();
    }
  }

  @Output()
  submitted = new EventEmitter<Authenticate>();

  forgotForm = new FormGroup({
    email: new FormControl('', [Validators.required])
  });

  display = {
    action_type: ''
  };

  ngOnInit() {}

  get email() {
    return this.forgotForm.get('email') as FormControl;
  }

  submit() {
    if (this.forgotForm.invalid) {
      Object.keys(this.forgotForm.controls).forEach(field => {
        this.forgotForm.get(field).markAsTouched({ onlySelf: true });
      });
      return;
    }
    const value: Authenticate = this.forgotForm.value;

    if (this.forgotForm.valid) {
      this.submitted.emit(value);
    }
  }
}
