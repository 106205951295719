<div class="login-holder">
  <div class="login-box">
    <img
      src="/assets/img/logo.png"
      width="200px"
      class="center"
      alt="iFuelTax Truck, Routing and Reporting"
    />
    <hr />
    <ng-content select="[auth-form]"></ng-content>
  </div>
  <p class="text-center">
    <a href="http://ifueltax.com" target="_blank">About iFuelTax</a> |
    <a href="http://ifueltax.com/blog" target="_blank">Blog</a> |
    <a href="http://ifueltax.com/default/index/terms-of-service" target="_blank"
      >Terms of Service</a
    >
    |
    <a href="http://ifueltax.com/default/index/privacy" target="_blank"
      >Privacy Policy</a
    >
  </p>
</div>
