  <div id="filter-quarter-year">
    <app-btn-default type="left" (click)="prevQuarter()" [btnStyles]="{ 'padding': '6px' }"></app-btn-default>
    <div  class="floating-label">
      <select class="form-control ng-pristine ng-valid" [(ngModel)]="quarter" (change)="change()">
        <option value="0">Qtr</option>
        <option *ngFor="let quarter of quarters" [value]="quarter.value">{{quarter.name}}</option>
      </select>
      <label for="Qtr">Qtr</label>
    </div>
    
    <div  class="floating-label">
      <select class="form-control ng-pristine ng-valid" [(ngModel)]="year" (change)="change()">
        <option value="" class="ng-binding">Select year </option>
        <option *ngFor="let year of years" [value]="year">{{year}}</option>
      </select>
      <label for="Year">Year</label>
    </div>
    <app-btn-default type="right" (click)="nextQuarter()" [btnStyles]="{ 'padding': '6px' }"></app-btn-default>
  </div>
