import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Settings, AuthUser } from '@app/shared/models';

export enum SettingActionTypes {
  GET = '[Settings] GET',
  GET_SUCCESS = '[Settings] GET_SUCCESS',
  GET_ERROR = '[Settings] GET_ERROR',
  UPDATE_SETTINGS = '[Settings] UPDATE_SETTINGS',
  UPDATE_SETTINGS_SUCCESS = '[Settings] UPDATE_SETTINGS_SUCCESS',
  UPDATE_SETTINGS_ERROR = '[Settings] UPDATE_SETTINGS_ERROR',
}

// Actions for Getting  Settings
export class GetSettings implements Action {
  readonly type = SettingActionTypes.GET;
}

export class GetSettingsSuccess implements Action {
  readonly type = SettingActionTypes.GET_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: Setting[]) {}
  constructor(public payload: { settings: Settings[] }) {}
}

export class GetSettingsError implements Action {
  readonly type = SettingActionTypes.GET_ERROR;
  constructor(public payload: any) {}
}

export class UpdateSettings implements Action {
  readonly type = SettingActionTypes.UPDATE_SETTINGS;
  constructor(public payload: Update<Settings>) {}
}

export class UpdateSettingsSuccess implements Action {
  readonly type = SettingActionTypes.UPDATE_SETTINGS_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: Setting[]) {}
  constructor(public payload: { settings: Update<Settings> }) {}
}

export class UpdateSettingsError implements Action {
  readonly type = SettingActionTypes.UPDATE_SETTINGS_ERROR;
  constructor(public payload: any) {}
}


export type SettingActions =
  | GetSettings
  | GetSettingsSuccess
  | GetSettingsError
  | UpdateSettings
  | UpdateSettingsSuccess
  | UpdateSettingsError;

