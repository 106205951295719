import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Authenticate } from '@app/auth/models/authentication.model';
import { Registration } from '../../models/registration.model';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styles: []
})
export class RegisterFormComponent implements OnInit {
  @Input()
  error: string | null;

  @Input()
  set disabled(isDisabled: boolean) {
    if (isDisabled) {
      this.loginForm.disable();
    } else {
      this.loginForm.enable();
    }
  }

  @Output()
  submitted = new EventEmitter<Registration>();

  loginForm = new FormGroup({
    fullname: new FormControl('vilton zinet', [Validators.required]),
    email: new FormControl('abc@gmail.com', [Validators.required]),
    pswd: new FormControl('test1234', [Validators.required])
  });

  display = {
    action_type: ''
  };

  ngOnInit() { }

  get fullname() {
    return this.loginForm.get('fullname') as FormControl;
  }

  get email() {
    return this.loginForm.get('email') as FormControl;
  }

  get pswd() {
    return this.loginForm.get('pswd') as FormControl;
  }

  submit() {
    let value = this.loginForm.value;
    let fullNameArr = this.loginForm.value.fullname.split(" ");

    let newUser = {
      plan: "solo",
      trucks: 1,
      firstname: fullNameArr[0],
      lastname: fullNameArr[1],
      email: value.email,
      pswd: value.pswd
    }

    if (this.loginForm.valid) {
      this.submitted.emit(newUser);
    }
  }
}
