import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromUser from '@app/core/store/reducers/user.reducers';
import * as fromFeature from '@app/core/store/reducers';

// Users State
export const selectUserState = createSelector(
  fromFeature.selectCoreState,
  (state: fromFeature.State) => state.users
);
export const selectUsersState = createSelector(
  selectUserState,
  fromUser.selectUserEntities
);

export const getUserLoaded = createSelector(
  selectUserState,
  fromUser.getLoaded
);
export const getUserLoading = createSelector(
  selectUserState,
  fromUser.getLoading
);

export const selectUserIds = createSelector(
  selectUserState,
  fromUser.selectUserIds
);
export const selectUserEntities = createSelector(
  selectUserState,
  fromUser.selectUserEntities
);
export const selectAllUsers = createSelector(
  selectUserState,
  fromUser.selectAllUser
);
export const selectUserTotal = createSelector(
  selectUserState,
  fromUser.selectUserTotal
);
export const selectCurrentUserId = createSelector(
  selectUserState,
  fromUser.getSelectedId
);

export const selectCurrentUser = createSelector(
  selectUserEntities,
  selectCurrentUserId,
  (entities, userId) => entities[userId]
);
