import { Action } from '@ngrx/store';
import { Authenticate } from '@app/auth/models/authentication.model';
import { AuthUser, AuthPlan, AuthPermissions, AuthSettings, AuthAccount } from '@app/shared/models/auth.model';
import { Registration } from '@app/auth/models/registration.model';
import { Company, Truck } from '@app/shared';

export enum AuthActionTypes {
  LoginToken = '[Login Token] Login',
  Login = '[Login Page] Login',
  LoginSuccess = '[Auth API] Login Success',
  LoginFailure = '[Auth API] Login Failure',
  Logout = '[Auth] Confirm Logout',
  LogoutCancelled = '[Auth] Logout Cancelled',
  LogoutConfirmed = '[Auth] Logout Confirmed',
  LogoutComplete = '[Auth API] Logout Complete',
  LoginRequest = '[Login] LoginRequest',
  LoginRedirect = '[Auth API] Login Redirect',

  LoginPaymentRedirect = '[Auth UI] Login Payment Redirect',

  Register = '[Register Page] Register',
  RegisterFailure = '[Auth] Register Failure',
  RegisterSuccess = '[Auth] Register Success',

  Forgot = '[Forgot Page] Forgot',
  ForgotFailure = '[Auth] Forgot Failure',
  ForgotSuccess = '[Auth] Forgot Success',

  Reset = '[Forgot Page] Reset',
  ResetFailure = '[Auth] Reset Failure',
  ResetSuccess = '[Auth] Reset Success',

  GET_TOP_MENU = '[Auth] GET_TOP_MENU',
  GET_TOP_MENU_SUCCESS = '[Auth] GET_TOP_MENU_SUCCESS',
  GET_TOP_MENU_ERROR = '[Auth] GET_TOP_MENU_ERROR',
}

export class LoginToken implements Action {
  readonly type = AuthActionTypes.LoginToken;
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;

  constructor(public payload: Authenticate) { }
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;

  constructor(
    public payload: {
      user: AuthUser;
      plan: AuthPlan;
      permissions: AuthPermissions;
      settings: AuthSettings;
      company: Company;
      truck: Truck;
      account: AuthAccount;
    }
  ) { }
}

export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LoginFailure;

  constructor(public payload: any) { }
}

export class LoginRedirect implements Action {
  readonly type = AuthActionTypes.LoginRedirect;
}

export class LoginPaymentRedirect implements Action {
  readonly type = AuthActionTypes.LoginPaymentRedirect;

  constructor(public payload: { user: AuthUser }) { }
}

export class LoginRequest implements Action {
  readonly type = AuthActionTypes.LoginRequest;
}
export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

export class LogoutConfirmed implements Action {
  readonly type = AuthActionTypes.LogoutConfirmed;
}

export class LogoutCancelled implements Action {
  readonly type = AuthActionTypes.LogoutCancelled;
}

export class LogoutComplete implements Action {
  readonly type = AuthActionTypes.LogoutComplete;
}

export class Register implements Action {
  readonly type = AuthActionTypes.Register;

  constructor(public payload: Registration) { }
}

export class RegisterSuccess implements Action {
  readonly type = AuthActionTypes.RegisterSuccess;

  constructor(public payload: {}) { }
}

export class RegisterFailure implements Action {
  readonly type = AuthActionTypes.RegisterFailure;

  constructor(public payload: any) { }
}

export class Forgot implements Action {
  readonly type = AuthActionTypes.Forgot;

  constructor(public payload: Authenticate) { }
}

export class ForgotSuccess implements Action {
  readonly type = AuthActionTypes.ForgotSuccess;

  constructor(public payload: { user: AuthUser }) { }
}

export class ForgotFailure implements Action {
  readonly type = AuthActionTypes.ForgotFailure;

  constructor(public payload: any) { }
}

export class Reset implements Action {
  readonly type = AuthActionTypes.Reset;

  constructor(public payload: Authenticate) { }
}

export class ResetSuccess implements Action {
  readonly type = AuthActionTypes.ResetSuccess;
}

export class ResetFailure implements Action {
  readonly type = AuthActionTypes.ResetFailure;

  constructor(public payload: any) { }
}

export class GetTopMenu implements Action {
  readonly type = AuthActionTypes.GET_TOP_MENU;
}
export class GetTopMenuSuccess implements Action {
  readonly type = AuthActionTypes.GET_TOP_MENU_SUCCESS;
  constructor(public payload: any[]) { }
}
export class GetTopMenuError implements Action {
  readonly type = AuthActionTypes.GET_TOP_MENU_ERROR;
  constructor(public payload: any) { }
}

export type AuthActions =
  | LoginToken
  | Login
  | LoginSuccess
  | LoginFailure
  | Logout
  | LogoutCancelled
  | LogoutConfirmed
  | LogoutComplete
  | LoginRedirect
  | LoginRequest
  | Register
  | RegisterSuccess
  | RegisterFailure
  | Forgot
  | ForgotSuccess
  | ForgotFailure
  | Reset
  | ResetSuccess
  | ResetFailure

  | GetTopMenu
  | GetTopMenuSuccess
  | GetTopMenuError;
