import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of, map, mergeMap, switchMap, catchError, takeUntil, tap } from 'rxjs';

import * as fromAuthStore from '@app/auth/store';
import * as BillingActions from '@app/settings/store/actions/billing.actions';
import * as CoreActions from '@app/core/store/actions';
import { SettingService } from '@app/settings/services/settings.service';
import { ToastHelper } from '@app/core/services/toast.service';
import { AuthAccount, SubjectDestroyed, PaymentService } from '@app/shared';
import { AuthService } from '@app/auth/services/auth.service';

@Injectable()
export class BillingEffects extends SubjectDestroyed {


  getPlan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<BillingActions.GetPlan>(BillingActions.BillingActionTypes.GET_PLAN),
      mergeMap(_ => {
        return this._SettingService.getPlan().pipe(
          map(resp => {
            let accountData: AuthAccount;
            this.store
              .select(fromAuthStore.selectAuthAccount)
              .pipe(
                takeUntil(this.destroyed$),
                tap(account => {
                  accountData = account;
                })
              )
              .subscribe();
            return new BillingActions.GetPlanSuccess(
              {
                plan: {
                  name: resp.name,
                  base: resp.prices[0].base,
                  truck: parseInt(resp.prices[0].truck),
                  exp: accountData.exp,
                  trucks: parseInt(accountData.trucks),
                  status: parseInt(accountData.status),
                  users: resp.users,
                  id: resp.id,
                },
                prices: resp.prices
              }
            );
          }),
          catchError(error => of(new BillingActions.GetPlanError(error)))
        );
      })
    )
  });

  getPayment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<BillingActions.GetPayment>(BillingActions.BillingActionTypes.GET_PAYMENT),
      mergeMap(_ => {
        return this._PaymentService.getPayment().pipe(
          map(resp => {
            return new BillingActions.GetPaymentSuccess({
              payments: resp
            });
          }),
          catchError(error => of(new BillingActions.GetPaymentError(error)))
        );
      })
    )
  });

  getAdditionalFeatures$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<BillingActions.GetAdditionalFeatures>(BillingActions.BillingActionTypes.GET_ADDITIONAL_FEATURES),
      mergeMap(_ => {
        return this._SettingService.getBillFeatures().pipe(
          map(resp => {
            return new BillingActions.GetAdditionalFeaturesSuccess({
              additional_features: resp
            });
          }),
          catchError(error => of(new BillingActions.GetAdditionalFeaturesError(error)))
        );
      })
    )
  });

  changeAdditionalFeatures$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<BillingActions.ChangeAdditionalFeatures>(BillingActions.BillingActionTypes.CHANGE_ADDITIONAL_FEATURES),
      mergeMap((action: BillingActions.ChangeAdditionalFeatures) => {
        return this._SettingService.changeAdditionalFeatures(action.payload).pipe(
          map(resp => {
            return new BillingActions.ChangeAdditionalFeaturesSuccess(resp);
          }),
          catchError(error => of(new BillingActions.ChangeAdditionalFeaturesError(error)))
        );
      })
    )
  });

  callServer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<BillingActions.CallServer>(BillingActions.BillingActionTypes.CALL_SERVER),
      mergeMap((action: BillingActions.CallServer) => {
        return this._SettingService.callServer(action.payload).pipe(
          map(resp => {
            return new BillingActions.CallServerSuccess(resp);
          }),
          catchError(error => of(new BillingActions.CallServerError(error)))
        );
      })
    )
  });

  completePayment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<BillingActions.CompletePayment>(BillingActions.BillingActionTypes.COMPLETE_PAYMENT),
      mergeMap((action: BillingActions.CompletePayment) => {
        return this._SettingService.completePayment(action.payload).pipe(
          switchMap(resp => {
            this._AuthService.saveToken(resp.token);
            return [
              new fromAuthStore.LoginToken(),
              new BillingActions.CompletePaymentSuccess(resp),
            ]
          }
          ),
          catchError(error => of(new BillingActions.CompletePaymentError())
          )
        );
      })
    )
  });

  constructor(
    private store: Store<fromAuthStore.State>,
    private actions$: Actions,
    private _SettingService: SettingService,
    private _PaymentService: PaymentService,
    private _AuthService: AuthService,
    private notify: ToastHelper,
  ) {
    super();
  }
}
