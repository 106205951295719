import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, of, catchError } from 'rxjs';

import * as AgentActions from '@app/settings/store/actions/agent.actions';
import { SettingService } from '@app/settings/services/settings.service';
import { ToastHelper } from '@app/core/services/toast.service';

@Injectable()
export class AgentEffects {

  get$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<AgentActions.GetAgent>(AgentActions.AgentActionTypes.GET),
      switchMap((action: AgentActions.GetAgent) => {
        return this._SettingService.getAgent().pipe(
          map(resp => {
            return new AgentActions.GetAgentSuccess({
              agent: resp
            });
          }),
          catchError(error => of(new AgentActions.GetAgentError(error)))
        );
      })
    )
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<AgentActions.UpdateAgent>(AgentActions.AgentActionTypes.UPDATE_AGENT),
      switchMap((action: AgentActions.UpdateAgent) => {
        return this._SettingService.updateAgent(action.payload).pipe(
          map(resp => {
            this.notify.ok(resp.msg);
            return new AgentActions.UpdateAgentSuccess({
              agent: resp
            });
          }),
          catchError(error => {
            this.notify.error(error);
            return of(new AgentActions.UpdateAgentError(error))
          })
        );
      })
    )
  });

  constructor(
    private actions$: Actions,
    private _SettingService: SettingService,
    private notify: ToastHelper,
  ) { }
}
