<div class="floating-label">
  <input [ngStyle]="{
          width: styleWidth,
          border: options?.invalid ? '1px solid red' : ''
        }" class="input form-control" [attr.name]="name" [attr.placeholder]="options?.placeholder || 'Date'"
    [disabled]="disabled" #dateTime />
  <label *ngIf="label" for="{{label}}">{{ label }}</label>
  <div class="input-group-append">
    <button class="btn btn-link inline-add" type="button" #clearDateBtn (click)="clearDate()" aria-label="Clear">
      <span aria-hidden="true"> <i class="fas fa-times"></i> </span>
    </button>
    <!-- </div> -->
  </div>
</div>
