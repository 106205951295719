import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FeatureName, reducers, effects } from '@app/auth/store';

import { ComponentsModule } from '@app/auth/components';
import { AuthWrapperComponent } from '@app/auth/components/auth-wrapper/auth-wrapper.component';
import { LoginPageComponent } from '@app/auth/containers/login-page.component';
import { RegisterPageComponent } from '@app/auth/containers/register-page.component';
import { ForgotPageComponent } from '@app/auth/containers/forgot-page.component';
import { ResetPageComponent } from '@app/auth/containers/reset-page.component';
import { LogoutPromptComponent } from '@app/auth/components/logout-prompt/logout-prompt.component';
import { LoginPromptComponent } from '@app/auth/components/login-prompt/login-prompt.component';
import { UserHomeComponent } from '@app/auth/components/user-home/user-home.component';
import { RouterModule } from '@angular/router';
import { AuthGuardService } from './guards/auth-guard.service';

const AuthRouting = RouterModule.forChild([
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  {
    path: 'auth/login',
    component: LoginPageComponent
  },
  { path: 'auth/register', component: RegisterPageComponent },
  { path: 'auth/forgot', component: ForgotPageComponent },
  { path: 'auth/reset', component: ResetPageComponent },
  {
    path: 'home',
    component: UserHomeComponent,
    canActivate: [AuthGuardService]
  }
]);
@NgModule({
  imports: [
    CommonModule,
    AuthRouting,
    ReactiveFormsModule,
    StoreModule.forFeature(FeatureName, reducers),
    EffectsModule.forFeature(effects),
    ComponentsModule
  ],
  declarations: [
    AuthWrapperComponent,
    LoginPageComponent,
    LoginPageComponent,
    LoginPromptComponent,
    RegisterPageComponent,
    ForgotPageComponent,
    ResetPageComponent,
    LogoutPromptComponent,
    UserHomeComponent
  ],
  entryComponents: [LoginPromptComponent, LogoutPromptComponent]
})
export class AuthModule { }
