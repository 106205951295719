import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, tap } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromCoreStore from '@app/core/store';
import * as fromAuthStore from '@app/auth/store';

import { AuthService } from '@app/auth/services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public _AuthService: AuthService,
    private store: Store<fromCoreStore.State>
  ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // this.progress.start();
    request = request.clone({
      setHeaders: {
        Authorization: this._AuthService.getToken()
          ? `Bearer ${this._AuthService.getToken()}`
          : ''
      }
    });

    // Check Auth on Response
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          // this.progress.complete();
          if (event instanceof HttpResponse) {
            // console.log('[Auth] OK');
            // do stuff with response if you want
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            // this.progress.complete();
            if (err.status === 401) {
              console.log('[Auth] Failed');
              this._AuthService.collectFailedRequest(request);
              this._AuthService.destroyToken();
              this.store.dispatch(new fromAuthStore.LoginRequest());
            }
          }
        }
      )
    );
  }
}
