import { Action } from '@ngrx/store';
import {
  TruckActions,
  TruckActionTypes
} from '@app/core/store/actions/truck.actions';
import { Truck } from '@app/shared';
import { EntityState, createEntityAdapter, EntityAdapter } from '@ngrx/entity';

export interface State extends EntityState<Truck> {
  selectId: string | null;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<Truck> = createEntityAdapter<Truck>({
  selectId: (truck: Truck) => truck.id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectId: null,
  loaded: false,
  loading: false
});

export function reducer(state = initialState, action: TruckActions): State {
  switch (action.type) {
    case TruckActionTypes.GET_TRUCKS_SUCCESS: {
      return adapter.setAll(action.payload.trucks, {
        ...state,
        selectId: state.selectId,
        loaded: true,
        loading: false
      });
    }

    case TruckActionTypes.ADD_TRUCK: {
      // return adapter.addOne(action.payload.truck, state);
      // Nothing to change to the store at this point. An effect CREATE is also triggered and will subsequently fire a CREATE_DONE action.
      return {
        ...state,
        loading: true
      };
    }

    case TruckActionTypes.ADD_TRUCK_SUCCESS: {
      return adapter.addOne(action.payload.truck, {
        ...state,
        loading: false
      });
    }

    case TruckActionTypes.GET_TRUCK: {
      return {
        ...state,
        loading: true
      };
    }

    case TruckActionTypes.GET_TRUCK_SUCCESS: {
      return adapter.addOne(action.payload.truck, {
        ...state,
        selectId: action.payload.truck.id,
        loading: false
      });
    }

    case TruckActionTypes.UPDATE: {
      return adapter.updateOne(action.payload, state);
    }

    case TruckActionTypes.UPDATE_SUCCESS: {
      return adapter.updateOne(action.payload.truck, state);
    }

    case TruckActionTypes.DELETE_SUCCESS: {
      return adapter.removeOne(action.payload.truck.id, state);
    }

    case TruckActionTypes.SET_CURRENT_ID: {
      return {
        ...state,
        selectId: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getSelectedId = (state: State) => state.selectId;
export const {
  // select the array of Truck ids
  selectIds: selectTruckIds,
  // select the dictionary of Truck entities
  selectEntities: selectTruckEntities,
  // select the array of trucks
  selectAll: selectAllTruck,
  // select the array of notes
  selectAll: selectAllNote,
  // select the total Truck count
  selectTotal: selectTruckTotal
} = adapter.getSelectors();
