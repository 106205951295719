import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { AuthUser } from '@app/shared/models/auth.model';

export enum UserActionTypes {
  GET_USERS = '[User] GET_USERS',
  GET_USERS_SUCCESS = '[User] GET_USERS_SUCCESS',
  GET_USERS_ERROR = '[User] GET_USERS_ERROR',

  ADD_USER = '[User] ADD_USER',
  ADD_USER_SUCCESS = '[User] ADD_USER_SUCCESS',
  ADD_USER_ERROR = '[User] ADD_USER_ERROR',

  GET_USER = '[User] GET_USER',
  GET_USER_SUCCESS = '[User] GET_USER_SUCCESS',
  GET_USER_ERROR = '[User] GET_USER_ERROR',

  UPDATE = '[User] UPDATE',
  UPDATE_SUCCESS = '[User] UPDATE_SUCCESS',
  UPDATE_ERROR = '[User] UPDATE_ERROR',

  DELETE = '[User] DELETE',
  DELETE_SUCCESS = '[User] DELETE_SUCCESS',
  DELETE_ERROR = '[User] DELETE_ERROR',

  GET_USERS_BY_COMPANY = '[User] GET_USERS_BY_COMPANY',
  GET_USERS_BY_COMPANY_SUCCESS = '[User] GET_USERS_BY_COMPANY_SUCCESS',
  GET_USERS_BY_COMPANY_ERROR = '[User] GET_USERS_BY_COMPANY_ERROR'
}

// Actions for Getting  Users
export class GetUsers implements Action {
  readonly type = UserActionTypes.GET_USERS;
  constructor(public payload?: { account_id?: string }) {}
}

export class GetUsersSuccess implements Action {
  readonly type = UserActionTypes.GET_USERS_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: User[]) {}
  constructor(public payload: { users: AuthUser[] }) {}
}
export class GetUsersError implements Action {
  readonly type = UserActionTypes.GET_USERS_ERROR;
  constructor(public payload: any) {}
}
// Action for Creating User
export class CreateUser implements Action {
  readonly type = UserActionTypes.ADD_USER;

  constructor(public payload: { user: AuthUser }) {}
}
export class CreateUserSuccess implements Action {
  readonly type = UserActionTypes.ADD_USER_SUCCESS;

  constructor(public payload: { user: AuthUser }) {}
}
export class CreateUserError implements Action {
  readonly type = UserActionTypes.ADD_USER_ERROR;
  constructor(public payload: any) {}
}
export class GetUser implements Action {
  readonly type = UserActionTypes.GET_USER;
}
export class GetUserSuccess implements Action {
  readonly type = UserActionTypes.GET_USER_SUCCESS;

  constructor(public payload: { user: AuthUser }) {}
}

export class GetUserError implements Action {
  readonly type = UserActionTypes.GET_USER_ERROR;
  constructor(public payload: any) {}
}
export class UpdateUser implements Action {
  readonly type = UserActionTypes.UPDATE;

  constructor(public payload: { user: Update<AuthUser> }) {}
}

export class UpdateUserSuccess implements Action {
  readonly type = UserActionTypes.UPDATE_SUCCESS;

  constructor(public payload: { user: Update<AuthUser> }) {}
}

export class UpdateUserError implements Action {
  readonly type = UserActionTypes.UPDATE_ERROR;

  constructor(public payload: any) {}
}

export class DeleteUser implements Action {
  readonly type = UserActionTypes.DELETE;

  constructor(public payload: AuthUser) {}
}
export class DeleteUserSuccess implements Action {
  readonly type = UserActionTypes.DELETE_SUCCESS;

  constructor(public payload: any) {}
}
export class DeleteUserError implements Action {
  readonly type = UserActionTypes.DELETE_ERROR;

  constructor(public payload: AuthUser) {}
}

export class GetUsersByCompany implements Action {
  readonly type = UserActionTypes.GET_USERS_BY_COMPANY;

  constructor(public payload: { companyId: string }) {}
}
export class GetUsersByCompanySuccess implements Action {
  readonly type = UserActionTypes.GET_USERS_BY_COMPANY_SUCCESS;

  constructor(public payload: { drivers: AuthUser[] }) {}
}
export class GetUsersByCompanyError implements Action {
  readonly type = UserActionTypes.GET_USERS_BY_COMPANY_ERROR;

  constructor(public payload: any) {}
}

export type UserActions =
  | GetUser
  | GetUserSuccess
  | GetUserError

  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserError

  | GetUsers
  | GetUsersSuccess
  | GetUsersError

  | CreateUser
  | CreateUserSuccess
  | CreateUserError

  | DeleteUser
  | DeleteUserSuccess
  | DeleteUserError

  | GetUsersByCompany
  | GetUsersByCompanySuccess
  | GetUsersByCompanyError;
