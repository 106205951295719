<app-layout-header></app-layout-header>

<!-- <div class="prg-cont hidden-print" *ngIf="loadingView">
  <div class="prg-status">
    <span class="prg-spinner"></span>
    <span class="prg-text">Loading</span>
  </div>
</div> -->

<app-toasts></app-toasts>
<app-modal-root></app-modal-root>
<!-- Add your site or application content here -->
<div class="container" style="margin-top: 20px">
  <!-- <div class="alert-float hidden-print">
    <alert *ngFor="let alert of alerts" type="{{alert.type}}" close="alert.close()" role="alert">{{ alert.msg }}</alert>
  </div> -->
  <div class="main">
    <router-outlet></router-outlet>
  </div>
  <!-- <div ui-view="login" ng-controller="LoginCtrl"></div> -->
</div>
