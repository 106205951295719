import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromFeature from '@app/settings/store/reducers';
import * as fromSetting from '@app/settings/store/reducers/settings.reducers';

// Settings State
export const selectSettingState = createSelector(
  fromFeature.selectSettingsState,
  (state: fromFeature.State) => state.settings
);

export const getSettingsLoaded = createSelector(
  selectSettingState,
  fromSetting.getLoaded
);
export const getSettingsLoading = createSelector(
  selectSettingState,
  fromSetting.getLoading
);

export const selectSettingIds = createSelector(
  selectSettingState,
  fromSetting.selectSettingsIds
);
export const selectSettingEntities = createSelector(
  selectSettingState,
  fromSetting.selectSettingsEntities
);

export const selectSettings = createSelector(
  selectSettingState,
  fromSetting.selectAllSettings
);
