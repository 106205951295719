import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/services/api.service';
import { Truck } from '@app/shared/models/truck.model';

@Injectable({
  providedIn: 'root',
})
export class TruckService {
  path = `api/truck`;
  constructor(private apiService: ApiService) { }

  get() {
    return this.apiService.get(this.path);
  }

  getAll() {
    return this.apiService.get(this.path, {
      action_type: 'report',
      active: false
    });
  }

  getTrucksByCompany(companyId: string) {
    return this.apiService.get(this.path, {
      action_type: 'report',
      active: false,
      company_id: companyId
    });
  }

  create(truck) {
    return this.apiService.post(this.path, truck);
  }

  update(data) {
    data = this.cleanObj(data);
    return this.apiService.put(`${this.path}`, data);
  }

  delete(data) {
    return this.apiService.delete(`${this.path}/${data.id}`);
  }

  upload(data) {
    return this.apiService.postForm(`${this.path}`, data);
  }

  download(data) {
    data = this.cleanObj(data);
    return this.apiService.getFile(`${this.path}/download`, data);
  }

  cleanObj(data) {
    Object.keys(data)
      .filter(k => k !== '_id' && k.startsWith('_'))
      .map(k => {
        console.log('delete', k);
        delete data[k];
      });
    return data;
  }

  getBlobURL(payload) {
    const blob = new Blob([payload.body], {
      type: payload.headers.get('content-type')
    });
    // Remove Truck Ext
    const name = payload.name.replace(/\.[^/.]+$/, '');
    const ext = payload.uploadName.split('.').pop();
    const url = window.URL.createObjectURL(blob);
    return { _url: url, name: name + (ext ? `.${ext}` : '') };
  }

  changeUserTruck(query: {
    driver_id: string,
    id: string,
    truck_id: string,
    trucks: Truck[]
  }) {
    this.apiService.get('api/user-truck');
    return this.apiService.put('api/user-truck', {
      driver_id: query.driver_id,
      id: query.id,
      truck_id: query.truck_id
    });
  }
}
