import { Stop } from '@app/shared/models/stop.model';

export class Order {
  _id?: string;
  id?: string; // used in firestore
  billing: {
    contactId?: string;
  };
  number?: number;
  desc?: string;
  specialInstr?: string;
  weight?: number;
  offer?: number;
  ltl?: boolean;
  miles?: number;
  status?: string;
  createdAt?: string;
  truckTypes?: [string];
  stops?: [Stop];
  confirmationFile?: string;
  // specs: [{min:0, max:-10, units:"F"}];
  specs?: {};
  creator?: {
    _id: string;
    name: string;
  };
}

export function genMockOrder(): Order {
  return {
    billing: {}
  };
}
