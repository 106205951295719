import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-btn-default',
  templateUrl: './btn-default.component.html',
  styleUrls: ['./btn-default.component.sass']
})
export class BtnDefaultComponent implements OnInit {

  @Input()
  type: string;
  @Input()
  cssClasses: string;
  @Input()
  btnStyles;

  button;

  constructor() { }

  ngOnInit() {
    switch (this.type) {
      case 'delete':
        this.button = {
          popoverTitle: 'Delete',
          iconType: 'trash',
          text: ''
        };
        break;
      case 'file':
        this.button = {
          popoverTitle: 'View',
          iconType: 'file',
          text: ''
        };
        break;
      case 'e-mail':
        this.button = {
          popoverTitle: 'E-mail',
          iconType: 'envelope',
          text: ''
        };
        break;
      case 'file-download':
        this.button = {
          popoverTitle: 'Download',
          iconType: 'file-download',
          text: ''
        };
        break;
      case 'edit':
        this.button = {
          popoverTitle: 'Edit',
          iconType: 'edit',
          text: ''
        };
        break;
      case 'save':
        this.button = {
          popoverTitle: 'Save',
          iconType: 'save',
          text: ''
        };
        break;
      case 'pdf':
        this.button = {
          popoverTitle: '',
          iconType: 'download',
          text: 'PDF'
        };
        break;
      case 'folder':
        this.button = {
          popoverTitle: 'Trips',
          iconType: 'folder',
          text: ''
        };
        break;
      case 'right':
        this.button = {
          popoverTitle: '',
          iconType: 'chevron-right',
          text: ''
        };
        break;
      case 'left':
        this.button = {
          popoverTitle: '',
          iconType: 'chevron-left',
          text: ''
        };
        break;
      case 'done':
        this.button = {
          popoverTitle: 'Done!',
          iconType: 'check',
          text: ''
        };
        break;
      default:
        break;
    }
  }
}
