import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '@app/auth/store';
import { Register } from '@app/auth/store/actions/auth.actions';
import { Authenticate } from '@app/auth/models/authentication.model';
import { Registration } from '../models/registration.model';

@Component({
  selector: 'app-register-page',
  template: `
    <app-auth-wrapper>
      <div auth-form>
        <app-register-form (submitted)="onRegister($event)"></app-register-form>
      </div>
    </app-auth-wrapper>
  `
})
export class RegisterPageComponent implements OnInit {
  error$ = this.store.select(fromStore.selectLoginPageError);
  pending$ = this.store.select(fromStore.selectLoginPagePending);

  constructor(private store: Store<fromStore.State>) { }

  ngOnInit() { }

  onRegister(credentials: Registration) {
    this.store.dispatch(new Register(credentials));
  }
}
