import { Stop } from '@app/shared/models/stop.model';

export interface Truck {
  id?: string;
  company_id?: string;
  creator_id?: string;
  trailer_id?: string;
  unit?: string;
  year?: string;
  make?: string;
  model?: string;
  fuel_id: string;
  axles?: string;
  vin?: string;
  lic?: string;
  state_id?: string;
  start_date?: string;
  end_date?: string;
  w_loaded?: string;
  w_unloaded?: string;
  status?: string;
}

export function genMockTruck(): Truck {
  return {
    axles: '',
    creator_id: '',
    end_date: '',
    fuel_id: '1',
    id: '',
    lic: '',
    make: '',
    model: '',
    start_date: '',
    state_id: '0',
    status: '1',
    trailer_id: '',
    unit: '',
    vin: '',
    w_loaded: '',
    w_unloaded: '',
    year: ''
  };
}
