import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  UserActions,
  UserActionTypes
} from '@app/settings/store/actions/users.actions';
import { AuthUser } from '@app/shared/models';

export interface State extends EntityState<AuthUser> {
  selectId: string | null;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<AuthUser> = createEntityAdapter<AuthUser>({
  selectId: (user: AuthUser) => user.id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectId: null,
  loaded: false,
  loading: false
});

export function reducer(state = initialState, action: UserActions): State {
  switch (action.type) {
    case UserActionTypes.GET_SUCCESS: {
      return adapter.setAll(action.payload.users, {
        ...state,
        selectId: state.selectId,
        loaded: true,
        loading: false
      });
    }

    case UserActionTypes.UPDATE_USER: {
      return adapter.updateOne(action.payload, state);
    }

    case UserActionTypes.UPDATE_USER_SUCCESS: {
      return adapter.updateOne(action.payload.users, state);
    }

    // case UserActionTypes.UPDATE_USER_ERROR: {
    //   return adapter.removeOne(action.payload.user.id, state);
    // }

    case UserActionTypes.ADD_USER_SUCCESS: {
      return adapter.addOne(action.payload, state);
    }

    case UserActionTypes.DELETE_USER: {
      return adapter.removeOne(action.payload.user.id, state);
    }

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getSelectedId = (state: State) => state.selectId;
export const {
  // select the array of Users ids
  selectIds: selectUsersIds,
  // select the dictionary of Users entities
  selectEntities: selectUsersEntities,
  // select the array of users
  selectAll: selectAllUsers,
  // select the total Users count
  selectTotal: selectUsersTotal
} = adapter.getSelectors();
