import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/services/api.service';
import { AuthUser, Email } from '../models';
import { Update } from '@ngrx/entity';

@Injectable()
export class UserService {
  path = `api/user`;
  constructor(private apiService: ApiService) { }

  getUserList() {
    return this.apiService.get(`${this.path}`);
  }
  create(setting) {
    return this.apiService.post(this.path, setting);
  }

  changeUser(query: Update<AuthUser>) {
    const requestObj = query.changes;
    return this.apiService.put(`${this.path}`, requestObj);
  }
  addUser(query: AuthUser) {
    const requestObj = {
      driver: Number(query.driver),
      firstname: query.firstname,
      lastname: query.lastname
    }
    return this.apiService.post(`${this.path}`, requestObj);
  }

  forgot(query: {
    email: Email
  }) {
    return this.apiService.postForm('auth/forgot', query);
  }

  update(data) {
    data = this.cleanObj(data);
    return this.apiService.put(`${this.path}/${data.id}`, data);
  }

  deleteUser(query: AuthUser) {
    return this.apiService.delete(`${this.path}`, query);
  }

  cleanObj(data) {
    Object.keys(data)
      .filter(k => k !== '_id' && k.startsWith('_'))
      .map(k => {
        console.log('delete', k);
        delete data[k];
      });
    return data;
  }

  getBlobURL(payload) {
    const blob = new Blob([payload.body], {
      type: payload.headers.get('content-type')
    });
    // Remove Setting Ext
    const name = payload.name.replace(/\.[^/.]+$/, '');
    const ext = payload.uploadName.split('.').pop();
    const url = window.URL.createObjectURL(blob);
    return { _url: url, name: name + (ext ? `.${ext}` : '') };
  }
}
