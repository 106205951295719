import { Action } from '@ngrx/store';
import { Coords, Stop, Mile } from '@app/shared/models';

export enum GoogleMapActionTypes {
  ADD_STOP_ON_THE_MAP = '[Google-map] ADD_STOP_ON_THE_MAP',
  ADD_STOP_ON_THE_MAP_SUCCESS = '[Google-map] ADD_STOP_ON_THE_MAP_SUCCESS',
  ADD_STOP_ON_THE_MAP_ERROR = '[Google-map] ADD_STOP_ON_THE_MAP_ERROR',

  GENERATE_ROUTE = '[Google-map] GENERATE_ROUTE',
  GENERATE_ROUTE_SUCCESS = '[Google-map] GENERATE_ROUTE_SUCCESS',
  GENERATE_ROUTE_ERROR = '[Google-map] GENERATE_ROUTE_ERROR',

  GENERATE_MILE = '[Google-map] GENERATE_MILE',
  GENERATE_MILE_SUCCESS = '[Google-map] GENERATE_MILE_SUCCESS',
  GENERATE_MILE_ERROR = '[Google-map] GENERATE_MILE_ERROR',
}

export class AddStopOnTheMap implements Action {
  readonly type = GoogleMapActionTypes.ADD_STOP_ON_THE_MAP;
  constructor(public payload: Coords) {}
}
export class AddStopOnTheMapSuccess implements Action {
  readonly type = GoogleMapActionTypes.ADD_STOP_ON_THE_MAP_SUCCESS;
  constructor(public payload: { result: google.maps.GeocoderResult, status: google.maps.GeocoderStatus }) {}
}
export class AddStopOnTheMapError implements Action {
  readonly type = GoogleMapActionTypes.ADD_STOP_ON_THE_MAP_ERROR;
  constructor(public payload: any) {}
}

export class GenerateRoute implements Action {
  readonly type = GoogleMapActionTypes.GENERATE_ROUTE;
  constructor(public payload: {stops: Stop[], map: google.maps.Map}) {}
}
export class GenerateRouteSuccess implements Action {
  readonly type = GoogleMapActionTypes.GENERATE_ROUTE_SUCCESS;
  // constructor(public payload: google.maps.DirectionsResult) {}
}
export class GenerateRouteError implements Action {
  readonly type = GoogleMapActionTypes.GENERATE_ROUTE_ERROR;
  constructor(public payload: any) {}
}

export class GenerateMile implements Action {
  readonly type = GoogleMapActionTypes.GENERATE_MILE;
  constructor(public payload: any) {}
}
export class GenerateMileSuccess implements Action {
  readonly type = GoogleMapActionTypes.GENERATE_MILE_SUCCESS;
  constructor(public payload: Mile[]) {}
}
export class GenerateMileError implements Action {
  readonly type = GoogleMapActionTypes.GENERATE_MILE_ERROR;
  constructor(public payload: any) {}
}

export type GoogleMapActions =
  | AddStopOnTheMap
  | AddStopOnTheMapSuccess
  | AddStopOnTheMapError

  | GenerateRoute
  | GenerateRouteSuccess
  | GenerateRouteError

  | GenerateMile
  | GenerateMileSuccess
  | GenerateMileError;

