import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromFeature from '@app/settings/store/reducers';
import * as fromAgent from '@app/settings/store/reducers/agent.reducers';

// Agent State
export const selectAgentState = createSelector(
  fromFeature.selectSettingsState,
  (state: fromFeature.State) => state.agent
);

export const getAgentLoaded = createSelector(
  selectAgentState,
  fromAgent.getLoaded
);
export const getAgentLoading = createSelector(
  selectAgentState,
  fromAgent.getLoading
);

export const selectAgent = createSelector(
  selectAgentState,
  fromAgent.getAgent
);