import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromFeature from '@app/settings/store/reducers';
import * as fromUser from '@app/settings/store/reducers/users.reducers';

// Users State
export const selectUserState = createSelector(
  fromFeature.selectSettingsState,
  (state: fromFeature.State) => state.users
);

export const getUsersLoaded = createSelector(
  selectUserState,
  fromUser.getLoaded
);
export const getUsersLoading = createSelector(
  selectUserState,
  fromUser.getLoading
);

export const selectUserIds = createSelector(
  selectUserState,
  fromUser.selectUsersIds
);
export const selectUserEntities = createSelector(
  selectUserState,
  fromUser.selectUsersEntities
);

export const selectUsers = createSelector(
  selectUserState,
  fromUser.selectAllUsers
);
