import { createSelector } from '@ngrx/store';

import * as fromFeature from '@app/core/store/reducers';
import * as fromLayout from '@app/core/store/reducers/layout.reducer';

// State
export const selectLayoutState = createSelector(
  fromFeature.selectCoreState,
  (state: fromFeature.State) => state.layouts
);

export const getModal = createSelector(
  selectLayoutState,
  fromLayout.getModal
);
