import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { AuthUser, Email } from '@app/shared/models';

export enum UserActionTypes {
  GET = '[Users] GET',
  GET_SUCCESS = '[Users] GET_SUCCESS',
  GET_ERROR = '[Users] GET_ERROR',
  UPDATE_USER = '[Users] UPDATE_USER',
  UPDATE_USER_SUCCESS = '[Users] UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR = '[Users] UPDATE_USER_ERROR',
  ADD_USER = '[Users] ADD_USER',
  ADD_USER_SUCCESS = '[Users] ADD_USER_SUCCESS',
  ADD_USER_ERROR = '[Users] ADD_USER_ERROR',
  DELETE_USER = '[Users] DELETE_USER',
  DELETE_USER_SUCCESS = '[Users] DELETE_USER_SUCCESS',
  DELETE_USER_ERROR = '[Users] DELETE_USER_ERROR',
  FORGOT_USER = '[Users] FORGOT_USER',
  FORGOT_USER_SUCCESS = '[Users] FORGOT_USER_SUCCESS',
  FORGOT_USER_ERROR = '[Users] FORGOT_USER_ERROR',
}

// Actions for Getting  Users
export class GetUsers implements Action {
  readonly type = UserActionTypes.GET;
}

export class GetUsersSuccess implements Action {
  readonly type = UserActionTypes.GET_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: User[]) {}
  constructor(public payload: { users: AuthUser[] }) {}
}

export class GetUsersError implements Action {
  readonly type = UserActionTypes.GET_ERROR;
  constructor(public payload: any) {}
}

export class UpdateUsers implements Action {
  readonly type = UserActionTypes.UPDATE_USER;
  constructor(public payload: Update<AuthUser>) {}
}
export class UpdateUsersSuccess implements Action {
  readonly type = UserActionTypes.UPDATE_USER_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: User[]) {}
  constructor(public payload: { users: Update<AuthUser> }) {}
}
export class UpdateUsersError implements Action {
  readonly type = UserActionTypes.UPDATE_USER_ERROR;
  constructor(public payload: any) {}
}

export class AddUser implements Action {
  readonly type = UserActionTypes.ADD_USER;
  constructor(public payload: AuthUser) {}
}
export class AddUserSuccess implements Action {
  readonly type = UserActionTypes.ADD_USER_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: User[]) {}
  constructor(public payload: AuthUser ) {}
}
export class AddUserError implements Action {
  readonly type = UserActionTypes.ADD_USER_ERROR;
  constructor(public payload: any) {}
}


export class DeleteUser implements Action {
  readonly type = UserActionTypes.DELETE_USER;

  constructor(public payload: { user: AuthUser }) {}
}
export class DeleteUserSuccess implements Action {
  readonly type = UserActionTypes.DELETE_USER_SUCCESS;

  constructor(public payload: any) {}
}
export class DeleteUserError implements Action {
  readonly type = UserActionTypes.DELETE_USER_ERROR;

  constructor(public payload: any) {}
}

export class ForgotUser implements Action {
  readonly type = UserActionTypes.FORGOT_USER;

  constructor(public payload: { email: Email }) {}
}
export class ForgotUserSuccess implements Action {
  readonly type = UserActionTypes.FORGOT_USER_SUCCESS;

  constructor(public payload: any) {}
}
export class ForgotUserError implements Action {
  readonly type = UserActionTypes.FORGOT_USER_ERROR;

  constructor(public payload: any) {}
}


export type UserActions =
  | GetUsers
  | GetUsersSuccess
  | GetUsersError
  | UpdateUsers
  | UpdateUsersSuccess
  | UpdateUsersError
  | AddUser
  | AddUserSuccess
  | AddUserError
  | DeleteUser
  | DeleteUserSuccess
  | DeleteUserError
  | ForgotUser
  | ForgotUserSuccess
  | ForgotUserError;

