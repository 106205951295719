<ng-container *ngIf="(currentUser$ | async) as user">
<div class="navbar navbar-inverse navbar-fixed-top ng-cloak hidden-print" role="navigation">
  <div class="container">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand" routerLink="/dashboard" routerLinkActive="router-link-active" ><img src="/assets/img/logo_light.png" width="120px" alt="iFuelTax"></a>
    </div>
    <div class="navbar-collapse collapse">
      <ul class="nav navbar-nav">
        <li *ngFor="let item of menu.collection" [ngClass]="{'active':isActive('/item.url')}"><a href="#/{{item.url}}" data-target=".navbar-collapse">{{item.label}}</a></li>
      </ul>
        <ul class="nav navbar-nav navbar-right">
          <li class="dropdown" ngbDropdown>
            <a class="dropdown-toggle" data-toggle="dropdown" ngbDropdownToggle>{{user.firstname}} <span class="fas fa-user"></span> <i class="fas fa-angle-down"></i></a>
            <ul class="dropdown-menu" ngbDropdownMenu>
              <li><a>Role: {{getRole(user.level)}} <span *ngIf="user.driver ==='1'">Driver</span></a></li>
              <li><a>{{user.email}}</a></li>
              <li class="divider"></li>
              <li><a (click)="logout()">{{'Logout'}}</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>
<div class="prg-cont hidden-print" *ngIf="loading !== 0">
  <div class="prg-status">
    <span class="prg-spinner"></span>
    <span class="prg-text">Loading</span>
  </div>
</div>
