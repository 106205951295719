import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ViewChild,
  forwardRef,
  Renderer2
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputComponent),
  multi: true
};

@Component({
  selector: 'app-input',
  providers: [INPUT_VALUE_ACCESSOR],
  template: `
  <div class="form-group row">
  <label class="col-md-auto col-form-label">{{label}}</label>
  <div class="col">
    <input #input type="{{type || 'text'}}" (input)="change($event)" class="form-control" placeholder="{{placeholder}}">
  </div>
</div>
  `
})
export class InputComponent implements ControlValueAccessor {
  @ViewChild('input', { static: true })
  input;

  @Input()
  label: string;

  @Input()
  type: string;
  @Input()
  placeholder: string;
  @Output()
  update = new EventEmitter();

  onChange;
  onTouched;

  constructor(private renderer: Renderer2) {}

  writeValue(value: any): void {
    const input = this.input.nativeElement;
    this.renderer.setProperty(input, 'value', value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  change($event) {
    this.onChange($event.target.value);
    this.onTouched($event.target.value);
  }
}
