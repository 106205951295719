import { Injectable, ErrorHandler } from '@angular/core'

import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

import { environment } from '../../../environments/environment'
declare var require: any
const appVersion = require('../../../../package.json').version


@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  constructor() {
    Sentry.init({
      release: appVersion,
      dsn: "https://d9cc5ecc1e474b2fa122bb813fd99109@o1064343.ingest.sentry.io/6055151",
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    })
  }

  handleError(error) {
    Sentry.captureException(error.originalError || error)
  }
}

export function getErrorHandler(): ErrorHandler {
  if (environment.production) {
    return new SentryErrorHandler()
  }
  return new ErrorHandler()
}
