import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  path = `api/user`;
  constructor(private apiService: ApiService) { }

  get() {
    return this.apiService.get(this.path);
  }

  getAll(account_id: string) {
    return this.apiService.get(this.path, {
      action_type: 'all',
      account_id
    });
  }

  getUsersByCompanyId(companyId: string) {
    return this.apiService.get(this.path, {
      action_type: 'report',
      company_id: companyId
    });
  }

  create(user) {
    return this.apiService.post(this.path, user);
  }

  update(data) {
    data = this.cleanObj(data);
    return this.apiService.put(`${this.path}`, data);
  }

  delete(data) {
    return this.apiService.delete(`${this.path}/${data.id}`);
  }

  upload(data) {
    return this.apiService.postForm(`${this.path}`, data);
  }

  download(data) {
    data = this.cleanObj(data);
    return this.apiService.getFile(`${this.path}/download`, data);
  }

  cleanObj(data) {
    Object.keys(data)
      .filter(k => k !== '_id' && k.startsWith('_'))
      .map(k => {
        console.log('delete', k);
        delete data[k];
      });
    return data;
  }

  getBlobURL(payload) {
    const blob = new Blob([payload.body], {
      type: payload.headers.get('content-type')
    });
    // Remove User Ext
    const name = payload.name.replace(/\.[^/.]+$/, '');
    const ext = payload.uploadName.split('.').pop();
    const url = window.URL.createObjectURL(blob);
    return { _url: url, name: name + (ext ? `.${ext}` : '') };
  }
}
