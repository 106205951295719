import { MetaReducer, createFeatureSelector } from '@ngrx/store';

import * as fromRouterReducer from '@app/core/store/reducers/router.reducer';
import * as fromLayout from '@app/core/store/reducers/layout.reducer';
import * as fromToast from '@app/core/store/reducers/toast.reducer';
import * as fromUser from '@app/core/store/reducers/user.reducers';
import * as fromCompanies from '@app/core/store/reducers/companies.reducers';
import * as fromTrucks from '@app/core/store/reducers/trucks.reducers';
import * as fromTrips from '@app/core/store/reducers/trip.reducers';

import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import * as fromRouter from '@ngrx/router-store';

import { environment } from 'environments/environment';
/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */

export interface State {
  routerReducer: fromRouter.RouterReducerState<
    fromRouterReducer.RouterStateUrl
  >;
  layouts: fromLayout.State;
  toasts: fromToast.ToastsState;
  users: fromUser.State;
  companies: fromCompanies.State;
  trucks: fromTrucks.State;
  trips: fromTrips.State;
}

export const reducers = {
  routerReducer: fromRouter.routerReducer,
  layouts: fromLayout.reducer,
  toasts: fromToast.reducer,
  users: fromUser.reducer,
  companies: fromCompanies.reducer,
  trucks: fromTrucks.reducer,
  trips: fromTrips.reducer
};

export const metaReducers: MetaReducer<any>[] = !environment.production
  ? []
  : [];

export class CustomSerializer
  implements
    fromRouter.RouterStateSerializer<fromRouterReducer.RouterStateUrl> {
  serialize(
    routerState: RouterStateSnapshot
  ): fromRouterReducer.RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const { params } = state;

    return { url, queryParams, params };
  }
}

export const selectCoreState = createFeatureSelector<State>('core');
