import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of, timer, map, mergeMap, switchMap } from 'rxjs';

import * as toastsActions from '@app/core/store/actions/toast.actions';
import { ToastHelper } from '@app/core/services/toast.service';

@Injectable()
export class ToastEffects {

  ok$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<toastsActions.ToastsOk>(toastsActions.ToastActionTypes.OK),
      map(action => {
        return new toastsActions.ToastsAdd(
          this.notify.build('success', action.payload)
        );
      })
    )
  });


  error$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<toastsActions.ToastsError>(toastsActions.ToastActionTypes.ERROR),
      map(action => {
        return new toastsActions.ToastsAdd(
          this.notify.build('danger', action.payload)
        );
      })
    )
  });


  dismiss$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<toastsActions.ToastsAdd>(toastsActions.ToastActionTypes.ADD),
      mergeMap(action => {
        const toast = action.payload;
        // console.log('[Dismiss] Call', toast);
        return timer(toast.seconds * 1000).pipe(
          switchMap(() => {
            // console.log('[Dismiss] Resolve', toast);
            return of(new toastsActions.ToastsDismiss(toast));
          })
        );
      })
    )
  });

  constructor(private actions$: Actions, private notify: ToastHelper) { }
}
