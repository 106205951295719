import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of, Observable, map, switchMap, mergeMap, catchError } from 'rxjs';

import * as CompanyActions from '@app/core/store/actions/company.actions';
import * as LayoutActions from '@app/core/store/actions/layout.actions';
import * as AuthActions from '@app/auth/store/actions/auth.actions';
import { CompanyService } from '@app/core/services/company.service';
import { ToastHelper } from '@app/core/services/toast.service';

@Injectable()
export class CompanyEffects {
  getCompanies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<CompanyActions.GetCompanies>(CompanyActions.CompanyActionTypes.GET_COMPANIES),
      switchMap((action: CompanyActions.GetCompanies) => {
        return this._CompanyService.getAll().pipe(
          map(resp => {
            return new CompanyActions.GetCompaniesSuccess({
              companies: resp || null
            });
          }),
          catchError(error => of(new CompanyActions.GetCompaniesError(error)))
        );
      })
    )
  });

  createCompany$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<CompanyActions.CreateCompany>(CompanyActions.CompanyActionTypes.ADD_COMPANY),
      mergeMap(action => {
        return this._CompanyService.create(action.payload.company).pipe(
          switchMap(resp => {
            this.notify.ok('Company Added Successful!');
            return [
              new LayoutActions.CloseModal(),
              new CompanyActions.CreateCompanySuccess({
                company: resp
              })
            ];
          }),
          catchError(error => {
            this.notify.error(error);
            return of(new CompanyActions.CreateCompanyError(error));
          })
        );
      })
    )
  });

  UpdateCompany$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<CompanyActions.UpdateCompany>(CompanyActions.CompanyActionTypes.UPDATE),
      mergeMap(action => {
        return this._CompanyService.update(action.payload.company).pipe(
          switchMap(resp => {
            this.notify.ok('Company Added Successful!');
            return [
              new LayoutActions.CloseModal(),
              new CompanyActions.UpdateCompanySuccess({
                company: resp
              })
            ];
          }),
          catchError(error => {
            this.notify.error(error);
            return of(new CompanyActions.UpdateCompanyError(error));
          })
        );
      })
    )
  });

  AgentCompanyLink$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<CompanyActions.AgentCompanyLink>(CompanyActions.CompanyActionTypes.AGENT_COMPANY_LINK),
      switchMap((action: CompanyActions.AgentCompanyLink) => {
        return this._CompanyService.AgentCompanyLink(action.payload).pipe(
          switchMap(resp => {
            this.notify.ok(resp.msg);
            return [
              new CompanyActions.GetCompanies(),
              new CompanyActions.SearchCompanyReset(),
              new CompanyActions.AgentCompanyLinkSuccess(resp)
            ]
          }),
          catchError(error => of(new CompanyActions.AgentCompanyLinkError(error)))
        );
      })
    )
  });

  UserCompanyChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<CompanyActions.UserCompanyChange>(CompanyActions.CompanyActionTypes.USER_COMPANY_CHANGE),
      switchMap((action: CompanyActions.UserCompanyChange) => {
        return this._CompanyService.changeCompany(action.payload).pipe(
          switchMap(resp => {
            this.notify.ok("Your company has been changed!");
            return [
              new CompanyActions.SearchCompanyReset(),
              new CompanyActions.UserCompanyChangeSuccess(resp),
              new AuthActions.LoginToken(),
              new CompanyActions.GetCompanies()
            ]
          }),
          catchError(error => {
            this.notify.error(error);
            return of(new CompanyActions.UserCompanyChangeError(error))
          })
        );
      })
    )
  });

  searchCompanies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<CompanyActions.SearchCompany>(CompanyActions.CompanyActionTypes.SEARCH_COMPANY),
      switchMap((action: CompanyActions.SearchCompany) => {
        return this._CompanyService.searchCompany(action.payload).pipe(
          map(resp => {
            if (resp?.length) {
              return new CompanyActions.SearchCompanySuccess(resp[0]);
            } else {
              return new LayoutActions.OpenModal({
                modal: 'COMPANY_FORM',
                props: { company: { mc: action.payload } }
              });
            }
          }),
          catchError(error => of(new CompanyActions.SearchCompanyError(error)))
        );
      })
    )
  });





  constructor(
    private actions$: Actions,
    private _CompanyService: CompanyService,
    private notify: ToastHelper
  ) { }
}
