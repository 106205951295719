import * as fromLayouts from '@app/core/store/actions/layout.actions';
import { Modal } from '@app/core/models/modal.model';
export interface State {
  modal: Modal;
}

export const initialState: State = {
  modal: null
};

export function reducer(
  state = initialState,
  action: fromLayouts.LayoutActions
): State {
  switch (action.type) {
    case fromLayouts.LayoutActionTypes.OPEN_MODAL: {
      const props = action.payload.props || {};
      return {
        ...state,
        modal: {
          type: action.payload.modal,
          props: props
        }
      };
    }
    case fromLayouts.LayoutActionTypes.CLOSE_MODAL: {
      return { ...state, modal: null };
    }
  }
  return state;
}

export const getModal = (state: State) => state.modal;
