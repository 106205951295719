import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/services/api.service';
import { Settings, Company, AuthUser, Email, AdditionalFeatures } from '@app/shared';
import { Update } from '@ngrx/entity';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  path = `api/`;
  pathObj = {
    settings: this.path + 'settings',
    company: this.path + 'account-company',
    agent: this.path + 'agent',
    payment: this.path + 'payment',
    plan: this.path + 'plan',
    billFeatures: this.path + 'bill-features',
    paymentStripe: this.path + 'payment'
  }
  constructor(private apiService: ApiService) { }

  getSettings() {
    return this.apiService.get(`${this.pathObj.settings}`);
  }
  updateSettings(query: Update<Settings>) {
    const requestQuery = query.changes;
    return this.apiService.post(`${this.pathObj.settings}`, requestQuery);
  }
  forgot(query: {
    email: string
  }) {
    return this.apiService.postForm(`${this.path}forgot`, query);
  }

  getCompany() {
    return this.apiService.get(`${this.pathObj.company}`);
  }
  searchCompany(query: {
    action_type: string
    mc: string
  }) {
    return this.apiService.get(`${this.path}company`, query);
  }
  updateCompany(query: Company) {
    return this.apiService.post(`${this.pathObj.company}`, query);
  }

  getAgent() {
    return this.apiService.get(`${this.pathObj.agent}`);
  }
  updateAgent(query: {
    email: Email
  }) {
    return this.apiService.post(`${this.pathObj.agent}`, query);
  }

  getPlan() {
    return this.apiService.get(`${this.pathObj.plan}`);
  }
  getBillFeatures() {
    return this.apiService.get(`${this.pathObj.billFeatures}`);
  }
  changeAdditionalFeatures(query: AdditionalFeatures) {
    return this.apiService.post(`${this.pathObj.billFeatures}`, query);
  }

  callServer(data: {
    action_type: string,
    token: any,
    payment: any
  }) {
    return this.apiService.post(`${this.pathObj.paymentStripe}?action_type=session`, data);
  }

  completePayment(data) {
    return this.apiService.post(`${this.pathObj.paymentStripe}?action_type=complete`, data);
  }

  get(value) {
    return this.apiService.get(`${this.path}${value}`);
  }
  create(setting) {
    return this.apiService.post(this.path, setting);
  }

  change(query: any, path) {
    return this.apiService.post(`${this.path}${path}`, query);
  }

  update(data) {
    data = this.cleanObj(data);
    return this.apiService.put(`${this.path}/${data.id}`, data);
  }

  upload(data) {
    return this.apiService.postForm(`${this.path}`, data);
  }

  download(data) {
    data = this.cleanObj(data);
    return this.apiService.getFile(`${this.path}/download`, data);
  }

  cleanObj(data) {
    Object.keys(data)
      .filter(k => k !== '_id' && k.startsWith('_'))
      .map(k => {
        console.log('delete', k);
        delete data[k];
      });
    return data;
  }

  getBlobURL(payload) {
    const blob = new Blob([payload.body], {
      type: payload.headers.get('content-type')
    });
    // Remove Setting Ext
    const name = payload.name.replace(/\.[^/.]+$/, '');
    const ext = payload.uploadName.split('.').pop();
    const url = window.URL.createObjectURL(blob);
    return { _url: url, name: name + (ext ? `.${ext}` : '') };
  }
}
