<table class="table table-bordered table-condensed" *ngIf="company">
	<tr>
		<td><strong>Name:</strong> {{company.name}} <app-btn-default type="edit" (click)="onEditCompany()"></app-btn-default><br>
			<strong>Address:</strong> {{ company.street1 }} {{ company.street2 }}<br>
			{{ company.city }}, {{ company.zip }} <br>
			<strong>Phone:</strong> {{company.phone}} | <strong>Fax:</strong> {{company.fax}} <br>
			<strong>Email:</strong> {{company.email}}
		</td>
		<td>
			<strong>DOT:</strong> {{ company.dot }}<br>
			<strong>MC:</strong> {{ company.mc }}<br>
			<strong>IFTA:</strong> {{company.ifta}}<br>
			<strong>NM:</strong> {{company.nm}}<br>
		</td>
		<td>
			<strong>NY:</strong> {{company.ny}}<br>
			<strong>OR:</strong> {{company.or}}<br>
			<strong>KY:</strong> {{company.ky}}<br>
		</td>
	</tr>
</table>