import { Action } from '@ngrx/store';

import { AuthUser, Email } from '@app/shared/models';

export enum AgentActionTypes {
  GET = '[Agent] GET',
  GET_SUCCESS = '[Agent] GET_SUCCESS',
  GET_ERROR = '[Agent] GET_ERROR',
  UPDATE_AGENT = '[Agent] UPDATE_AGENT',
  UPDATE_AGENT_SUCCESS = '[Agent] UPDATE_AGENT_SUCCESS',
  UPDATE_AGENT_ERROR = '[Agent] UPDATE_AGENT_ERROR'
}

// Actions for Getting  Agent
export class GetAgent implements Action {
  readonly type = AgentActionTypes.GET;
}

export class GetAgentSuccess implements Action {
  readonly type = AgentActionTypes.GET_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: Agent[]) {}
  constructor(public payload: { agent: AuthUser }) { }
}

export class GetAgentError implements Action {
  readonly type = AgentActionTypes.GET_ERROR;
  constructor(public payload: any) { }
}

export class UpdateAgent implements Action {
  readonly type = AgentActionTypes.UPDATE_AGENT;
  constructor(public payload: {
    email: Email
  }) { }
}

export class UpdateAgentSuccess implements Action {
  readonly type = AgentActionTypes.UPDATE_AGENT_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: Agent[]) {}
  constructor(public payload: any) { }
}

export class UpdateAgentError implements Action {
  readonly type = AgentActionTypes.UPDATE_AGENT_ERROR;
  constructor(public payload: any) { }
}



export type AgentActions =
  | GetAgent
  | GetAgentSuccess
  | GetAgentError
  | UpdateAgent
  | UpdateAgentSuccess
  | UpdateAgentError;

