import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Stop } from '@app/shared/models/stop.model';

export enum StopActionTypes {
  GET_STOPS = '[TripStop] GET_STOPS',
  GET_STOPS_SUCCESS = '[TripStop] GET_STOPS_SUCCESS',
  GET_STOPS_ERROR = '[TripStop] GET_STOPS_ERROR',

  ADD_ONE = '[TripStop] ADD_ONE',
  ADD_ONE_SUCCESS = '[TripStop] ADD_ONE_SUCCESS',
  ADD_ONE_ERROR = '[TripStop] ADD_ONE_ERROR',

  GET_ONE = '[TripStop] GET_ONE',
  GET_ONE_SUCCESS = '[TripStop] GET_ONE_SUCCESS',
  GET_ONE_ERROR = '[TripStop] GET_ONE_ERROR',

  UPDATE = '[TripStop] UPDATE',
  UPDATE_SUCCESS = '[TripStop] UPDATE_SUCCESS',
  UPDATE_ERROR = '[TripStop] UPDATE_ERROR',

  DELETE = '[TripStop] DELETE',
  DELETE_SUCCESS = '[TripStop] DELETE_SUCCESS',
  DELETE_ERROR = '[TripStop] DELETE_ERROR',

  UPDATE_ALL = '[TripStop] UPDATE_ALL'
}

// Actions for Getting  Stops
export class GetStops implements Action {
  readonly type = StopActionTypes.GET_STOPS;
  constructor(public payload: { tripId: string }) {}
}

export class GetStopsSuccess implements Action {
  readonly type = StopActionTypes.GET_STOPS_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: Stop[]) {}
  constructor(public payload: { stops: Stop[], tripId: string }) {}
}
export class GetStopsError implements Action {
  readonly type = StopActionTypes.GET_STOPS_ERROR;
  constructor(public payload: any) {}
}

// Action for Creating Stop
export class CreateStop implements Action {
  readonly type = StopActionTypes.ADD_ONE;

  constructor(public payload: { stop: Stop }) {}
}
export class CreateStopSuccess implements Action {
  readonly type = StopActionTypes.ADD_ONE_SUCCESS;

  constructor(public payload: { stop: Stop }) {}
}
export class CreateStopError implements Action {
  readonly type = StopActionTypes.ADD_ONE_ERROR;
  constructor(public payload: any) {}
}

export class GetStop implements Action {
  readonly type = StopActionTypes.GET_ONE;
}
export class GetStopSuccess implements Action {
  readonly type = StopActionTypes.GET_ONE_SUCCESS;

  constructor(public payload: { stop: Stop }) {}
}
export class GetStopError implements Action {
  readonly type = StopActionTypes.GET_ONE_ERROR;
  constructor(public payload: any) {}
}

export class UpdateStop implements Action {
  readonly type = StopActionTypes.UPDATE;

  constructor(public payload: Update<Stop>) {}
}
export class UpdateStopSuccess implements Action {
  readonly type = StopActionTypes.UPDATE_SUCCESS;

  constructor(public payload: { stop: Update<Stop> }) {}
}
export class UpdateStopError implements Action {
  readonly type = StopActionTypes.UPDATE_ERROR;

  constructor(public payload: any) {}
}

export class DeleteStop implements Action {
  readonly type = StopActionTypes.DELETE;

  constructor(public payload: { stop: Stop }) {}
}
export class DeleteStopSuccess implements Action {
  readonly type = StopActionTypes.DELETE_SUCCESS;

  constructor(public payload: any) {}
}
export class DeleteStopError implements Action {
  readonly type = StopActionTypes.DELETE_ERROR;

  constructor(public payload: any) {}
}

export class UpdateAllStops implements Action {
  readonly type = StopActionTypes.UPDATE_ALL;

  constructor(public payload: Stop[]) {}
}

export type StopActions =
  | GetStop
  | GetStopSuccess
  | GetStopError

  | UpdateStop
  | UpdateStopSuccess
  | UpdateStopError

  | GetStops
  | GetStopsSuccess
  | GetStopsError

  | CreateStop
  | CreateStopSuccess
  | CreateStopError

  | DeleteStop
  | DeleteStopSuccess
  | DeleteStopError

  | UpdateAllStops;
