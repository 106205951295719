
export interface AccountCompany {
  id?: string,
  account_id: string,
  company_id: string,
  type: string
}

export function genMockAccountCompany(): AccountCompany {
  return {
    account_id: '',
    company_id: '',
    type: ''
  };
}
