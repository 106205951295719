import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { ApiService } from '@app/shared/services/api.service';
import { Authenticate } from '@app/auth/models/authentication.model';
import { Registration } from '../models/registration.model';
import { AuthUser } from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  path = `auth`;

  cachedRequests: Array<HttpRequest<any>> = [];

  constructor(private apiService: ApiService) { }

  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }

  login(auth: Authenticate): Observable<any> {
    return this.apiService.post(`${this.path}/login`, auth);
  }

  logout() {
    this.destroyToken();
    // TODO: expire Token on server.
    return of(true);
  }

  check() {
    return this.apiService.get(`api/account`);
  }

  getMenu() {
    return this.apiService.get(`api/menu`);
  }

  getToken(): string {
    return window.localStorage.jwtToken;
  }

  saveToken(token: string) {
    window.localStorage.jwtToken = token;
  }

  destroyToken() {
    window.localStorage.removeItem('jwtToken');
  }

  register(auth: Registration): Observable<any> {
    return this.apiService.post(`${this.path}/signup`, auth);
  }

  forgot(auth: Authenticate): Observable<any> {
    return this.apiService.post(`${this.path}/forgot`, auth);
  }

  reset(auth: Authenticate): Observable<any> {
    return this.apiService.post(`${this.path}/reset`, auth);
  }
}
