<div class="modal-header">
  <h4 class="modal-title">User modal</h4>
  <button class="btn btn-link" type="button" (click)="close()"
    aria-label="Close">
    <span aria-hidden="true">
      <i class="fas fa-times"></i>
    </span>
  </button>
</div>
<div class="modal-body">
    <form class="form-horizontal" role="form" [formGroup]="userForm">
       <fieldset>
           <div class="form-group">
            <label class="control-label col-sm-3">Driver?</label>
            <div class="col-sm-6">
                <div class="btn-group">
                    <button type="button" [ngClass]="(userForm.value.driver=='0') ? 'btn-success' : 'btn-default'" class="btn" >
                        <label>
                            <input type="radio" name="driver" formControlName="driver" value="0">
                            No
                        </label>
                    </button>
                    <button type="button" [ngClass]="(userForm.value.driver=='1') ? 'btn-success' : 'btn-default'" class="btn" >
                        <label>
                            <input type="radio" name="driver" formControlName="driver" value="1">
                            Yes
                        </label>
                    </button>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-3 ">First name</label>
            <div class="col-sm-6">
                <input class="form-control" type="text" formControlName="firstname" size="35">
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-3 ">Last name</label>
            <div class="col-sm-6">
                <input class="form-control" type="text" formControlName="lastname" size="35">
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3" for="status">Login Needed?</label>
            <div class="col-sm-6">
                <div class="btn-group">
                    <button type="button" [ngClass]="(emailDisplay == false) ? 'btn-success' : 'btn-default'" class="btn" (click)="changeEmailDisplay(false)">
                        <label>
                            <input type="radio" name="driver" value="0">
                            No
                        </label>
                    </button>
                    <button type="button" [ngClass]="(emailDisplay == true) ? 'btn-success' : 'btn-default'" class="btn" (click)="changeEmailDisplay(true)">
                        <label>
                            <input type="radio" name="driver" value="1">
                            Yes
                        </label>
                    </button>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="emailDisplay">
            <label class="control-label col-sm-3 ">E-mail</label>
            <div class="col-sm-6">
                <input class="form-control" type="text" formControlName="email" size="35">
            </div>
        </div>
    </fieldset>
</form>
</div>
<div class="modal-footer">
  <div class="form-actions">
    <button type="submit" (click)="onSubmit()" class="btn btn-primary btn-lg btn-block">Save</button>
  </div>
</div>
