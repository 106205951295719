import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectionStrategy
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';

import { Store } from '@ngrx/store';

import * as fromCoreStore from '@app/core/store';

import { Fuels, States, Company } from '@app/shared';

@Component({
  selector: 'app-company-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss']
})
export class CompanyFormComponent implements OnInit {
  @Input()
  props: { company: Company };
  @Output()
  update = new EventEmitter<Company>();
  @Output()
  create = new EventEmitter<Company>();

  states;
  fuels;
  form: FormGroup;
  updateStatus: boolean;

  constructor(
    private store: Store<fromCoreStore.State>,
    private statesService: States,
    private fuelsService: Fuels
  ) {
    this.states = this.statesService.getStates();
    this.fuels = this.fuelsService.getFuels();
  }

  ngOnInit() {
    console.log(this.props.company);
    this.form = new FormGroup({
      type: new FormControl(this.props.company.type, [Validators.required]),
      name: new FormControl(this.props.company.name, [Validators.required]),
      dba: new FormControl(this.props.company.dba),
      street1: new FormControl(this.props.company.street1, [Validators.required]),
      city: new FormControl(this.props.company.city, [Validators.required]),
      state: new FormControl(this.props.company.state, [Validators.required]),
      zip: new FormControl(this.props.company.zip, [Validators.required]),
      phone: new FormControl(this.props.company.phone),
      fax: new FormControl(this.props.company.fax),
      email: new FormControl(this.props.company.email),
      mc: new FormControl(this.props.company.mc, [Validators.required]),
      dot: new FormControl(this.props.company.dot, [Validators.required]),
      or: new FormControl(this.props.company.or),
      ifta_state_id: new FormControl(this.props.company.ifta_state_id),
      ifta: new FormControl(this.props.company.ifta),
      ny_report: new FormControl(this.props.company.ny_report),
      ny: new FormControl(this.props.company.ny),
      ky: new FormControl(this.props.company.ky),
      nm: new FormControl(this.props.company.nm)
    });
  }

  setValue(field, value) {
    if (this.form && this.form.controls[field]) {
      this.form.controls[field].setValue(value);
    }
  }

  showTrucking() { }

  fc(name) {
    return this.form.get(name) as FormControl;
  }

  save() {
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach(field => {
        this.form.get(field).markAsTouched({ onlySelf: true });
      });
      return;
    }
    const companyFormLet: Company = { ...this.props.company, ...this.form.value };
    if (this.props.company.id) {
      this.store.dispatch(
        new fromCoreStore.UpdateCompany(
          { company: companyFormLet }
        )
      );
    } else {
      this.store.dispatch(
        new fromCoreStore.CreateCompany({
          company: companyFormLet
        })
      );
    }
    // this.store.dispatch(new fromCoreStore.CloseModal());
  }

  close() {
    this.store.dispatch(new fromCoreStore.CloseModal());
  }
}
