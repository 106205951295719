import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

interface Filter {
  status: string
}
@Component({
  selector: 'app-filters',
  template: `
  <ul>
  <li *ngFor="let s of status;  let i=index" class="dd-tab-item"
    [ngClass]="{'active': filters && filters.status === s}"
    (click)="set(status[i]); isActive=i">
      <a [ngClass]="count && count.length > 0? 'dd-badge':''" attr.data-dd-badge="{{getCount(status[i])}}">
        {{status[i]}}
      </a>
    </li>
  </ul>
  `
})



export class FiltersComponent {
  @Input()
  status: string[];
  @Input()
  filters: Filter;
  @Input()
  count: string[];
  @Output()
  filterSet = new EventEmitter();


  isActive;

  constructor(private route: ActivatedRoute, private router: Router) {
    // this.route.params.subscribe((data: { status: string }) => {
    //   const searchFormData = Object.assign({ status: '' }, data);
    //   //   this.searchForm.patchValue(searchFormData)
    //   console.log(searchFormData);
    // });
  }

  getCount(status) {
    return this.count && this.count[status] ? this.count[status] : 0;
  }

  set(status) {
    // trigger navigation
    // this.router.navigate(['./', { status: status }]);
    this.filterSet.emit({ status: status });
  }

  resetFilter() {
    // this.router.navigate(['./', { from: null, to: null }]);
  }
}
