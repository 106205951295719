import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotFoundPageComponent } from '@app/core/containers/not-found-page'

import { AuthGuardService } from '@app/auth/guards/auth-guard.service';
import { AccountsGuard } from '@app/manage/guards/accounts.guard';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: 'auth',
          loadChildren: () => import('@app/auth/auth.module').then(m => m.AuthModule)
        },
        {
          path: 'dashboard',
          loadChildren: () => import('@app/dashboard/dashboard.module').then(m => m.DashboardModule),
          canActivate: [AuthGuardService]
        },
        {
          path: 'trips',
          loadChildren: () => import('@app/trips/trips.module').then(m => m.TripsModule),
          canActivate: [AuthGuardService]
        },
        {
          path: 'trucks',
          loadChildren: () => import('@app/trucks/trucks.module').then(m => m.TrucksModule),
          canActivate: [AuthGuardService]
        },
        {
          path: 'company',
          loadChildren: () => import('@app/companies/companies.module').then(m => m.CompaniesModule),
          canActivate: [AuthGuardService]
        },
        {
          path: 'reports',
          loadChildren: () => import('@app/reports/reports.module').then(m => m.ReportsModule),
          canActivate: [AuthGuardService]
        },
        {
          path: 'accounting',
          loadChildren: () => import('@app/invoices/invoices.module').then(m => m.InvoicesModule),
          canActivate: [AuthGuardService]
        },
        {
          path: 'settings',
          loadChildren: () => import('@app/settings/settings.module').then(m => m.SettingsModule),
          canActivate: [AuthGuardService]
        },
        {
          path: 'manage',
          loadChildren: () => import('@app/manage/manage.module').then(m => m.ManageModule),
          canActivate: [AuthGuardService]
        },
        { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
        // { path: '**', component: NotFoundPageComponent }
      ],
      {
        useHash: true
      })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
