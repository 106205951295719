import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Authenticate } from '@app/auth/models/authentication.model';

@Component({
  selector: 'app-reset-form',
  templateUrl: './reset-form.component.html',
  styles: []
})
export class ResetFormComponent implements OnInit {
  @Input()
  error: string | null;

  @Input()
  set disabled(isDisabled: boolean) {
    if (isDisabled) {
      this.resetForm.disable();
    } else {
      this.resetForm.enable();
    }
  }

  @Output()
  submitted = new EventEmitter<Authenticate>();

  resetForm = new FormGroup({
    con_pswd: new FormControl('', [Validators.required]),
    pswd: new FormControl('', [Validators.required])
  });

  display = {
    action_type: ''
  };

  get pswd() {
    return this.resetForm.get('pswd') as FormControl;
  }

  get con_pswd() {
    return this.resetForm.get('con_pswd') as FormControl;
  }

  ngOnInit() {}

  submit() {
    if (this.resetForm.invalid) {
      Object.keys(this.resetForm.controls).forEach(field => {
        this.resetForm.get(field).markAsTouched({ onlySelf: true });
      });
      return;
    }
    const value: Authenticate = this.resetForm.value;

    if (this.resetForm.valid) {
      if (this.resetForm.value.pswd == this.resetForm.value.con_pswd) {
        this.submitted.emit(value);
      }
    }
  }
}
