import { Action } from '@ngrx/store';

export enum LayoutActionTypes {
  OPEN_MODAL = '[Layout] Open Modal',
  CLOSE_MODAL = '[Layout] Close Modal'
}

// Actions for Getting  Layouts
// Modal actions

export class OpenModal implements Action {
  readonly type = LayoutActionTypes.OPEN_MODAL;
  constructor(public payload: { modal: string; props?: object }) {}
}
export class CloseModal implements Action {
  readonly type = LayoutActionTypes.CLOSE_MODAL;
  // constructor(public payload: string) {}
}

export type LayoutActions = OpenModal | CloseModal;
