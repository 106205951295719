import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, Subject, catchError, finalize } from 'rxjs';
import { SubjectDestroyed } from '../models/subjectDestroyed';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends SubjectDestroyed {
  private loadNumeric = 0;
  loadSubject: Subject<number> = new Subject<number>();
  constructor(private http: HttpClient) {
    super();
  }

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    return new HttpHeaders(headersConfig);
  }

  private setHeadersForm(): HttpHeaders {
    const headersConfig = {
      Accept: 'application/json'
    };

    return new HttpHeaders(headersConfig);
  }

  private setHeadersFormPut(): HttpHeaders {
    const headersConfig = {
      Accept: 'application/json',
      'Content-Type': 'multipart/form'
    };

    return new HttpHeaders(headersConfig);
  }

  private formatErrors(error: any) {
    // this.loadSubject.next(this.loadNumeric = this.loadNumeric + 1);
    return throwError(error?.error?.msg || error);
  }

  private callBack() {
    this.loadSubject.next(this.loadNumeric = this.loadNumeric + 1);
    console.log(this.loadNumeric);
  }

  private payloadToForm(data) {
    const formData = new FormData();
    for (const key of Object.keys(data)) {
      formData.append(key, data[key]);
    }
    return formData;
  }

  getRaw(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    this.loadSubject.next(this.loadNumeric = this.loadNumeric - 1);
    const headers = this.setHeaders();
    headers.set('Content-Type', 'application/pdf');
    const returnRequest = this.http
      .get(`${environment.apiUrl}${path}`, {
        headers,
        params,
      })
      .pipe(
        finalize(() => this.callBack()),
        catchError(this.formatErrors)
      );
    return returnRequest;
  }

  getPdf(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    this.loadSubject.next(this.loadNumeric = this.loadNumeric - 1);
    const headers = new HttpHeaders({
      'Content-Type': 'application/pdf',
      Accept: 'application/pdf'
    });
    const returnRequest = this.http
      .get(`${environment.apiUrl}${path}`, {
        headers,
        params,
        responseType: 'blob',
      })
      .pipe(
        finalize(() => this.callBack()),
        catchError(this.formatErrors)
      );
    return returnRequest;
  }

  get(path: string, params = {}): Observable<any> {
    this.loadSubject.next(this.loadNumeric = this.loadNumeric - 1);
    let httpParams = new HttpParams();
    Object.keys(params).forEach(key => {
      httpParams = httpParams.append(key, params[key]);
    });
    const returnRequest = this.http
      .get<any>(`${environment.apiUrl}${path}`, {
        headers: this.setHeaders(),
        params: httpParams
      })
      .pipe(
        finalize(() => this.callBack()),
        catchError(this.formatErrors)
      );
    return returnRequest;
  }

  getOpen(
    path: string
  ): Observable<any> {
    this.loadSubject.next(this.loadNumeric = this.loadNumeric - 1);
    const returnRequest = this.http.get<any>(path)
      .pipe(
        finalize(() => this.callBack()),
        catchError(this.formatErrors)
      );
    return returnRequest;
  }

  put(path: string, payload: object = {}): Observable<any> {
    this.loadSubject.next(this.loadNumeric = this.loadNumeric - 1);
    const returnRequest = this.http
      .put<any>(`${environment.apiUrl}${path}`, JSON.stringify(payload), {
        headers: this.setHeaders()
      })
      .pipe(
        finalize(() => this.callBack()),
        catchError(this.formatErrors)
      );
    return returnRequest;
  }
  putForm(path: string, payload: object = { _id: '' }): Observable<any> {
    this.loadSubject.next(this.loadNumeric = this.loadNumeric - 1);
    const returnRequest = this.http
      .put<any>(`${environment.apiUrl}${path}`, this.payloadToForm(payload), {
        headers: this.setHeadersForm()
      })
      .pipe(
        finalize(() => this.callBack()),
        catchError(this.formatErrors)
      );
    return returnRequest;
  }

  post(path: string, payload: object = {}): Observable<any> {
    this.loadSubject.next(this.loadNumeric = this.loadNumeric - 1);
    const returnRequest = this.http
      .post<any>(`${environment.apiUrl}${path}`, JSON.stringify(payload), {
        headers: this.setHeaders()
      })
      .pipe(
        finalize(() => this.callBack()),
        catchError(this.formatErrors)
      );
    return returnRequest;
  }

  getFile(
    path: string,
    payload: object = {}
  ): Observable<any> {
    this.loadSubject.next(this.loadNumeric = this.loadNumeric - 1);
    const returnRequest = this.http
      .post(`${environment.apiUrl}${path}`, payload, {
        responseType: 'blob',
      })
      .pipe(
        finalize(() => this.callBack()),
        catchError(this.formatErrors)
      );
    return returnRequest;
  }
  postForm(path: string, payload): Observable<any> {
    this.loadSubject.next(this.loadNumeric = this.loadNumeric - 1);
    const returnRequest = this.http
      .post<any>(`${environment.apiUrl}${path}`, this.payloadToForm(payload), {
        headers: this.setHeadersForm()
      })
      .pipe(
        finalize(() => this.callBack()),
        catchError(this.formatErrors)
      );
    return returnRequest;
  }
  postPdf(path: string, payload: object = {}): Observable<any> {
    this.loadSubject.next(this.loadNumeric = this.loadNumeric - 1);
    const returnRequest = this.http
      .post<any>(`${environment.apiUrl}${path}`, { headers: this.setHeaders() })
      .pipe(
        finalize(() => this.callBack()),
        catchError(this.formatErrors)
      );
    return returnRequest;
  }

  delete(path: string, params = {}): Observable<any> {
    this.loadSubject.next(this.loadNumeric = this.loadNumeric - 1);
    let httpParams = new HttpParams();
    Object.keys(params).forEach(key => {
      httpParams = httpParams.append(key, params[key]);
    });
    const returnRequest = this.http
      .delete<any>(`${environment.apiUrl}${path}`, {
        headers: this.setHeaders(),
        params: httpParams
      })
      .pipe(
        finalize(() => this.callBack()),
        catchError(this.formatErrors)
      );
    return returnRequest;
  }
}
