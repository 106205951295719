export class TripErrorsTypes {
    INCORRECT_TRIP_DATE = 'Trip date is incorrect';
    INCORRECT_FUEL_DATE = 'Fuel Date doen\'t fall in the trip range';
    INCORRECT_FUEL_STATE = 'State has fuel but no miles!';
}

export interface TripErrors {
    id: string;
    errorsText: {
        type: string;
        value: string;
    }[];
}
