export interface Address {
  id?: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
  loc?: {
    lng: number;
    lat: number;
  };
}

export function genMockAddress(): Address {
  return {
    addressLine1: '',
    city: '',
    state: '',
    zip: ''
  };
}
