import { Component, OnInit, OnDestroy } from '@angular/core';

import { Observable, takeUntil, tap } from 'rxjs';

import { Store } from '@ngrx/store';
import * as fromAuthStore from '@app/auth/store';

import { AuthUser, SubjectDestroyed } from '@app/shared/models';
import { Logout } from '@app/auth/store';
import { ApiService } from '@app/shared/services/api.service';

@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class HeaderComponent extends SubjectDestroyed implements OnInit, OnDestroy {
  currentUser$: Observable<AuthUser>;

  menu = {
    collection: []
  };
  loading = 0;

  constructor(
    private store: Store<fromAuthStore.State>,
    private apiService: ApiService
  ) {
    super();
    apiService.loadSubject.subscribe(loadNum => this.loading = loadNum);
  }

  ngOnInit() {
    this.currentUser$ = this.store.select(fromAuthStore.selectAuthUser);
    this.store
      .select(fromAuthStore.selectTopMenu)
      .pipe(
        takeUntil(this.destroyed$),
        tap(topMenu => {
          this.menu.collection = topMenu;
        })
      )
      .subscribe();
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  isActive(itemPath) {
    itemPath.substr(1);
    return (
      itemPath === window.location.pathname ||
      itemPath.substr(1) ===
      window.location.pathname.substr(1).match(/[^/]*/)[0]
    );
  }

  getRole(level) {
    let role = 'User'
    switch (level) {
      case '3':
        role = 'Account Admin'
        break;
      case '5':
        role = 'Agent'
        break;
      case '7':
        role = 'System Admin'
        break;
      default:
        break;
    }
    return role;
  }

  ngOnDestroy() { }
}
