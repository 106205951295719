import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Company } from '@app/shared/models';
import * as fromCoreStore from '@app/core/store';
import { Subscription, Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss']
})
export class SelectCompanyComponent implements OnInit {

  companies: Company[];
  currentCompanyId$: Observable<string>;
  @Input() label: String;
  @Output()
  onChange = new EventEmitter<number>();

  companiesListener: Subscription;

  constructor(private store: Store<fromCoreStore.State>) { }

  ngOnInit() {
    this.currentCompanyId$ = this.store.select(fromCoreStore.selectCurrentCompanyId);
    this.companiesListener = this.store.select(fromCoreStore.selectAllCompanies)
      .subscribe(companies => this.companies = companies);
  }
  change(companyId: number) {
    this.onChange.emit(companyId);
    this.store.dispatch(new fromCoreStore.SetCurrentCompanyId(String(companyId)));
    this.store.dispatch(new fromCoreStore.GetTrucksByCompany({ companyId: String(companyId) }));
  }

  ngOnDestroy(): void {
    this.companiesListener.unsubscribe();
  }

}
