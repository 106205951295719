import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Note } from '../../models';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  @Output()
  addNote = new EventEmitter<string>();
  @Output()
  removeNote = new EventEmitter<Note>();
  @Input()
  notes: Array<Note>;
  focused: boolean = false;
  text: string = '';

  constructor() { }

  ngOnInit() {
  }

  save() {
    this.addNote.emit(this.text);
    this.text = '';
  }

  delete(note: Note) {
    this.removeNote.emit(note);
  }

}
