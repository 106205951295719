/// <reference types="googlemaps" />

import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  NgZone,
  ElementRef
} from '@angular/core';
import { GoogleMapService } from '@app/shared/services/google-map.service';
import { Address } from '@app/shared/models';

@Component({
  selector: 'app-auto-complete',
  template: `
    <input
      *ngIf="addressline !== 'true'"
      [placeholder]="placeholder"
      [(ngModel)]="text"
      (change)="changeVal($event)"
      (keyup)="keyup($event)"
      type="text"
      class="form-control {{ bonusClassToInput }}"
      #search
    />

    <input
      *ngIf="addressline === 'true'"
      [placeholder]="placeholder"
      [(ngModel)]="addressObject.addressLine1"
      (change)="changeVal($event)"
      (keyup)="keyup($event)"
      type="text"
      class="form-control {{ bonusClassToInput }}"
      #search
    />
  `
})
export class AutoCompleteComponent implements OnInit {
  @ViewChild('search', { static: false })
  public searchElementRef: ElementRef;

  @Output()
  onSave = new EventEmitter<Address>();
  @Output()
  inputOnKeyup = new EventEmitter<any>();
  @Input('value')
  text: string;
  @Input('title')
  fieldName: string;
  @Input()
  addressObject: Address;
  @Input()
  placeholder;
  @Input()
  addressline;
  @Input()
  label;
  @Input()
  type;
  @Input()
  bonusClassToInput: string;

  save = new EventEmitter();

  public componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    sublocality: 'long_name',
    sublocality_level_1: 'long_name',
    administrative_area_level_1: 'short_name',
    postal_code: 'short_name'
  };

  public address = {
    street_number: '',
    route: '',
    locality: '',
    sublocality: '',
    sublocality_level_1: '',
    administrative_area_level_1: '',
    postal_code: ''
  };

  public originalAddress: Address = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: ''
  };

  constructor(private mapsAPILoader: GoogleMapService, private ngZone: NgZone) { }

  ngOnInit() {
    this.placeholder = '';
    if (this.addressline === 'true') {
      this.originalAddress = this.addressObject;
    } else {
      this.addressObject = this.originalAddress;
    }
    let autocomplete;
    // load Places Autocomplete
    this.mapsAPILoader.loadAPI.then(() => {
      if (this.addressline === 'true') {
        autocomplete = new google.maps.places.Autocomplete(
          this.searchElementRef.nativeElement,
          {
            types: ['geocode']
          }
        );
      } else {
        autocomplete = new google.maps.places.Autocomplete(
          this.searchElementRef.nativeElement,
          {
            types: ['(regions)']
          }
        );
      }

      autocomplete.setComponentRestrictions({ country: 'us' });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          this.text = '';
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          Object.keys(this.componentForm).map(field => {
            this.address[field] = '';
          });

          if (!place || !place.address_components) {
            return false;
          }

          // Get each component of the address from the place details
          // and fill the corresponding field on the form.
          let compType;
          place.address_components.map(comp => {
            compType = comp.types[0];
            if (this.componentForm[compType]) {
              this.address[compType] = comp[this.componentForm[compType]];
            }
          });
          if (this.addressline === 'true') {
            // this.text = this.searchElementRef.nativeElement.value = this.address.street_number;
            this.text = this.searchElementRef.nativeElement.value = place.formatted_address.replace(
              ', USA',
              ''
            );
            if (this.address.route) {
              this.addressObject.addressLine1 =
                this.address.street_number + ' ' + this.address.route;
            } else {
              this.addressObject.addressLine1 = this.address.street_number;
              // this.addressObject.addressLine2=this.address.street_number;
            }
          } else {
            this.text = this.searchElementRef.nativeElement.value = place.formatted_address.replace(
              ', USA',
              ''
            );
          }
          this.addressObject.state = this.address.administrative_area_level_1;
          this.addressObject.city =
            this.address.locality ||
            this.address.sublocality ||
            this.address.sublocality_level_1;
          this.addressObject.zip = this.address.postal_code;

          this.addressObject.loc.lat = place.geometry.location.lat();
          this.addressObject.loc.lng = place.geometry.location.lng();

          this.addressObject.addressLine1 = this.addressObject.city + ', ' + this.addressObject.state;
          // this.onSave.emit(this.addressObject.addressLine1);

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
        });
      });
    });
  }

  changeVal(event) {
    // Stop Reset 
    this.addressObject = {
      addressLine1: this.addressObject.addressLine1,
      city: '',
      loc: { lat: 0, lng: 0 },
      state: '',
      zip: '',
    };
    if (this.addressline === 'false' && this.isEmptyObj(this.addressObject)) {
      this.addressObject.zip = this.searchElementRef.nativeElement.value;
    }
    setTimeout(() => {
      this.onSave.emit(this.addressObject);
    }, 1000);
  }

  isEmptyObj(obj) {
    obj = obj || {};
    let isEmpty = true;
    Object.keys(obj).forEach(function (prop) {
      isEmpty = isEmpty ? !obj[prop] : isEmpty;
    });
    return isEmpty;
  }

  isValidZip(input) {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(input);
  }

  keyup(event) {
    this.inputOnKeyup.emit(event);
  }
}
