<div class="notes">
  <h4 class="heading">Notes</h4>
  <table class="table table-condensed" style="margin-bottom:0px;">
    <tr *ngFor="let note of this.notes">
      <td>
        <p>
          {{note.content}} 
          <strong>{{note.name}} </strong>
          <small class="text-muted">on {{note.created | date:'MMMM dd'}}</small>
          <app-btn-default type="delete" cssClasses="btn-xs btn-delete" (click)="delete(note)"></app-btn-default>
        </p>
      </td>
    </tr>
  </table>
  <div class="panel panel-default" [ngStyle]="{'width': '100%'}">
    <div class="panel-body" style="padding:0px;">
      <textarea class="form-control" [(ngModel)]="this.text" [rows]="this.text.length ? 4 : 1" placeholder="Write a note..."></textarea>
    </div>
    <div class="panel-footer" style="padding:5px;" *ngIf="this.text.length">
      <span><span class="label label-warning">Don't</span> Enter SSN or bank account!</span>
      <button type="submit" class="btn btn-default pull-right" (click)="save()">Add Note</button>
      <div class="clearfix"></div>
    </div>
  </div>
</div>