import { Component, OnInit, Input } from '@angular/core';
import * as fromCoreStore from '@app/core/store';
import * as fromAuthStore from '@app/auth/store';
import { Store } from '@ngrx/store';
import { AuthPlan, AuthAccount } from '@app/shared/models/auth.model';
import { SubjectDestroyed } from '@app/shared/models/subjectDestroyed';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { tap } from 'rxjs/internal/operators/tap';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.css']
})
export class AccountDetailsComponent extends SubjectDestroyed implements OnInit {
  @Input()
  props: { account: any };
  account: AuthAccount;
  plan: AuthPlan;

  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    { // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';

  constructor(private store: Store<fromCoreStore.State>) {
    super();
    this.store
      .select(fromAuthStore.selectAuthPlan)
      .pipe(
        takeUntil(this.destroyed$),
        tap(plan => this.plan = plan)
      )
      .subscribe();
    this.store
      .select(fromAuthStore.selectAuthAccount)
      .pipe(
        takeUntil(this.destroyed$),
        tap(account => this.account = account)
      )
      .subscribe();
  }

  ngOnInit() {
    if (this.props.account.graph) {
      this.lineChartLabels = this.props.account.graph.dates;
      this.props.account.graph.data.forEach((item, index) => {
        this.lineChartData.push({ data: item, label: this.props.account.graph.labels[index] });
      });
    }
  }

  close() {
    this.store.dispatch(new fromCoreStore.CloseModal());
  }
}
