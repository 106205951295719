import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';

import {
  of,
  map,
  exhaustMap,
  catchError,
  tap,
  withLatestFrom,
  mergeMap,
} from 'rxjs';

import * as AuthActions from '@app/auth/store/actions/auth.actions';
import * as fromCoreStore from '@app/core/store';
import { AuthService } from '@app/auth/services/auth.service';
import { ToastHelper } from '@app/core/services/toast.service';

@Injectable()
export class AuthEffects {

  loginToken$ = createEffect(() => this.actions$.pipe(
    ofType<AuthActions.LoginToken>(AuthActions.AuthActionTypes.LoginToken),
    exhaustMap(() =>
      this._AuthService.check().pipe(
        mergeMap(resp => {
          return [
            new AuthActions.LoginSuccess(resp),
            new AuthActions.GetTopMenu()
          ]
        }),
        catchError(error => of(new AuthActions.LoginFailure(error)))
      )
    )
  )
  );


  login$ = createEffect(() => this.actions$.pipe(
    ofType<AuthActions.Login>(AuthActions.AuthActionTypes.Login),
    map(action => action.payload),
    exhaustMap(auth =>
      this._AuthService.login(auth).pipe(
        mergeMap(resp => {
          this._AuthService.saveToken(resp.user.access_token);
          return [
            new AuthActions.LoginSuccess(resp),
            new AuthActions.GetTopMenu()
          ];
        }),
        catchError(error => {
          this.notify.error(error);
          return of(new AuthActions.LoginFailure(error))
        })
      )
    )
  )
  );

  loginFailed$ = createEffect(() => this.actions$.pipe(
    ofType<AuthActions.LoginFailure>(AuthActions.AuthActionTypes.LoginFailure),
    tap(auth => this._AuthService.destroyToken())
  ), { dispatch: false })

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.AuthActionTypes.LoginSuccess),
    withLatestFrom(this.store$.select(fromCoreStore.getModal)),
    tap(([action, modal]) => {
      const path = window.location.hash.substring(1);
      if (action['payload']['user']['level'] === '4') {
        // if (action?.payload?.user?.level === '4') {
        const billingPath = '/settings/billing';
        if (!path.includes(billingPath)) {
          this._Router.navigate([billingPath]);
        }
      } else {
        if (modal && modal.type) {
          this.store$.dispatch(new fromCoreStore.CloseModal());
        } else {
          if (path.includes('/auth/login')) {
            this._Router.navigate(['/dashboard']);
          } else {
            this._Router.navigateByUrl(path);
          }
        }
      }
    })
  ), { dispatch: false })

  loginRequest$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.AuthActionTypes.LoginRequest),
    tap(authed => {
      const path = window.location.hash.substring(1);
      console.log(path);
      if (path === '' || path === '/' || path === '/auth/login') {
        this._Router.navigate(['/auth/login']);
      } else {
        this.store$.dispatch(new fromCoreStore.OpenModal({ modal: 'LOGIN' }));
      }
    })
  ), { dispatch: false });


  loginPaymentRequest$ = createEffect(() => this.actions$.pipe(
    ofType<AuthActions.LoginPaymentRedirect>(AuthActions.AuthActionTypes.LoginPaymentRedirect),
    tap(action => {

    })
  ), { dispatch: false });

  logoutConfirmation$ = createEffect(() => this.actions$.pipe(
    ofType<AuthActions.Logout>(AuthActions.AuthActionTypes.Logout),
    map(() => new AuthActions.LogoutConfirmed())
  )
  );

  logout$ = createEffect(() => this.actions$.pipe(
    ofType<AuthActions.LogoutConfirmed>(AuthActions.AuthActionTypes.LogoutConfirmed),
    exhaustMap(auth =>
      this._AuthService.logout().pipe(
        tap(() => this._Router.navigate(['/auth/login'])),
        map(() => new AuthActions.LogoutComplete()),
        catchError(() => of(new AuthActions.LogoutComplete()))
      )
    )
  ), { dispatch: false });


  register$ = createEffect(() => this.actions$.pipe(
    ofType<AuthActions.Register>(AuthActions.AuthActionTypes.Register),
    map(action => action.payload),
    exhaustMap(auth =>
      this._AuthService.register(auth).pipe(
        map(resp => {
          this._Router.navigate(['/auth/login'])
          this.notify.ok(resp.msg);
          return new AuthActions.RegisterSuccess({});
        }),
        catchError(error => {
          this.notify.error(error);
          return of(new AuthActions.RegisterFailure(error))
        }
        )
      )
    )
  ));


  forgot$ = createEffect(() => this.actions$.pipe(
    ofType<AuthActions.Forgot>(AuthActions.AuthActionTypes.Forgot),
    map(action => action.payload),
    exhaustMap(auth =>
      this._AuthService.forgot(auth).pipe(
        map(resp => {
          this.notify.ok(resp.msg);
          return new AuthActions.ForgotSuccess({ user: resp.user });
        }),
        catchError(error => of(new AuthActions.ForgotFailure(error)))
      )
    )
  )
  );


  reset$ = createEffect(() => this.actions$.pipe(
    ofType<AuthActions.Reset>(AuthActions.AuthActionTypes.Reset),
    map(action => action.payload),
    exhaustMap(auth =>
      this._AuthService.reset(auth).pipe(
        map(resp => {
          this._Router.navigate(['/auth/login'])
          this.notify.ok(resp.msg);
          return new AuthActions.ResetSuccess();
        }),
        catchError(error => of(new AuthActions.ResetFailure(error)))
      )
    )
  )
  );


  getTopMenu$ = createEffect(() => this.actions$.pipe(
    ofType<AuthActions.GetTopMenu>(AuthActions.AuthActionTypes.GET_TOP_MENU),
    exhaustMap(_ =>
      this._AuthService.getMenu().pipe(
        map(resp => new AuthActions.GetTopMenuSuccess(resp)),
        catchError(error => of(new AuthActions.GetTopMenuError(error)))
      )
    )
  ));

  constructor(
    private store$: Store<fromCoreStore.State>,
    private actions$: Actions,
    private notify: ToastHelper,
    private _AuthService: AuthService,
    private _Router: Router
  ) { }
}
