import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of, map, switchMap, catchError } from 'rxjs';

import * as SettingActions from '@app/settings/store/actions/setting.actions';
import { SettingService } from '@app/settings/services/settings.service';

@Injectable()
export class SettingsEffects {

  get$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SettingActions.GetSettings>(SettingActions.SettingActionTypes.GET),
      switchMap((action: SettingActions.GetSettings) => {
        return this._SettingService.getSettings().pipe(
          map(resp => {
            return new SettingActions.GetSettingsSuccess({
              settings: resp || []
            });
          }),
          catchError(error => of(new SettingActions.GetSettingsError(error)))
        );
      })
    )
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<SettingActions.UpdateSettings>(SettingActions.SettingActionTypes.UPDATE_SETTINGS),
      switchMap((action: SettingActions.UpdateSettings) => {
        return this._SettingService.updateSettings(action.payload).pipe(
          map(resp => {
            return new SettingActions.UpdateSettingsSuccess({
              settings: resp
            });
          }),
          catchError(error => of(new SettingActions.UpdateSettingsError(error)))
        );
      })
    )
  });

  constructor(
    private actions$: Actions,
    private _SettingService: SettingService,
  ) { }
}
