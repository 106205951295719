import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { Truck } from '@app/shared/models';
import * as fromCoreStore from '@app/core/store';

@Component({
  selector: 'app-select-truck',
  templateUrl: './select-truck.component.html',
  styleUrls: ['./select-truck.component.scss']
})
export class SelectTruckComponent implements OnInit {

  trucks: Truck[];
  @Input()
  label;
  @Input()
  currentTruckId;
  @Output()
  onChange = new EventEmitter<number>();

  trucksListener: Subscription;

  change(truckId: number) {
    this.onChange.emit(truckId);
  }

  constructor(private store: Store<fromCoreStore.State>) { }

  ngOnInit() {
    this.trucksListener = this.store.select(fromCoreStore.selectAllTrucks).subscribe(trucks => this.trucks = trucks);
  }

  ngOnDestroy(): void {
    this.trucksListener.unsubscribe();
  }
}
